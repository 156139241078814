import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Avatar , Grid, Typography , Box, Chip , ButtonGroup , Button } from '@mui/material';


import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CloseIcon from '@mui/icons-material/Close';

import { toPrice } from '../../lib/pricing';
import TruncatedText from '../text/TruncatedText';

import { useLang } from '../lang';
import { useCart } from '../cart';
import { store_url } from '../../lib/pbGet';
import StyledTypography from '../text/StyledTypography';
import HalfScreenDialog from './HalfScreenDialog';
import { useNavigate } from 'react-router-dom';
import { tab } from '@testing-library/user-event/dist/tab';
import FullScreenDialog from './FullScreenDialog';
import WebView from '../webview/WebView';

import theme from '../theme';

export default function ProductDialog({open , setOpen , product , children}) {
  
  const lang = useLang();
  const cart = useCart();
  const [scroll, setScroll] = React.useState('paper');
  const [showMore , setShowMore] = React.useState(false);
  const [standalone , setStandalone] = React.useState(window.navigator.standalone)
  
  const handleAddToCart = () => {

    cart.addProduct(product.id);

  }

  const handleViewInStore = () => {
    
    cart.showProductInStore(product.store_url);
    // if (!product.store_url || product.store_url === '') {
      
    //   window.open(store_url ,'_blank');
    // } else {
    //   window.open(product.store_url ,'_blank');
    // }
  };

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  if(product.price == 0){
    return null;
  }

  return (
    <HalfScreenDialog
      open={open}
      setOpen={setOpen}
      dir={lang.t('code.dir')}
    >

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' , marginBottom: '1rem'}}>
          <img src={product.image_url ? product.image_url : theme.images.default_product_image} style={{ width: '30%' }} alt="Product" />
          <StyledTypography dir={lang.t('code.dir')} lng={lang.t('code.lng')}>
            {product.title}
          </StyledTypography>
        </div>

      
        <StyledTypography 
          dir={lang.t('code.dir')} 
          lng={lang.t('code.lng')}
        >
          {product.desc}
        </StyledTypography>
        
        <StyledTypography 
          variant='h5' 
          color='black' 
          style={{marginTop: 7}}
          dir={lang.t('code.dir')} 
          lng={lang.t('code.lng')}
        >
          {toPrice(product.price)}
        </StyledTypography>

        <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 13
              }}
            >
              {
                !product.old_price || product.old_price === 0 
                ? null
                :
                <StyledTypography 
                  variant='body1' 
                  color='black' 
                  style={{textDecoration: "line-through"}}
                  dir={lang.t('code.dir')} 
                  lng={lang.t('code.lng')}
                > 
                  {toPrice(product.old_price)}
                </StyledTypography>
              }

              {
                product.tags.length != 0 &&
                product.tags.map((tag , index) => (
                  <Chip 
                  style={{
                    borderRadius: 10,
                  }}
                  label={tag}
                  ></Chip>
                ))
              }

              {
                !product.inventory || product.inventory === 0 
                ? null
                :
                <StyledTypography 
                  variant='body1' 
                  color='black' 
                  style={{color: '#de185a'}}
                  dir={lang.t('code.dir')} 
                  lng={lang.t('code.lng')}
                >
                  {product.inventory} {lang.t('general.left')}
                </StyledTypography>
              }

            </div>
            
            <Typography>{product.add_desc}</Typography>



        <ButtonGroup fullWidth variant="contained" aria-label="outlined primary button group">
            
            <Button onClick={handleAddToCart} fullWidth variant="contained">
              <StyledTypography dir={lang.t('code.dir')} lng={lang.t('code.lng')}>
                {lang.t('general.addToCart')}
              </StyledTypography>
            </Button>

            <a href={!product.store_url || product.store_url === '' ? store_url : product.store_url} target="_blank" rel="noreferrer external">
              <Button fullWidth variant="outlined">
                <StyledTypography dir={lang.t('code.dir')} lng={lang.t('code.lng')}>
                  {lang.t('general.viewInStore')}
                </StyledTypography>
              </Button>
            </a>



        </ButtonGroup>

    </HalfScreenDialog>


  );
}