import { Avatar, Box } from "@mui/material";
import { endpoint } from "../../lib/backend_endpoint";
import { pbGetImage } from "../../lib/pbGet";
import { useAuth } from "../auth";
import { useEffect, useState } from "react";
import UpdateProfileImageDialog from "../dialog/UpdateProfileImageDialog";

function AccountAvatar({ imageSize = 30, imageSrc = null, changeOption = false , onClick = () => {}}) {
  const auth = useAuth();
  const [accountImageSrc, setAccountImageSrc] = useState(null);
  const [openChangeProfileImage, setOpenChangeProfileImage] = useState(false);

  useEffect(() => {
    const fetchImage = async () => {
      try {

        if(!auth.authUser){
          return;
        }

        const imageUrl = `${endpoint}/api/files/_pb_users_auth_/${auth.authUser.record.id}/${auth.authUser.record.avatar}`;

        // Check if the image is already in cache
        const cachedImage = sessionStorage.getItem(imageUrl);

        if (cachedImage) {
          setAccountImageSrc(cachedImage);
        } else {
          const dataURL = await pbGetImage(imageUrl);

          const response = await fetch(imageUrl, {
            method: "get",
            headers: new Headers({
              "ngrok-skip-browser-warning": "69420",
            }),
          });

          if (response.status === 404) {
            setAccountImageSrc(auth.authUser.record.google_picture);
          } else {
            setAccountImageSrc(dataURL);
            // Cache the image in sessionStorage
            sessionStorage.setItem(imageUrl, dataURL);
          }
        }
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImage();
  }, [auth.authUser]);

  return (
    <div onClick={onClick}>
      <Box
        sx={{
          borderRadius: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // mt: 2,
          // p: 1,
        }}
      >
        <Avatar
          src={!imageSrc ? accountImageSrc : imageSrc}
          sx={{ width: imageSize, height: imageSize , maxWidth: 35 , maxHeight: 35}}
        />

        {changeOption && (
          <UpdateProfileImageDialog
            open={openChangeProfileImage}
            setOpen={setOpenChangeProfileImage}
          />
        )}
      </Box>
    </div>
  );
}

export default AccountAvatar;
