import React, { useRef, useEffect, useState } from "react";
import Webcam from "react-webcam";
import { FaceMesh } from "@mediapipe/face_mesh";
import * as cam from "@mediapipe/camera_utils";

// MUI
import { Container, Grid, Paper } from "@mui/material";

import CenteredDiv from './CenteredDiv';
import { faceArea } from "../../faceLandmarks/faceLandmarksDict";



const SmartCamera = ({ showCanvas = true , onResults = (results) => {} , onInit = () => {} , faceAreaNameList}) => {

  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const animationRef = useRef(false);
  const lmRef = useRef();
  const [curLm, setCurLm] = useState(0);


  function innerOnResults(results) {

    // const video = webcamRef.current.video;
    if(!webcamRef.current){
      return;
    }
    const videoWidth = webcamRef.current.video.videoWidth;
    const videoHeight = webcamRef.current.video.videoHeight;

    // Set canvas width
    if(!canvasRef.current){
      return;
    }

    canvasRef.current.width = videoWidth;
    canvasRef.current.height = videoHeight;

    const canvasElement = canvasRef.current;
    const canvasCtx = canvasElement.getContext("2d");
    canvasCtx.save();
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
    canvasCtx.drawImage(
      results.image,
      0,
      0,
    );

    onResults(results , videoWidth , videoHeight , canvasCtx);

    canvasCtx.restore();
 
  }


  useEffect(() => {

    onInit();

    let camera = null;
    const faceMesh = new FaceMesh({
      locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
      },
    });

    faceMesh.setOptions({
      maxNumFaces: 1,
      minDetectionConfidence: 0.9,
      minTrackingConfidence: 0.9,
    });

    try {
      faceMesh.onResults(innerOnResults);
    } catch (error) {
      console.log(error);
    }

    try {
      if (typeof webcamRef.current !== "undefined" && webcamRef.current !== null) {
        camera = new cam.Camera(webcamRef.current.video, {
          onFrame: async () => {
            await faceMesh.send({ image: webcamRef.current.video });
          },
          width: 640,
          height: 480,
          canvas: canvasRef.current, // Pass canvas reference to Camera constructor
        });
        camera.start();
      }
    } catch (error) {
      console.warn(error);
    }

    return () => {
      if (camera) {
        camera.stop();
      }
      faceMesh.close();
    };
  }, []);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <canvas
        ref={canvasRef}
        className="output_canvas"
        style={{
          transform: "rotateY(180deg)",
          display: showCanvas ? "block" : "none", // Show/hide canvas based on showCanvas prop
        }}
      ></canvas>

      <Webcam
        ref={webcamRef}
        style={{
          transform: "rotateY(180deg)",
          width: "100%",
          height: "100%",
          display: showCanvas ? "none" : "block", // Show/hide webcam based on showCanvas prop
        }}
      />
    </div>
  );
};

export default SmartCamera;
