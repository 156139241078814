import Lottie from "lottie-react";
import scanAnimation from "./scanAnimation.json";
import { useEffect, useRef } from "react";

const ScanAnimation = () => {

  const animation = useRef();

  useEffect(() => {
    animation.current.setSpeed(0.3);
  } , [])

  return <Lottie 
    lottieRef={animation}
    animationData={scanAnimation} 
  />;
};

export default ScanAnimation;