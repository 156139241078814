import React, { useRef, useEffect, useState } from 'react';
import { useOpenCv } from 'opencv-react';
import { FaceMesh } from "@mediapipe/face_mesh";

import { Avatar, Grid } from '@mui/material';
import { ConstructionOutlined, FaceRetouchingNatural } from '@mui/icons-material';
import { faceArea , applyMethods } from '../../faceLandmarks/faceLandmarksDict';

function ShaderCanvas({imageSrc , detect , rotate, setShareableImageSrc , products = [] }) {
    
    const { loaded, cv } = useOpenCv();
    const imageRef = useRef();
    const [faceMeshInst , setFaceMeshInst] = useState(null);

    function hexToRgb(hex) {
        hex = hex.replace(/^#/, '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return { r, g, b };
    }
    

    function fillSection(img , landmarks , section , shaderHexColor , opacity = 0.5, blurKernelSize = 0 , gaussianBlurKernelSize = 0 , shaderType = "dark"){

      // Make sure that the first and last numbers in the section array are the same number
      if(section[0] !== section[section.length - 1]){
        section.push(section[0]);
      }
      // Get the points for the upper lip contour
      const sectionContour = section.reduce((acc, index) => {
          const x = landmarks[index].x * imageRef.current.width;
          const y = landmarks[index].y * imageRef.current.height;
          acc.push(x, y);
          return acc;
      }, []);


      const mask = new cv.Mat.zeros(img.rows, img.cols, cv.CV_8UC4);
      const mask_color = new cv.Mat.zeros(img.rows, img.cols, cv.CV_8UC4);

      // Convert the array of Points to a Mat object
      const sectionMat = cv.matFromArray(sectionContour.length / 2, 1, cv.CV_32SC2, sectionContour);
      // Create a 2D array of points (contour)
      const contours = new cv.MatVector();
      contours.push_back(sectionMat);
    
      // Apply Color
      if (shaderHexColor) {
        const { r, g, b } = hexToRgb(shaderHexColor);
        
        // Fill a new image with the specified color inside the section contour
        const sub = 255 * (opacity);
        cv.fillPoly(mask, contours, new cv.Scalar( sub , sub , sub , sub ));
        cv.fillPoly(mask_color, contours, new cv.Scalar( r , g , b , 255));

        // // Erode the white section
        const erodeSize = 3;
        const erodeKernel = cv.getStructuringElement(cv.MORPH_RECT, new cv.Size(erodeSize, erodeSize));
        cv.erode(mask, mask, erodeKernel);
        // cv.erode(mask_color, mask_color, erodeKernel);

        // Apply Gaussian blur to smooth edges
        cv.GaussianBlur(mask, mask, new cv.Size(gaussianBlurKernelSize, gaussianBlurKernelSize), 0, 0);
        cv.GaussianBlur(mask_color, mask_color, new cv.Size(gaussianBlurKernelSize, gaussianBlurKernelSize), 0, 0);
  
        // // Blur Image
        if (blurKernelSize > 0) {
          cv.boxFilter(mask, mask, -1, new cv.Size(blurKernelSize, blurKernelSize));
          cv.boxFilter(mask_color, mask_color, -1, new cv.Size(blurKernelSize, blurKernelSize));
        }
      }
      

      if (shaderType === "dark" || !shaderType){
        cv.subtract(img , mask , img);
      }

      cv.addWeighted(img , 1 , mask_color , opacity , 0, img);

      contours.delete();
      sectionMat.delete();
      mask.delete();
      mask_color.delete();

    }

    function applyShader(img, landmarks , section , shaderHexColor, opacity = 0.5, blurKernelSize = 0 , gaussianBlurKernelSize = 0 , shaderType = "bright" , debug = false) {
      fillSection(img , landmarks , section , shaderHexColor , opacity , blurKernelSize, gaussianBlurKernelSize , shaderType);
    }

    const detectFace = async () => {
        await faceMeshInst.send({ image: imageRef.current });
    }

    const onResults = (results) => {
      
      let img = new cv.imread(imageRef.current);
      
      // Get landmarks from deteciton
      for(const landmarks of results.multiFaceLandmarks){

        // // Add text with landmark number
        // for (let i = 0; i < landmarks.length; i++) {
        //   cv.putText(
        //     img,
        //     `${i}`,
        //     new cv.Point(landmarks[i].x * imageRef.current.width, landmarks[i].y * imageRef.current.height),
        //     cv.FONT_HERSHEY_SIMPLEX,
        //     0.3, // font size
        //     new cv.Scalar(255, 255, 255), // text color (white)
        //     0.8, // thickness
        //     cv.LINE_AA
        //   );
        // }


        // Fill the area inside the contour with a color
        for(const product of products){
          // console.log(product);
          for(const faceAreaName of product.faceAreasNameList){

            applyShader(
              img, 
              landmarks , 
              faceArea[faceAreaName] , 
              product.color_hex , 
              product.opacity , 
              product.blur,
              product.gaussian_blur,
              product.shader_type,
            );

          }
        }
        
      }      
      
      // display final result on canvas
      cv.imshow("outputCanvas", img);

    }   

  const createShareImage = async () => {

    // Convert canvas content to data URL
    const canvas = document.getElementById("outputCanvas");
    const dataUrl = canvas.toDataURL("image/png");

    // Set the new image source using setShareableImageSrc
    setShareableImageSrc(dataUrl);

  }

  useEffect(() => {
    if (loaded && cv) {
        // Load face mesh model
        const faceMesh = new FaceMesh({
            locateFile: (file) => {
            return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
            },
        });
        setFaceMeshInst(faceMesh);
        faceMesh.setOptions({
            maxNumFaces: 2, // detect up to max faces number
            minDetectionConfidence: 0.5, 
            minTrackingConfidence: 0.5,
        });
        faceMesh.onResults(onResults);
    }
  }, [cv, loaded]);

  if (!loaded) {
    return null;
  }

  return (
    <div>
    <Grid container spacing={2}>
      <Grid item xs={12}>
          <div style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}>
            {
                faceMeshInst &&
                <img ref={imageRef} src={imageSrc} onLoad={detectFace} alt="model" style={{
                  transform: rotate ? "rotateY(180deg)" : null,
                  left: 0,
                  display: detect ? "none" : null,
                  }}/>
            }
          </div>


          <div style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 2,
            // mixBlendMode: 'multiply',
          }}>
            <canvas id="outputCanvas" style={{
              transform: rotate ? "rotateY(180deg)" : null,
              display: detect ? null : "none",
            }}></canvas>
          </div>

      </Grid>
    </Grid>
    </div>
  );
}

export default ShaderCanvas;
