import React, { useCallback, useEffect, useState } from 'react'
import { LoginSocialFacebook } from 'reactjs-social-login';

// MUI
import { Button } from "@mui/material";

// MUI Icons
import FacebookIcon from '@mui/icons-material/Facebook';

import { useLang } from '../lang';
import { useAuth } from '../auth';

function LoomeFacebookLoginButton({loading , setLoading}){
  
  const lang = useLang();
  const auth = useAuth();  

  const [provider, setProvider] = useState('')
  const [profile, setProfile] = useState(null)

  const onLogoutSuccess = useCallback(() => {
    setProfile(null)
    setProvider('')
    alert('logout success')
  }, [])


  // Use the access token to fetch user information from Facebook Graph API
  const fetchFacebookUserData = async () => {
    try {
      const response = await fetch(
        `https://graph.facebook.com/v13.0/me?fields=id,first_name,last_name,name,email,picture.type(large),gender,locale&access_token=${profile.accessToken}`
      );
      const userData = await response.json();
      const signinResponse = await auth.loginWithFacebook(userData);
      // Now you can do something with the user data, such as storing it in your state or performing further actions.
    } catch (error) {
      console.error('Error fetching Facebook user data:', error);
    }
  };

  useEffect(() => {
    if(!profile){
      return;
    }

    fetchFacebookUserData();

  } , [profile])

 
  return (
    
    <LoginSocialFacebook
        isOnlyGetToken
        appId={process.env.REACT_APP_FB_APP_ID || ''}
        onLoginStart={() => {}}
        onResolve={({ provider, data }) => {
          setProvider(provider)
          setProfile(data)
        }}
        onReject={(err) => {
          console.log(err)
        }}
    >
        <Button><FacebookIcon/></Button>
    </LoginSocialFacebook>
  )
}

export default LoomeFacebookLoginButton;