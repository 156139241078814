import FullScreenDialog from "./FullScreenDialog";
import WebView from "../webview/WebView";
import { useCart } from "../cart";
import { Button } from "@mui/material";
import { useEffect } from "react";

function StoreSession({}) {

    const cart = useCart();

    useEffect(() => {
        setInterval(() => {
            console.log("session check");
        } , 5000)
    } , [])

    return ( 
        <>
            <FullScreenDialog open={cart.openSessionDialog} setOpen={cart.setOpenSessionDialog}>
                <WebView srcUrl={cart.sessionUrl}/>
            </FullScreenDialog>
        </>
    );
}

export default StoreSession;