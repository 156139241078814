import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import LoadingAnimation from '../animations/loading/LoadingAnimation';
import { LoomeLogoAvatar } from '../avatar/LoomeLogoAvatar';
import DaynamicLogo from '../avatar/DynamicLogo';
import theme from '../theme';

export default function LoomeLogoBackdrop({ loading }) {

  const [rotate, setRotate] = React.useState(true);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setRotate(prevRotate => !prevRotate);
    }, 2000); // Adjust the interval as needed

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <div
          style={{
            transform: rotate ? 'rotate(360deg)' : 'none',
            transition: 'transform 2s linear',
            transformOrigin: 'center',
          }}
        >
          <DaynamicLogo backgroundColor={theme.palette.primary.main} imageSrc={theme.images.app_logo_white_nobg}/>
        </div>
      </Backdrop>
    </div>
  );
}
