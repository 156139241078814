import { useRef , useEffect , useState} from "react";

import { Avatar, Card, Typography , Box } from "@mui/material";

import ProductDialog from '../dialog/ProductDialog';
import { useNavigate } from "react-router-dom";
import VideoAvatar from '../avatar/VideoAvatar';

import { pbGetImage } from "../../lib/pbGet";
import theme from "../theme";
import StyledTypography from "../text/StyledTypography";
import { useLang } from "../lang";
import { LargePlayButton } from "../buttons/LargePlayButton";


export function ScrollItem({
    size = "200px",
    spacing = 10,
    title,
    elevation = 0,
    imageSrc = theme.images.app_logo_bg,
    onClick = () => {},
    content = null , 
    children,
  }) {

    const lang = useLang();
    const [imageLoaded, setImageLoaded] = useState(false);
  
    useEffect(() => {
      const img = new Image();
      img.src = imageSrc;
  
      img.onload = () => {
        setImageLoaded(true);
      };
  
      return () => {
        img.onload = null;
      };
    }, [imageSrc]);
  
    return (
        <div onClick={onClick}>
            <Card 
              elevation={elevation}
            >
            {
              title &&
              <>
              <Box style={{width: '90%' , position: 'absolute'}} dir={lang.t('code.dir')}>
                <StyledTypography lng={lang.t('code.lng')} dir={lang.t('code.dir')}>
                  {title}
                </StyledTypography>
              </Box>
              </>
            }
                {
                  !content
                  ?
                  <>
                    <Avatar
                        src={imageLoaded && imageSrc ? imageSrc : theme.images.app_logo_bg}
                        variant="rounded"
                        style={{ 
                          width: size, 
                          height: size, 
                          margin: spacing , 
                          backgroundColor: theme.palette.secondary.dark,
                          opacity: imageSrc ? 1 : 0.5
                          }}
                    >
                    </Avatar>
                  </>
                  :content
                }
            </Card>
        </div>
    );
  }

export function TutorialScrollItem({
  tutorial,
  size = "300px",
  spacing = 15,
  elevation = 2,
  children
}){

  const navigate = useNavigate();

  useEffect(() => {
    console.log(tutorial);
  } , [])
  
  const handleNavigation = () => {

    navigate(`/dress-room/tutorial/${tutorial.id}`)

  }

  if(!tutorial || !tutorial.active){
    return null
  }



  return(
    <div style={{ position: 'relative' }}>
      <ScrollItem
        size={size}
        spacing={spacing}
        title={tutorial.title}
        elevation={elevation}
        onClick={handleNavigation}
        content={
          <>
            <div 
              style={{
                position: 'absolute',
                zIndex: 3,
                opacity: 1,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <LargePlayButton onClick={handleNavigation} hidden={false} sizePx={'50px'} iconSizePx={"50px"}/>
            </div>
            
            <Avatar
                src={tutorial.image_url ? tutorial.image_url : theme.images.app_logo_bg}
                variant="rounded"
                style={{ 
                  width: size, 
                  height: size, 
                  margin: spacing , 
                  backgroundColor: theme.palette.secondary.dark,
                  opacity: tutorial.image_url ? 1 : 0.5
                  }}
            >
            </Avatar>

          </>
        }
      >
      </ScrollItem>
  </div>
  )

}


export function ProductScrollItem({
    product,
    size = "200px",
    spacing = 10,
    elevation = 2,
    children
  }) {

    const [openDialog , setOpenDialog] = useState(false);

    if(!product || !product.new){
        return null;
    }

    return (
        <div>
            <ProductDialog open={openDialog} setOpen={setOpenDialog} product={product}/>
            <ScrollItem size={size} spacing={spacing} imageSrc={product.image_url} elevation={elevation} onClick={() => {setOpenDialog(true)}}>
                Loome
            </ScrollItem>
            <div
                style={{
                    position: "relative",
                    width: '25%',
                    bottom: '25%',
                    right: 0,
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.secondary.main,
                    padding: "4px",
                    fontSize: "12px",
                    fontWeight: "bold",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                >
                New
            </div>
        </div>
    );
}

export function HorizontalScroller({children}) {

    const containerRef = useRef(null);
  
    return (
      <div
        ref={containerRef}
        style={{
          overflow: 'auto',
          overflowX: 'scroll',
          whiteSpace: 'nowrap',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {children}
      </div>
    )

}

export function VerticalScroller() {

    return(
        null
    );

}

