import React from 'react';
import Button from '@mui/material/Button';
import ShareIcon from '@mui/icons-material/Share';

const ShareButton = ({ imageSrc }) => {
  const handleShare = async () => {

    if(!imageSrc){
      return;
    }

    try {
      if (navigator.share) {
        await navigator.share({
          title: 'Your app title',
          text: 'Check out this awesome content!',
          url: window.location.href,
          files: [new File([await (await fetch(imageSrc)).blob()], 'image.png')],
        });
      } else {
        // Fallback for browsers that do not support the Web Share API
        alert('Web Share API is not supported in your browser.');
      }
    } catch (error) {
      console.error('Error sharing:', error.message);
    }
  };

  return (
    <Button disabled={!imageSrc} onClick={handleShare}>
      <ShareIcon />
    </Button>
  );
};

export default ShareButton;
