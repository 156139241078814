import React from 'react';
import { Divider } from "@mui/material";
import { LoomeGoogleLoginButtonV1 } from './LoomeGoogleLoginButton';
import LoomeFacebookLoginButton from './LoomeFacebookLoginButton';
import ChangeLangGlobeButton from '../buttons/ChangeLangGlobeButton';
import { GoogleOAuthProvider } from '@react-oauth/google';
import theme from '../theme';

function SignWithAutoProviders({ loading, setLoading }) {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GG_APP_ID || ''}>
      <div style={{ marginBottom: 5 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: theme.sizes.gap.small, // Adjust the gap between buttons to your preference
          }}
        >
          {/* <LoomeGoogleLoginButtonV1 loading={loading} setLoading={setLoading} />
          <LoomeFacebookLoginButton loading={loading} setLoading={setLoading} /> */}
          <ChangeLangGlobeButton />
        </div>
      </div>
    </GoogleOAuthProvider>
  );
}

export default SignWithAutoProviders;
