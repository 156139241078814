import * as React from 'react';

// API
import { pbGetById } from '../../lib/pbGet';
import pb from '../../lib/pocketbase';

// MUI
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

// MUI Icons
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';


// Custom
import { toPrice } from '../../lib/pricing';
import { useAuth } from '../auth';
import { Avatar, Grid } from '@mui/material';
import ProductDialog from '../dialog/ProductDialog';
import { TrendingUp } from '@mui/icons-material';
import TruncatedText from '../text/TruncatedText';
import { useCart } from '../cart';
import theme from '../theme';


interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));


export default function ProductCard({ productId, children }) {

  const auth = useAuth();
  const cart = useCart()
  const [product, setProduct] = React.useState(null);
  const [inCart , setInCart] = React.useState(false);
  const [openDialog , setOpenDialog] = React.useState(false);

  const handleCartButtonClick = () => {

    if(Object.keys(cart.cartCookie).indexOf(productId) !== -1){
      cart.removeProduct(productId);
      setInCart(false);
    }else{
      cart.addProduct(productId);
      setInCart(true);
    }

  }

  const fetchProductRecord = async () => {
    const productRecord = await pbGetById("products", productId);
    setProduct(productRecord);
  };

  React.useEffect(() => {
    fetchProductRecord();

  }, []);

  if (!product) {
    return null;
  }

  return (
    <Card dir="rtl" sx={{ width: "100%" }}>
      <ProductDialog open={openDialog} setOpen={setOpenDialog} product={product}/>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Button onClick={() => {setOpenDialog(true)}}>
            <Avatar src={product.image_url ? product.image_url : theme.images.default_product_image}  variant='rounded' style={{height: 100 , width: 100}}/>
          </Button>
        </Grid>
        <Grid item xs={8}>
          <CardHeader title={product.title} subheader={toPrice(product.price)} />
        </Grid>

      </Grid>
      <CardContent sx={{ height: "100%" }}>
        <Typography variant="body2" color="text.secondary">
          {product.desc}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        {/* <Rating name="read-only" value={3} readOnly /> */}
        <IconButton aria-label="remove" onClick={handleCartButtonClick}>
          {
            inCart
            ?
            <RemoveShoppingCartIcon/>
            :
            <AddShoppingCartIcon/>
          }
        </IconButton>
      </CardActions>
    </Card>
  );
}
