import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth";
import LoomeLogoBackdrop from "../backdrop/LoomeLogoBackdrop";


function NoMatch() {

    const auth = useAuth();
    const navigate = useNavigate();
    const [loading , setLoading] = useState(false);

    useState(() => {
        const authRefresh = async () => {
            // check if the user is still valid after going out
            setLoading(true)
            await auth.authRefresh();
            setLoading(false);
        }
      
        authRefresh();
    } , [])

    return ( 
        <LoomeLogoBackdrop loading={loading}/>
     );
}

export default NoMatch;