import { useState , useEffect } from 'react';
import toast from 'react-hot-toast';

// MUI
import { Typography , Box, Button, Avatar, Card , CardContent} from '@mui/material';

// MUI Icons
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FavoriteIcon from '@mui/icons-material/Favorite';

// API
import { useCart } from '../cart'; 
import { pbGetById , store_url } from '../../lib/pbGet';


import theme from '../theme';
import { toPrice } from '../../lib/pricing';
import { useLang } from '../lang';

import WebView from '../webview/WebView';
import StyledTypography from '../text/StyledTypography';
import ProductDialog from '../dialog/ProductDialog';
import TopBar from '../topbar/TopBar';
import FullScreenDialog from '../dialog/FullScreenDialog';
import { CostumSubmitButton } from '../buttons/CostumSubmitButton';


const WishListProductCard = ({ productId, avatarSize = 100 }) => {
  const cart = useCart();
  const lang = useLang();

  const [product, setProduct] = useState(null);
  const [openProductDialog , setOpenProductDialog] = useState(false);

  const fetchProduct = async () => {
    const productRecord = await pbGetById('products', productId);
    setProduct(productRecord);
  };

  const handleGoToStoreClick = () => {
    if(!product.store_url || product.store_url === ''){
      window.location.href = store_url;
    }else{
      window.location.href = product.store_url;
    }
  };

  const handleClickLikeProduct = async () => {

    // toast('' , {icon: '🤎'})

  }

  useEffect(() => {
    fetchProduct();
  }, [cart.cartCookie]);

  if (!product) {
    return null;
  }

  return (
    <div>

      <ProductDialog 
        open={openProductDialog} 
        setOpen={setOpenProductDialog} 
        product={product}
      />
      <Card
        elevation={20}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: theme.palette.secondary.main,
          padding: 2,
          marginBottom: 5,
          maxWidth: '100vh',
          position: 'relative', // Set the position to relative
        }}
      >
        <img
          src={product.image_url}
          alt={product.name}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            opacity: 0.45,
            height: '100%',
            filter: 'blur(20px)', // Add blur effect
            zIndex: 1, // Set z-index to make sure the image is behind the card
          }}
        />

        <Card
          elevation={2}
          style={{
            marginBottom: '15px'
          }}
        >
          <Avatar
            onClick={() => {setOpenProductDialog(true)}}
            variant='rounded'
            src={product.image_url ? product.image_url : theme.images.default_product_image}
            sx={{ width: avatarSize, height: avatarSize, marginBottom: 2, zIndex: 1 }} // Set z-index for the avatar
          />
        </Card>
          <Typography>{product.title}</Typography>

        <CardContent sx={{ flexGrow: 1, textAlign: 'center', position: 'relative', zIndex: 1 }}>
          <Typography variant="h6" component="div">
            {product.name}
          </Typography>

          <Typography variant="body2" color="text.secondary" sx={{ marginTop: 1 }}>
            {product.desc}
          </Typography>
        </CardContent>

        <Typography variant="h6" sx={{ marginBottom: 1, zIndex: 1 }}>
          {toPrice(product.price)}
        </Typography>

        <Box
          style={{
            zIndex: 3
          }}
        >
          <Button  
            onClick={() => cart.removeProduct(productId)}
            startIcon={<HighlightOffIcon/>}
          >
            {/* {lang.t('wishlist.removeProduct')} */}
          </Button>
          <Button  
            onClick={handleClickLikeProduct}
            startIcon={<FavoriteIcon/>}
          >
            {/* {lang.t('wishlist.removeProduct')} */}
          </Button>

        </Box>
      </Card>
    </div>
  );
};

const WishListPageHeader = () => {
  
  const lang = useLang();

  
  return(
    
    <Card sx={{
      backgroundColor: theme.palette.primary.main , 
      height: '7%' , 
      position: 'absolute', 
      width: '100%' , 
      zIndex: 2,
      borderRadius: 0
      }}
      elevation={10}
      >
      <div style={{marginLeft: 10 , marginRight: 10}}>
        <StyledTypography 
          variant='h4' 
          color={theme.palette.secondary.main} 
          dir={lang.t('code.dir')}
          lng={lang.t('code.lng')}
        >
          {lang.t('wishlist.title')}
        </StyledTypography>
      </div>
    </Card>
  )

}


const CartScreen = () => {
  const lang = useLang();
  const cart = useCart();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!cart.cartCookie) {
      return;
    }
    if (Object.keys(cart.cartCookie).length === 0) {
      toast(lang.t('toast.listEmpty'), { duration: 3 * 1000 });
    }
  }, [cart.cartCookie]);

  useEffect(() => {
    // Check local storage for loading state on page load
    const storedLoading = localStorage.getItem('cartScreenLoading');
    if (storedLoading) {
      setLoading(JSON.parse(storedLoading));
    }
  }, []);

  const handleCheckoutClick = () => {
    // Set loading state to true and save to local storage
    setLoading(true);
    localStorage.setItem('cartScreenLoading', JSON.stringify(true));

    // Perform your checkout logic here
    // ...

    // For demonstration purposes, simulate an asynchronous action (e.g., API call)
    setTimeout(() => {
      // Set loading state back to false and update local storage
      setLoading(false);
      localStorage.setItem('cartScreenLoading', JSON.stringify(false));
    }, 2000); // Adjust the timeout as needed
  };

  if (!cart.cartCookie) {
    return null;
  }

  return (
    <>
      <TopBar title={lang.t('wishlist.title')} />

      <div style={{ height: window.innerHeight, overflow: 'auto' }}>
        <div style={{ height: '10%' }}></div>
        {Object.keys(cart.cartCookie).map((productId, key) => (
          <WishListProductCard productId={productId} key={key}></WishListProductCard>
        ))}

        {Object.keys(cart.cartCookie).length !== 0 && (
          <Button startIcon={<DeleteSweepIcon />} onClick={() => cart.emptyCart()}>
            {lang.t('wishlist.emptyWishlist')}
          </Button>
        )}

        <Box
          style={{
            position: 'absolute',
            bottom: '20%', // Adjust the distance from the bottom as needed
            left: '50%', // Center horizontally
            transform: 'translateX(-50%)', // Center horizontally
            zIndex: 3,
          }}
        >
          <a href={`${theme.store.base_url}`} target="_blank" rel="noreferrer external">
            <CostumSubmitButton variant="contained" color="primary" onClick={handleCheckoutClick}>
              GO TO STORE
            </CostumSubmitButton>
          </a>
        </Box>

        <div style={{ height: '30%' }}></div>
      </div>
    </>
  );
};

export default CartScreen;