import { endpoint } from "./backend_endpoint";
export const store_url = 'https://loomecosmetics.com';


export const pbGetImage = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl , {
        method: "get",
        headers: new Headers({
          "ngrok-skip-browser-warning": "69420",
        }),
      });
      const blob = await response.blob();
  
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const dataURL = reader.result;
          resolve(dataURL);
        };
        reader.onerror = (error) => {
          reject(error);
        };
  
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Error fetching image:', error);
      throw error;
    }
  };
  

export const pbGetAll = async (resource , sort = '' , perPage = 500) => {
    try {

        const apiUrl = `${endpoint}/api/collections/${resource}/records?sort=${sort}&perPage=${perPage}`
        // Making a GET request using fetch
        const response = await fetch(apiUrl , {
            method: "get",
            headers: new Headers({
              "ngrok-skip-browser-warning": "69420",
            }),
          });

        // Check if the request was successful (status code in the range of 200-299)
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Parse the response as JSON
        const data = await response.json();
        return data;
    } catch (error) {
        // Handle errors that occurred during the fetch
        console.error('Fetch error:', error);

        // You might want to throw the error again or handle it differently based on your needs
        throw error;
    }
};

export const pbGetById = async (resource , id) => {
    try {

        const apiUrl = `${endpoint}/api/collections/${resource}/records/${id}`
        // Making a GET request using fetch
        const response = await fetch(apiUrl , {
            method: "get",
            headers: new Headers({
              "ngrok-skip-browser-warning": "69420",
            }),
          });

        // Check if the request was successful (status code in the range of 200-299)
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Parse the response as JSON
        const data = await response.json();
        return data;
    } catch (error) {
        // Handle errors that occurred during the fetch
        console.error('Fetch error:', error);

        // You might want to throw the error again or handle it differently based on your needs
        throw error;
    }

}