import React, { useRef, useEffect, useState } from "react";
import Webcam from "react-webcam";
import { FaceMesh } from "@mediapipe/face_mesh";
import * as cam from "@mediapipe/camera_utils";

// MUI
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';

// MUI Icons
import VideocamOffIcon from '@mui/icons-material/VideocamOff';

import { faceArea } from "../../faceLandmarks/faceLandmarksDict";
import { Typography , Box, Button } from "@mui/material";
import SmartCamera from "./SmartCamera";
import CenteredDiv from './CenteredDiv';


const FaceAreasRadioButtons = ({ faceAreaKeys, handleChange, selectedValue }) => {
  
  
  return (
    <div style={{height: '50%' , overflow: 'auto'}}>
      
      <FormControl>
      
        <FormControlLabel control={<Checkbox defaultChecked />} label="Show Points" />
        <FormControlLabel control={<Checkbox defaultChecked />} label="Show Numbers" />
        
        <Box>
          <Typography>Face Areas</Typography>
        </Box>

        <RadioGroup
          aria-labelledby="face-areas-label"
          value={selectedValue}
          onChange={handleChange}
          name="face-areas-group"
        >
          {faceAreaKeys.map((key) => (
            <FormControlLabel
              key={key}
              value={key}
              control={<Radio />}
              label={key}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
};


const CameraComponent = ({showCanvas , instructionRecordList , curIndexRef , showInstruction , debug = false}) => {

  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const animationRef = useRef(false);
  const curFaceAreaNameList = useRef([]);
  const lmRef = useRef();
  const [curLm, setCurLm] = useState(0);

  // DEBUG VALUES
  const [selectedFaceArea, setSelectedFaceArea] = React.useState('1'); // Set an initial value if needed
  const [selectedFaceAreaName , setSelectedFaceAreaName] = React.useState('rightBrowSection')
  const [selectedNumber , setSelectedNumber] = useState('1');
  const faceAreaNameRef = useRef(null);
  const selectedNumberRef = useRef(null);


  const handleFaceAreaChange = (event) => {
    console.log(faceArea[event.target.value]);
    setSelectedFaceArea(faceArea[event.target.value])
    setSelectedFaceAreaName(event.target.value);
    // Add any other logic you need based on the selected face area
  };

  const faceAreaKeys = Object.keys(faceArea); // Assuming faceArea is available


  const drawCircle = (cctxCur , x, y, color = [255, 255, 255], radius = 10, opacity = 1) => {
    if (!cctxCur) {
      return;
    }
  
    cctxCur.beginPath();
    cctxCur.arc(x, y, radius, 0, 2 * Math.PI);
    cctxCur.fillStyle = `rgba(${color},${opacity})`;
    cctxCur.fill();
  };

  const drawFaceAreas = (cctxCur , faceLandmarks , areaNames = [] , width , height , color = [255, 255, 255], radius = 2, opacity = 1) => {

    if(debug){
      if(!selectedNumberRef.current){
        return
      }
      const index = parseInt(selectedNumberRef.current.innerHTML)
      const point = faceLandmarks[index];
      const x = point.x * width;
      const y = point.y * height;
      cctxCur.fillStyle = "red";
      cctxCur.font = "12px Arial";
      cctxCur.fillText(index, x - 5, y - 5); // Adjust the position for better visibility
      drawCircle(cctxCur , x, y, color , radius ,opacity);
    }

    for( let i=0 ; i < areaNames.length ; i++ ){
      const curArea = faceArea[areaNames[i]];

      
      for(let j = 0 ; j < curArea.length ; j++){
        const point = faceLandmarks[curArea[j]];
        const x = point.x * width;
        const y = point.y * height;
        
        if(debug){
          // Draw landmark number
          cctxCur.fillStyle = "white";
          cctxCur.font = "12px Arial";
          cctxCur.fillText(curArea[j], x - 5, y - 5); // Adjust the position for better visibility
        }

        drawCircle(cctxCur , x, y, color , radius ,opacity);
      }
    }
  }

  const basePointAnimation = (
    cctxCur,
    faceLandmarks,
    width,
    height,
    startLandmarkIndex,
    endLandmarkIndex,
    startRadius = 1,
    endRadius = 1,
    duration = 1,
    color = [255, 255, 255], // Default to white color
    startOpacity = 1,
    endOpacity = 1
  ) => {
    
  
    const startTime = performance.now();
  
    const animateFrame = (currentTime) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);
      const startPoint = faceLandmarks[startLandmarkIndex];
      const endPoint = faceLandmarks[endLandmarkIndex];
  
      const startX = startPoint.x * width;
      const startY = startPoint.y * height;
      const targetX = endPoint.x * width;
      const targetY = endPoint.y * height;
  
      const currentX = (1 - progress) * startX + progress * targetX;
      const currentY = (1 - progress) * startY + progress * targetY;
  
      const currentRadius = (1 - progress) * startRadius + progress * endRadius;
      const currentOpacity = (1 - progress) * startOpacity + progress * endOpacity;
  
      drawCircle(cctxCur , currentX, currentY, color, currentRadius, currentOpacity);
  
      if (progress < 1) {
        requestAnimationFrame(animateFrame);
      } else {
        // Animation finished, reset the flag
        animationRef.current = false;
      }
    };
  
    requestAnimationFrame(animateFrame);
  };

  const animationPointFollowPath = (faceLandmarks, areaLandmarks) => {
    const animationConfig = {
      startRadius: 5,
      endRadius: 3,
      duration: 700,
    };
    

    for (let i = 0; i < areaLandmarks.length - 1; i++) {
      const startLandmarkIndex = areaLandmarks[i];
      const endLandmarkIndex = areaLandmarks[i+1];
  
      basePointAnimation(
        faceLandmarks,
        startLandmarkIndex,
        endLandmarkIndex,
        animationConfig.startRadius,
        animationConfig.endRadius,
        animationConfig.duration
      );
    }
  };
  
  function onResultsDebug(results){
    // const video = webcamRef.current.video;
    if(!webcamRef.current){
      return;
    }
    const videoWidth = webcamRef.current.video.videoWidth;
    const videoHeight = webcamRef.current.video.videoHeight;

    // Set canvas width
    if(!canvasRef.current){
      return;
    }

    canvasRef.current.width = videoWidth;
    canvasRef.current.height = videoHeight;

    const canvasElement = canvasRef.current;
    const canvasCtx = canvasElement.getContext("2d");
    canvasCtx.save();
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
    canvasCtx.drawImage(
      results.image,
      0,
      0,
    );
    
    if (results.multiFaceLandmarks) {

      for (const landmarks of results.multiFaceLandmarks) {
        
        const faceAreaNameValue = faceAreaNameRef.current.innerHTML;

        drawFaceAreas(canvasCtx , landmarks , [faceAreaNameValue] , videoWidth , videoHeight);
        


        // if(lmRef.current){
        //   for(let i = 0 ; i < landmarks.length ; i++){
        //     const point = landmarks[i];
        //     console.log(point);
        //     const x = point.x * videoWidth;
        //     const y = point.y * videoHeight;
        //     if( i == lmRef.current.innerHTML){
        //       drawCircle(canvasCtx , x , y , [255,0,0] , 3);
        //     }
        //     else{
        //       drawCircle(canvasCtx , x , y , [100,100,100] , 1);
              
        //     }
        //   }
        // }
        
      }
    }

    canvasCtx.restore();
  }


  function onResults(results) {

    // const video = webcamRef.current.video;
    if(!webcamRef.current){
      return;
    }
    const videoWidth = webcamRef.current.video.videoWidth;
    const videoHeight = webcamRef.current.video.videoHeight;

    // Set canvas width
    if(!canvasRef.current){
      return;
    }

    canvasRef.current.width = videoWidth;
    canvasRef.current.height = videoHeight;

    const canvasElement = canvasRef.current;
    const canvasCtx = canvasElement.getContext("2d");
    canvasCtx.save();
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
    canvasCtx.drawImage(
      results.image,
      0,
      0,
    );
    
    if (results.multiFaceLandmarks) {

      for (const landmarks of results.multiFaceLandmarks) {
        if(!curIndexRef.current){
          return
        }
        const faceAreaNameList = instructionRecordList[parseInt(curIndexRef.current.innerHTML)].faceAreasNameList;
        for (let i = 0 ; i < faceAreaNameList.length ; i++){
          drawFaceAreas(canvasCtx , landmarks , faceAreaNameList , videoWidth , videoHeight);

          // const curArea = faceArea[faceAreaNameList[i]];
          // basePointAnimation(canvasCtx , landmarks , videoWidth , videoHeight , curArea[0] , curArea[curArea.length - 1] , 10 , 5 , 1000)
        }
      
      }
    }
    canvasCtx.restore();
  }
  
  useEffect(() => {

    let camera = null;
    
    const faceMesh = new FaceMesh({
      locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
      },
    });

    faceMesh.setOptions({
      maxNumFaces: 1,
      minDetectionConfidence: 0.9,
      minTrackingConfidence: 0.9,
    });

    try {
      if(debug){
        faceMesh.onResults(onResultsDebug);

      }else{
        faceMesh.onResults(onResults);
      }
    } catch (error) {
      console.log(error);
    }

    try {
      if (typeof webcamRef.current !== "undefined" && webcamRef.current !== null) {
        camera = new cam.Camera(webcamRef.current.video, {
          onFrame: async () => {
            await faceMesh.send({ image: webcamRef.current.video });
          },
          width: 640,
          height: 480,
          canvas: canvasRef.current, // Pass canvas reference to Camera constructor
        });
        camera.start();
      }
    } catch (error) {
      console.warn(error);
    }

    return () => {
      if (camera) {
        camera.stop();
      }
      faceMesh.close();
    };

  }, [curIndexRef.current , selectedFaceArea]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <canvas
        ref={canvasRef}
        className="output_canvas"
        style={{
          transform: "rotateY(180deg)",
          display: showCanvas ? "block" : "none", // Show/hide canvas based on showCanvas prop
        }}
      ></canvas>

      <Webcam
        ref={webcamRef}
        style={{
          transform: "rotateY(180deg)",
          width: "100%",
          height: "100%",
          display: showCanvas ? "none" : "block", // Show/hide webcam based on showCanvas prop
        }}
      />

      {
        debug &&
        <>
        <div style={{display: 'flex' , flexDirection: 'row'}}>
          <Button onClick={() => {selectedNumberRef.current.innerHTML = parseInt(selectedNumberRef.current.innerHTML) + 1}}>+</Button>
            <p ref={selectedNumberRef}>{selectedNumber}</p>
          <Button onClick={() => {selectedNumberRef.current.innerHTML = parseInt(selectedNumberRef.current.innerHTML) - 1}}>-</Button>
        </div>
          <p ref={faceAreaNameRef}>{selectedFaceAreaName}</p>
          <FaceAreasRadioButtons
            faceAreaKeys={faceAreaKeys}
            handleChange={handleFaceAreaChange}
            selectedValue={selectedFaceArea}
          />
        </>
      }

    </div>
  );
}

export default CameraComponent;
