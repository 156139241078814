import { useEffect , useState } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

import { pbGetAll } from '../../../lib/pbGet';
import pb from '../../../lib/pocketbase';
import { useAuth } from '../../auth';
import { Box, Typography , Button } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';
import { LinePlot } from '@mui/x-charts/LineChart';
import { BarPlot } from '@mui/x-charts/BarChart';
import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis';
import { ChartsYAxis } from '@mui/x-charts/ChartsYAxis';
import { axisClasses } from '@mui/x-charts/ChartsAxis';

import CounterCard from './CounterCard';


function AxisWithComposition() {
  return (
    <Box sx={{ width: '100%', maxWidth: 600 }}>
      <ResponsiveChartContainer
        xAxis={[
          {
            scaleType: 'band',
            data: ['Q1', 'Q2', 'Q3', 'Q4'],
            id: 'quarters',
            label: 'Quarters',
          },
        ]}
        yAxis={[{ id: 'money' }, { id: 'quantities' }]}
        series={[
          {
            type: 'line',
            id: 'revenue',
            yAxisKey: 'money',
            data: [5645, 7542, 9135, 12221],
          },
          {
            type: 'bar',
            id: 'cookies',
            yAxisKey: 'quantities',
            data: [3205, 2542, 3135, 8374],
          },
          {
            type: 'bar',
            id: 'icecream',
            yAxisKey: 'quantities',
            data: [1645, 5542, 5146, 3735],
          },
        ]}
        height={400}
        margin={{ left: 70, right: 70 }}
        sx={{
          [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: 'translate(-25px, 0)',
          },
          [`.${axisClasses.right} .${axisClasses.label}`]: {
            transform: 'translate(30px, 0)',
          },
        }}
      >
        <BarPlot />
        <LinePlot />
        <ChartsXAxis axisId="quarters" label="2021 quarters" labelFontSize={18} />
        <ChartsYAxis axisId="quantities" label="# units sold" />
        <ChartsYAxis axisId="money" position="right" label="revenue" />
      </ResponsiveChartContainer>
    </Box>
  );
}


export default function ProductsStats() {

  const heighest_price = 10;
  const auth = useAuth();
  const [showSection , setShowSection] = useState(true);
  const [data , setData] = useState([0]);
  const [axisData , setAxisData] = useState([""]);
  const [totalItems , setTotalItems] = useState(0);

  const fetchProducts = async () => {
    
    const heights_prices_products_prices = [];
    const heights_prices_products_names = [];
    const products = await pbGetAll('products' , '-price');
    for( let i = 0 ; i < heighest_price ; i++){
      heights_prices_products_prices.push(products.items[i].price);
      heights_prices_products_names.push(products.items[i].title);
    }

    setData(heights_prices_products_prices);
    setAxisData(heights_prices_products_names);
    setTotalItems(products.totalItems);

  }

  useEffect(() => {

    fetchProducts();

  } , [])

  return (
    <Box>
      <div>
        <Button onClick={() => {setShowSection(!showSection)}}>Products Stats</Button>
      </div>
      {
        showSection &&
        <Box>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly'
            }}
          >
          <CounterCard countTo={totalItems} label={"# Products"}/>
          

          </div>
          <BarChart
            series={[{ data: data }]}
            xAxis={[{ scaleType: 'band', data: axisData }]}
            height={300}
            width={window.innerWidth}
          />
          <AxisWithComposition/>
        </Box>
      }
    </Box>
  );
}





