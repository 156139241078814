
const all_landmarks = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
  61,
  62,
  63,
  64,
  65,
  66,
  67,
  68,
  69,
  70,
  71,
  72,
  73,
  74,
  75,
  76,
  77,
  78,
  79,
  80,
  81,
  82,
  83,
  84,
  85,
  86,
  87,
  88,
  89,
  90,
  91,
  92,
  93,
  94,
  95,
  96,
  97,
  98,
  99,
  100,
  101,
  102,
  103,
  104,
  105,
  106,
  107,
  108,
  109,
  110,
  111,
  112,
  113,
  114,
  115,
  116,
  117,
  118,
  119,
  120,
  121,
  122,
  123,
  124,
  125,
  126,
  127,
  128,
  129,
  130,
  131,
  132,
  133,
  134,
  135,
  136,
  137,
  138,
  139,
  140,
  141,
  142,
  143,
  144,
  145,
  146,
  147,
  148,
  149,
  150,
  151,
  152,
  153,
  154,
  155,
  156,
  157,
  158,
  159,
  160,
  161,
  162,
  163,
  164,
  165,
  166,
  167,
  168,
  169,
  170,
  171,
  172,
  173,
  174,
  175,
  176,
  177,
  178,
  179,
  180,
  181,
  182,
  183,
  184,
  185,
  186,
  187,
  188,
  189,
  190,
  191,
  192,
  193,
  194,
  195,
  196,
  197,
  198,
  199,
  200,
  201,
  202,
  203,
  204,
  205,
  206,
  207,
  208,
  209,
  210,
  211,
  212,
  213,
  214,
  215,
  216,
  217,
  218,
  219,
  220,
  221,
  222,
  223,
  224,
  225,
  226,
  227,
  228,
  229,
  230,
  231,
  232,
  233,
  234,
  235,
  236,
  237,
  238,
  239,
  240,
  241,
  242,
  243,
  244,
  245,
  246,
  247,
  248,
  249,
  250,
  251,
  252,
  253,
  254,
  255,
  256,
  257,
  258,
  259,
  260,
  261,
  262,
  263,
  264,
  265,
  266,
  267,
  268,
  269,
  270,
  271,
  272,
  273,
  274,
  275,
  276,
  277,
  278,
  279,
  280,
  281,
  282,
  283,
  284,
  285,
  286,
  287,
  288,
  289,
  290,
  291,
  292,
  293,
  294,
  295,
  296,
  297,
  298,
  299,
  300,
  301,
  302,
  303,
  304,
  305,
  306,
  307,
  308,
  309,
  310,
  311,
  312,
  313,
  314,
  315,
  316,
  317,
  318,
  319,
  320,
  321,
  322,
  323,
  324,
  325,
  326,
  327,
  328,
  329,
  330,
  331,
  332,
  333,
  334,
  335,
  336,
  337,
  338,
  339,
  340,
  341,
  342,
  343,
  344,
  345,
  346,
  347,
  348,
  349,
  350,
  351,
  352,
  353,
  354,
  355,
  356,
  357,
  358,
  359,
  360,
  361,
  362,
  363,
  364,
  365,
  366,
  367,
  368,
  369,
  370,
  371,
  372,
  373,
  374,
  375,
  376,
  377,
  378,
  379,
  380,
  381,
  382,
  383,
  384,
  385,
  386,
  387,
  388,
  389,
  390,
  391,
  392,
  393,
  394,
  395,
  396,
  397,
  398,
  399,
  400,
  401,
  402,
  403,
  404,
  405,
  406,
  407,
  408,
  409,
  410,
  411,
  412,
  413,
  414,
  415,
  416,
  417,
  418,
  419,
  420,
  421,
  422,
  423,
  424,
  425,
  426,
  427,
  428,
  429,
  430,
  431,
  432,
  433,
  434,
  435,
  436,
  437,
  438,
  439,
  440,
  441,
  442,
  443,
  444,
  445,
  446,
  447,
  448,
  449,
  450,
  451,
  452,
  453,
  454,
  455,
  456,
  457,
  458,
  459,
  460,
  461,
  462,
  463,
  464,
  465,
  466,
  467,
  468
]
const above_right_eye_bright_section = [
  55,
  133,
  173,
  157,
  158,
  159,
  160,
  130,
  156,
  46,
  53,
  52,
  65,
  55,
]
const above_left_eye_bright_section = [
  398,
  384,
  385,
  386,
  387,
  388,
  446,
  276,
  283,
  282,
  295,
  285,
  // 255,446,254,263,359,384,385,386,387,388,398,466,336,296,334,293,300,276,283,282,295,285
]

const under_right_eye_bright_section = [111,117,118,119,120,121,121,26,22,23,24,110,25,113,35,]
const under_left_eye_bright_section = [346,347,348,349,350,463,341,256,252,253,254,339,255,342,265,340]


const tip_nose_point = [4]
const under_chick_bone_line = [177,213,192,401,433,416]
const base_nose_to_lip_edge = [48,57,92,278,287,322]

const right_inner_eye_point = [133]
const left_inner_eye_point = [362]
const center_forehead_triangle = [9,108,337]
const bottom_right_outer_eyeliner_line = [24,25,226]
const bottom_left_outer_eyeliner_line = [255,446,254]
const top_right_outer_eyeliner_line = [160,247]
const top_left_outer_eyeliner_line = [342,387]
const bottom_right_brow_line = [53,46,156]
const bottom_left_brow_line = [283,276,353]
const right_hair_line = [67,103,54,21,162]
const left_hair_line = [297,332,284,251,389]
const tint_blush = [36,50,266,280]
const conceler_points = [24,49,133,175,226,253,255,279,362]
const eye_corrector = [22,23,112,252,253,341]
const top_upper_lip = [409,270,269,267,0,37,39,40,62,306,]
const bottom_upper_lip = [13,82,38,81,41,80,42,308,292,291,268,415,271,272,310,312]
const upper_lip_section = [409, 270, 269, 267, 0, 37, 39, 40, 62,42 ,80,81, 38,13, 271,272, 292, 291];
const top_lower_lip = [403,325,319,318,317,14,178,89,88,86,307]
const bottom_lower_lip = [314,375,405,321,17,84,91,181,77]
const lower_lip_section = [14,317,318,325,375,321,405,314,17,84,181,91,77,88,178]
const right_brow_section = [107,66,105,63,70,46,53,52,65,55]
const left_brow_section = [336,296,334,293,300,276,283,282,295,285]
const right_top_eyeliner = [130,33 ,133,157,158,159,160,161,173,246]
const right_bottom_eyeliner = [23,24,25 ,22,26,110,112, ]
const left_top_eyeliner = [263,359,384,385,386,387,388,398,466,]
const left_bottom_eyeliner = [339,255,252,253,254,256,341,463]
const center_nose_line = [1,4,5,6,8]
const right_nose_line = [3,45,122]
const right_chick_bone = [34,50,116,205]
const right_upper_chick = [101,111,117,118,143]
const right_lower_chick = []
const right_chick_shader_section = [34,116,50,205,207,187,123,227]
const left_nose_line = [248,275,351]
const left_chick_bone = [264,345,425,280]
const left_upper_chick = [330,340,346,347,372]
const left_lower_chick = []
const left_chick_shader_section = [264,345,280,425,427,411,352,447]
const right_nose_brow_connection = [193 , 55]
const left_nose_brow_connection = [285 , 417]
const brow_mid_point = [9,]
const right_jaw_line = [58,136,172]
const left_jaw_line = [288,365,397]
const center_chin_point = []
const left_chick_blush_section = [330,347,346,280,425]
const right_chick_blush_section = [101,118,117,50,205]

const all_face_blur_section = [10,338,297,332,284,251,389,356,454,323,361,288,397,365,379,378,400,377,152,148,176,149,150,136,172,58,132,93,234,127,162,21,54,103,67,109]
const right_upper_chick_section = [203,205,50,116,34,139,156,225,25,110,24,23,22,26,224,245,188,174,198,49]
const left_upper_chick_section = [423,425,280,345,264,368,383,353,446,255,339,254,253,252,256,412,399,456,360]
const nose_front_section = [134,51,5,281,363,440,274,2,44,220,]

// export const applyMethods = {

//   fillSection : "fill_section",
//   bluredLine : "blured_line",
  
// }

export const faceArea = {

  aboveRightEyeBrightSection: above_right_eye_bright_section,
  aboveLeftEyeBrightSection: above_left_eye_bright_section,

  underRightEyeBrightSection: under_right_eye_bright_section,
  underLeftEyeBrightSection: under_left_eye_bright_section,



  allLandmarks: all_landmarks,
  allFaceBlurSection: all_face_blur_section,
  
  // # hair
  rightHairLine: right_hair_line,
  leftHairLine: left_hair_line,

  // # forehead
  centerForeheadTriangle: center_forehead_triangle,
  
  // # brows
  browMidPoint: brow_mid_point,
  // ## right brow
  rightBrowSection: right_brow_section,
  bottomRightBrowLine: bottom_right_brow_line,
  // ## left brow
  leftBrowSection: left_brow_section,
  bottomLeftBrowLine: bottom_left_brow_line,
  
  // # eyes
  rightTopEyeliner: right_top_eyeliner,
  topRightOuterEyelinerLine: top_right_outer_eyeliner_line,
  bottomRightOuterEyelinerLine: bottom_right_outer_eyeliner_line,
  rightBottomEyeliner: right_bottom_eyeliner,
  leftTopEyeliner: left_top_eyeliner,
  leftBottomEyeliner: left_bottom_eyeliner,
  topLeftOuterEyelinerLine: top_left_outer_eyeliner_line,
  bottomLeftOuterEyelinerLine: bottom_left_outer_eyeliner_line,
  
  concelerPoints: conceler_points,
  rightInnerEyePoint: right_inner_eye_point,
  leftInnerEyePoint: left_inner_eye_point,
  eyeCorrectorPoints: eye_corrector,
  
  rightNoseBrowConnection: right_nose_brow_connection,
  leftNoseBrowConnection: left_nose_brow_connection,
  
  // # nose
  rightNoseLine: right_nose_line,
  leftNoseLine: left_nose_line,
  centerNoseLine: center_nose_line,
  noseFrontSection: nose_front_section,
  baseNoseToLipEdge: base_nose_to_lip_edge,
  tipNosePoint: tip_nose_point,
  
  // # chicks
  tintBlush: tint_blush,
  // ## right
  rightUpperChick: right_upper_chick,
  rightChickBone: right_chick_bone,
  rightLowerChick: right_lower_chick,
  rightChickShaderSection: right_chick_shader_section,
  rightChickBlushSection: right_chick_blush_section,
  rightUpperChickSection: right_upper_chick_section,
  // ## left
  leftChickBone: left_chick_bone,
  leftUpperChick: left_upper_chick,
  leftLowerChick: left_lower_chick,
  leftChickShaderSection: left_chick_shader_section,
  leftChickBlushSection: left_chick_blush_section,
  leftUpperChickSection: left_upper_chick_section,

  underChickBoneLine: under_chick_bone_line,


  // # lips
  // ## upper lip
  topupperLip: top_upper_lip,
  bottomUpperLip: bottom_upper_lip,
  upperLipSection: upper_lip_section,
  // ## lower lip
  topLowerLip: top_lower_lip,
  bottomLowerLip: bottom_lower_lip,
  lowerLipSection: lower_lip_section,

  // jaw
  rightJawLine: right_jaw_line,
  leftJawLine: left_jaw_line,
  centerChinPoint: center_chin_point
}
