import * as React from 'react';
import { useState , useEffect} from 'react';

// API
import { pbGetById } from '../../lib/pbGet';

// MUI
import { 
  Button,
  ListItem,
  ListItemAvatar,
  Avatar,
  Skeleton
} from '@mui/material';

// MUI Icons
// import CircleIcon from '@mui/icons-material/Circle';

// Custom
import ProductDialog from '../dialog/ProductDialog';
import StyledTypography from '../text/StyledTypography';
import { useLang } from '../lang';
import theme from '../theme';


export const InstructionCard = ({instruction , imageSrc = theme.images.default_product_image}) => {

  const lang = useLang();
  const [product , setProduct] = useState(null);
  const [openProductDialog , setOpenProductDialog] = useState(false);
  const [loading , setLoading] = useState(false);

  useEffect(() => {

    if(!instruction || !instruction.productRecord){
      return;
    }

    setProduct(instruction.productRecord);
  }, [instruction])

  if(!instruction || !product){
    return (
      <ListItem alignItems="flex-start" dir='rtl'>
        <ListItemAvatar>
                <Skeleton animation="wave" variant="circular" width={40} height={40} >

                </Skeleton>
        </ListItemAvatar>
        <React.Fragment>
          <Skeleton animation="wave" height={20} width="80%" sx={{mt: 2}}/>
        </React.Fragment>
      </ListItem>
    );
  }
  return (
    <ListItem 
      alignItems="flex-start" 
      dir={lang.t('code.dir')}
    >  
      {
        product &&
        <ProductDialog open={openProductDialog} setOpen={setOpenProductDialog} product={product} setProduct={setProduct}/>
      }
      {
        !product
        ?
        <Skeleton animation="wave" variant="circular" width={40} height={40}/>
        :
        <ListItemAvatar>
              <Button onClick={() => {setOpenProductDialog(true)}}>
                <Avatar 
                  variant='rounded' 
                  style={{
                    height: 50 , 
                    width: 50,
                    opacity: !product.image_url ? 0.3 : 1
                    }} 
                  alt={product.title} 
                  src={!product.image_url ? imageSrc : product.image_url}
                >

                </Avatar>
              </Button>
        </ListItemAvatar>
      }
      <StyledTypography
        variant='body'
        dir={lang.t('code.dir')}
        lng={lang.t('code.lng')}
      >
        {instruction.title}
      </StyledTypography>
    </ListItem>
  );

}


export const InstructionCardFromId = ({ instructionId }) => {

    const [instruction , setInstruction] = useState(null);
    
    const fetchInstruction = async () => {
      const instructionRes = await pbGetById('instructions' , instructionId);
      const productRecord = await pbGetById('products' , instructionRes.product);
      instructionRes.productRecord = productRecord;
      setInstruction(instructionRes);
    }
    useEffect(() => {
      fetchInstruction();
    } , [instructionId])

    return(
      <InstructionCard instruction={instruction}/>
    );
    

};