import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import DialogContent from '@mui/material/DialogContent';

import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} timeout={{ enter: 700, exit: 400 }} />;
});

export default function HalfScreenDialog({
  open,
  setOpen,
  dir = 'rtl',
  title = '',
  saveButtonLabel = '',
  handleCloseCallback = () => {},
  callback = () => {},
  children,
}) {
  const handleClose = () => {
    handleCloseCallback();
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            height: '50%', // Set the desired height (in this case, half of the screen)
            position: 'absolute',
            bottom: 0,
            left: 0,
            borderRadius: '30px 30px 0 0',
            maxHeight: 'none', // Allow the content to take up the specified height
          },
        }}
      >


        <DialogContent
          dividers
          dir={dir}
          sx={{
            overflowY: 'auto',
            flexGrow: 1,
            zIndex: 2,
          }}
        >
          {children}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
