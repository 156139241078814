import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './components/auth';
import { LangProvider } from './components/lang';
import { OpenCvProvider } from 'opencv-react';
import { CartProvider } from './components/cart';
import { NotificationProvider } from './components/notify';

import './i18n';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <BrowserRouter>
  <AuthProvider>
  <LangProvider>
  <CartProvider>
  <NotificationProvider>

  <OpenCvProvider>

      <App />
  
  </OpenCvProvider>
  </NotificationProvider>
  </CartProvider>
  </LangProvider>
  </AuthProvider>
  </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();