

import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import theme from '../theme';

export function LargePlayButton({
    hidden = false,
    iconSizePx = '80px',
    sizePx = '100px',
    onClick = () => {},
}){

    if(hidden){return null}

    return(
        <div
            onClick={onClick}
            style={{
                width: sizePx, // Adjust the width of the circle as needed
                height: sizePx, // Adjust the height of the circle as needed
                backgroundColor: theme.palette.secondary.main, // Change the background color of the circle as needed
                borderRadius: '50%', // Make it a circle
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

                zIndex: 5
            }}
        >
            <PlayArrowIcon
                style={{
                    fontSize: iconSizePx, // Adjust the icon size as needed
                    color: theme.palette.primary.main, // Adjust the icon color as needed
                }}
            />
        </div>

    )
}