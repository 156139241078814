import { useState } from 'react'; // Import useState for managing state

// API
import { endpoint } from '../../lib/backend_endpoint';

// MUI
import { Button, Typography, TextField, MenuItem } from '@mui/material';

// MUI Icons
import SendIcon from '@mui/icons-material/Send';
import LoomeLogoBackdrop from '../backdrop/LoomeLogoBackdrop';
import FullScreenDialog from './FullScreenDialog';
import { useAuth } from '../auth';
import { useLang } from '../lang';
import StyledTypography from '../text/StyledTypography';
import toast from 'react-hot-toast';


function SuggestionsDialog({ open, setOpen, title, children }) {
    
  const auth = useAuth();
  const lang = useLang();
  const options = [
    lang.t('account.suggestionBoxDialog.options.improve') ,
    lang.t('account.suggestionBoxDialog.options.appBug') ,
    
  ];
  
    const [textValue, setTextValue] = useState(''); // State for text area
    const [dropdownValue, setDropdownValue] = useState(options[0]); // State for dropdown

    const [loading , setLoading] = useState(false);

    const handleSend = async () => {
        try {
          setLoading(true);
    
          // Perform HTTP POST request here with textValue and dropdownValue
          const response = await fetch(`${endpoint}/api/collections/comments/records`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              body: textValue,
              type: dropdownValue,
              user_id: auth.authUser.record.id,
            }),
          });
    
          // Check if the request was successful (status code 2xx)
          if (response.ok) {
            setTextValue("");
            setDropdownValue(options[0]);
            setOpen(false);
            toast.success(lang.t('toast.thanksForSuggestion'))
          } else {
            toast.error(lang.t('toast.suggestionSendFailed'))
            console.error('HTTP request failed with status:', response.status);
            // Handle error scenarios
          }
        } catch (error) {
          toast.error(lang.t('toast.suggestionSendFailed'))
          console.error('An error occurred during the HTTP request:', error);
          // Handle error scenarios
        } finally {
          setLoading(false);
        }
    };

  return (
    <FullScreenDialog open={open} setOpen={setOpen} title={lang.t('account.suggestionBox')}>
      
      <StyledTypography dir={lang.t('code.dir')} lng={lang.t('code.lng')} variant={'h6'}>
        {lang.t('account.suggestionBoxDialog.title')}
      </StyledTypography>

      {/* Dropdown */}
      <TextField
        dir={lang.t('code.dir')}
        select
        label={lang.t('account.suggestionBoxDialog.chooseOption')}
        value={dropdownValue}
        onChange={(e) => setDropdownValue(e.target.value)}
        variant="outlined"
        fullWidth
        margin="normal"
      >

        {
            options.map((op) => ((
                <MenuItem value={op}>{op}</MenuItem>
            )))
        }

      </TextField>

      {/* Text Area */}
      <TextField
        dir={lang.t('code.dir')}
        label={lang.t('account.suggestionBoxDialog.suggestion')}
        variant="outlined"
        value={textValue}
        onChange={(e) => setTextValue(e.target.value)}
        multiline
        rows={4} // You can adjust the number of rows as needed
        fullWidth
        margin="normal"
      />

      {children}
      {/* Button to trigger the HTTP request */}
      <Button onClick={handleSend}>
        <StyledTypography variant='body2' dir={lang.t('code.dir')} lng={lang.t('code.lng')}>
          {lang.t('account.suggestionBoxDialog.send')}
        </StyledTypography>
      </Button>

        <LoomeLogoBackdrop loading={loading}/>

    </FullScreenDialog>
  );
}

export default SuggestionsDialog;
