
export function toPrice(priceNumber) {
    if (typeof priceNumber !== 'number') {
      throw new Error('Invalid input. Please provide a number.');
    }
  
    const formattedPrice = priceNumber.toLocaleString('en-US', {
      style: 'currency',
      currency: 'ILS', // ISO code for Israeli Shekel
      minimumFractionDigits: 2,
    });
  
    return formattedPrice;
  }
  