import { Typography , Container , Box , Grid , TextField  , CssBaseline , Button , Link , Alert } from "@mui/material";
import { useEffect , useState } from "react";
import { useParams } from 'react-router-dom';

// API
import pb from "../../lib/pocketbase";

import { useAuth } from "../auth";

// Custom
import LoomeLogoBackdrop from '../backdrop/LoomeLogoBackdrop';
import { LoomeLogoAvatar } from "../avatar/LoomeLogoAvatar";
import CheckAnimation from "../animations/check/CheckAnimation";



export default function ChangePassordFrom (){

    const params = useParams()
    const token = params.token;

    const auth = useAuth();

    const [newPasswordValid , setNewPasswordValid] = useState(false);
    const [newPassword , setNewPassword] = useState("");

    const [newPasswordConfirm , setNewPasswordConfirm] = useState("");
    const [newPasswordConfirmValid , setNewPasswordConfirmValid] = useState(false);

    const [passwordMatch , setPasswordMatch] = useState(true);
    
    const [validationError , setValidationError] = useState(null);
    
  const validatePassword = (value) => {
    // Minimum password length requirement, you can customize this as needed
    const minLength = 8;
  
    // Check if the password meets the minimum length requirement
    const isValidPassword = value.length >= minLength;
  
    // Set the password valid state based on the result
    setNewPasswordValid(isValidPassword);

    if(!isValidPassword){
      setValidationError("סיסמה לא תקינה");
    }else{
      setValidationError(null);
    }

    setNewPassword(value);
  
  }    
    
  const validateConfirmPassword = (value) => {
    // Minimum password length requirement, you can customize this as needed
    const minLength = 8;
  
    // Check if the password meets the minimum length requirement
    const isValidPassword = value.length >= minLength;
    const passMatch = value === newPassword;
  
    // Set the password valid state based on the result
    setNewPasswordConfirmValid(isValidPassword);
    setPasswordMatch(passMatch);
    setNewPasswordConfirm(value);

    if(!passMatch){
        setValidationError("הסיסמאות לא תואמות");   
    }
    else if(!isValidPassword){
      setValidationError("סיסמה לא תקינה");
    }
    else{
      setValidationError(null);
    }
  
  }    
    
    const [loading , setLoading] = useState(false);
    const [passwordChanged , setPasswordCahnged] = useState(false);



    const handleSubmit = async (event) => {

        if(validationError){
            return;
        }
        setLoading(true);

        event.preventDefault();
        const data = new FormData(event.currentTarget);
        try{
            const response = await auth.resetPassword(token , data.get('newPassword') , data.get('newPasswordConfirm'))
            setPasswordCahnged(true);
        }catch(error){
            console.log(error);
        }

        setLoading(false);
      };


    return (
        <Container component="main" maxWidth="xs">
            <LoomeLogoBackdrop loading={loading}/>
          <CssBaseline />

            <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <LoomeLogoAvatar/>
  
            <Typography component="h1" variant="h5">
              החלף סיסמה
            </Typography>
  
            {
                !passwordChanged
                ?
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            type="password"
                            id="newPassword"
                            label="New Password"
                            name="newPassword"
                            autoComplete="password"
                            onChange={(event) => {validatePassword(event.target.value)}}
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            type="password"
                            id="newPasswordConfirm"
                            label="New Password Confirm"
                            name="newPasswordConfirm"
                            autoComplete="password"
                            onChange={(event) => {validateConfirmPassword(event.target.value)}}
                        />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={!passwordMatch || !newPasswordValid || !newPasswordConfirmValid}
                    >
                        שלח
                    </Button>
              
                </Box>
                :
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <CheckAnimation/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{mt: 7}}>הסיסמה הוחלפה בהצלחה</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>אתם יכולים לחזור לאפליקציה</Typography>
                        </Grid>

                    </Grid>
                </Box>
            }
            
            </Box>


            {
            validationError &&
            <Alert dir="rtl" severity="warning">{validationError}</Alert>
            }
          
        </Container>
    );

}