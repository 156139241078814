import { Card } from "@mui/material";
import AccountAvatar from "../avatar/AccountAvatar";
import { LoomeLogoAvatar } from "../avatar/LoomeLogoAvatar";
import DynamicLogo from "../avatar/DynamicLogo";
import StyledTypography from "../text/StyledTypography";
import { useLang } from "../lang";

import theme from '../theme';


function TopBar({title = '' , logoImageSrc = theme.images.app_logo_white_nobg}) {
    
    const lang = useLang();


    return (
      <Card
        style={{
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 20px',
          height: '3rem'
        }}
      >
        <div></div>
        <StyledTypography variant={'h6'} dir={lang.t('code.dir')} lng={lang.t('code.lng')}>{title}</StyledTypography>
        <DynamicLogo backgroundColor={theme.palette.primary.main} imageSrc={logoImageSrc}/>
      </Card>
    );
  }


export default TopBar;
