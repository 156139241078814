import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

// MUI
import { Card, CardContent, Typography, Box, Chip, Avatar , Skeleton , Divider} from "@mui/material";

// MUI Icons
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

import { useAuth } from "../auth";
import { useLang } from '../lang';
import VideoAvatar from "../avatar/VideoAvatar";
import CustomizedRating from "../rating/CustomizedRating";
import StyledTypography from '../text/StyledTypography';
import { pbGetById } from "../../lib/pbGet";

const TutorialTagsChips = ({tagNameList = []}) => {

  return (

    <Box
      sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: 'center', // Adjust as needed
          width: '100%',
          p: 2,
      }}
    >
      {
        tagNameList.length !== 0 &&
        (
          tagNameList.map((tagName) => ((
            <Chip
                label={tagName.charAt(0).toUpperCase() + tagName.slice(1)}
                color="primary"
                sx={{ml: 5}}
            />
          )))
        )

      }

    </Box>
  )


}


const TutorialCard = ({ tutorialId }) => {

  const navigate = useNavigate();
  const auth = useAuth();
  const lang = useLang();
  const user = auth.authUser.record;
  const [matchPercent, setMatchPercent] = useState(0);
  const [matchColor, setMatchColor] = useState("success");
  const [tutorial , setTutorial] = useState(null);
  const [rateValue, setRateValue] = useState(null);


  const fetchTutorial = async () => {
  
    const record = await pbGetById('tutorials' , tutorialId);
    setTutorial(record);

  }

  const handleClickTutorial = () => {
    navigate(`/dress-room/tutorial/${tutorialId}`);
  };

  const calculateMatchPercent = () => {
    if (!user) {
      return;
    }

    const minPrecent = 65;
    const playValue = 100 - minPrecent;
    const successMatchLevel = 95;
    const middleMatchLevel = 68;

    let param_number = 0;
    let param_match_number = 0;

    param_number += 1;
    if (tutorial.face_type === user.face_type) {
      param_match_number += 1;
    }
    param_number += 1;
    if (tutorial.eye_type === user.eye_type) {
      param_match_number += 1;
    }
    param_number += 1;
    if (tutorial.brow_type === user.brow_type) {
      param_match_number += 1;
    }

    let matchVal =
      Math.round((playValue * (param_match_number / param_number)) + minPrecent);

    setMatchPercent(matchVal);

    if (matchVal >= successMatchLevel) {
      setMatchColor("success");
    } else if (matchVal >= middleMatchLevel && matchVal < successMatchLevel) {
      setMatchColor("middle");
    } else {
      setMatchColor("warning");
    }
  };

  const calculateRateValue = async () => {
    let rate = tutorial.total_rate / tutorial.raters_number;
    setRateValue(rate);
  };

  useEffect(() => {
    fetchTutorial();

    // calculateMatchPercent();
    // calculateRateValue();
    
  }, []);

  
  if (!tutorial) {
    return (
      <Card variant="outlined" sx={{ borderRadius: 2, boxShadow: 4, mb: 2, mt: 2, ml: 1, mr: 1, width: "95%" , height: '25%'}}>
        <CardContent>
          <div style={{display: 'flex' , flexDirection: 'row' , marginBottom: 4 , padding: 2}}>
            <Skeleton variant="rounded" height={50} width={50} sx={{mr: 2, ml: 2}}/>
            <Skeleton variant="text" height={20} width="80%"/>
          </div>

          <Skeleton variant="text" height={20} width="100%" />
          <Skeleton variant="text" height={20} width="80%" />
          <Skeleton variant="text" height={20} width="90%" />

        </CardContent>
      </Card>
    )
    
  }else if(!tutorial.active && !auth.authUser.record.is_super){
    return null;
  }

  return (
    <div onClick={handleClickTutorial}>
      <Card
        variant="outlined"
        sx={{
          borderRadius: 2,
          boxShadow: 4,
          mb: 2,
          mt: 2,
          mx: 'auto',
          width: '95%',
          cursor: 'pointer',
          "&:hover": {
            boxShadow: 8,
          },
        }}
      >
        <CardContent dir={lang.t('code.dir')}>

          <div style={{marginLeft: 20}}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between", // Adjust as needed
                    width: '100%',
                    p: 2,
                }}
            >
                <VideoAvatar videoId={null} imageSrc={tutorial.image_url}/>

                <StyledTypography variant="h6" sx={{ mt: 2, fontWeight: 600, textAlign: "center" }} dir={lang.t('code.dir')} lng={lang.t('code.lng')}>
                  {tutorial.title}
                </StyledTypography>
    
          </Box>
          </div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              p: 2,
            }}
          >

            {/* <TutorialTagsChips tagNameList={tutorial.tag}/> */}
            
            {/* <StyledTypography
              variant="body2"
              color={matchColor}
              sx={{ fontWeight: 600 }}
              dir={lang.t('code.dir')}
              lng={lang.t('code.lng')}
            >
              {matchPercent}%
            </StyledTypography> */}

          </Box>


          <StyledTypography variant="body2" color="text.secondary" sx={{ textAlign: "center" }} dir={lang.t('code.dir')} lng={lang.t('code.lng')}>
            {tutorial.description}
          </StyledTypography>

        </CardContent>
        {/* Uncomment if needed */}
        {/* <Button onClick={handleClickTutorial}>התחל הדרכה</Button> */}
        {/* <CustomizedRating value={rateValue} /> */}
      </Card>
    </div>
  );
};

export default TutorialCard;
