import * as React from 'react';
import { useState , useRef } from 'react';
import { useNavigate } from 'react-router-dom';

// MUI
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Alert } from '@mui/material';

// MUI Icons
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EastRoundedIcon from '@mui/icons-material/EastRounded';

// import { createTheme, ThemeProvider } from '@mui/material/styles';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import { useAuth } from './auth';
import { LoomeLogoAvatar } from './avatar/LoomeLogoAvatar';
import LoomeLogoBackdrop from './backdrop/LoomeLogoBackdrop';
import ScrollDialog from './dialog/ScrollDialog';

import TermsAndConditionsContent from './dialog/TermsAndConditionsContent';
import StyledTypography from './text/StyledTypography';
import { useLang } from './lang';
import WavesAnimation from './animations/waves/WavesAnimation';
import { CostumPassTextField, CostumTextField } from './text_field/CostumTextField';

import theme from './theme';
import CustomDatePicker from './datepicker/CustomDatePicker';
import UploadProfileImage from './upload/UploadProfileImage';

export default function SignUp() {

  const auth = useAuth();
  const lang = useLang();
  const navigate = useNavigate();
  const [loading , setLoading] = useState(false);
  const [showLicence , setShowLicence] = useState(false);
  const [licenceAgreed , setLicenceAgreed] = useState(false);
  const [showPassword , setShowPassword] = useState(false);


  const [firstName , setFirstName] = useState('');
  const [lastName , setLastName] = useState('');
  const [email , setEmail] = useState('');
  const [emailValid , setEmailValid] = useState(false);
  const [password , setPassword] = useState('');
  const [passwordValid , setPasswordValid] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  
  const [imageSrc, setImageSrc] = useState(null);
  const [fileUpload , setFileUpload] = useState(null);

  const [validationError , setValidationError] = useState(null);

  const validateEmail = (value) => {
    // Regular expression for a simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    // Check if the email matches the regex
    const isValidEmail = emailRegex.test(value);
  
    // Set the email valid state based on the result
    setEmailValid(isValidEmail);
    setEmail(value);

    if(!isValidEmail){
      setValidationError(lang.t('signup.emailNotValidMsg'));
    }else{
      setValidationError(null);
    }
  }

  const validatePassword = (value) => {
    // Minimum password length requirement, you can customize this as needed
    const minLength = 8;
  
    // Check if the password meets the minimum length requirement
    const isValidPassword = value.length >= minLength;
  
    // Set the password valid state based on the result
    setPasswordValid(isValidPassword);
    setPassword(value);

    if(!isValidPassword){
      setValidationError(lang.t('signup.passwordNotValidMsg'));
    }else{
      setValidationError(null);
    }
    
  }
  
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if(!licenceAgreed || !emailValid || !passwordValid){
      return;
    }
    
    setLoading(true);
    try{
      const data = new FormData(event.currentTarget);

      
      const userData = {
        "username": ``,
        "email": email.toLocaleLowerCase(),
        "emailVisibility": true,
        "password": password,
        "passwordConfirm": password,
        "name": `${firstName} ${lastName}`,
        "coupon": [],
        "birth_date": selectedDate.toISOString().replace("T", " "),
        "face_type": 1,
        "brow_type": 1,
        "eye_type": 1,
        "locale": lang.lng,
        "avatar": fileUpload
      };
      
      const response = await auth.signup(userData);
      
      setLoading(false);
      
    }catch(error){
      console.log(error);
      // setValidationError(lang.t('signup.createAccountError'));
      setValidationError(error.response.data.message);
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      {/* <WavesAnimation/> */}

      <CssBaseline />
      <LoomeLogoBackdrop loading={loading}/>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/* <LoomeLogoAvatar logoSize={50}/> */}

        <StyledTypography variant="h5" dir={lang.t('code.dir')} lng={lang.t('code.lng')}>
          {lang.t("signup.title")}
        </StyledTypography>


        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 , width: "100%" }}>
          <Box sx={{ width: '70%', margin: '0 auto', textAlign: 'center'}}>

              <UploadProfileImage 
                imageSrc={imageSrc} 
                setImageSrc={setImageSrc} 
                fileUpload={fileUpload}
                setFileUpload={setFileUpload}
              />

              <CostumTextField
                autoComplete="given-name"
                dir={lang.t('code.dir')}
                name="firstName"
                label={lang.t("general.firstName")}
                id="firstName"
                autoFocus={true}
                value={firstName}
                setValue={setFirstName}
                onChange={(event) => {setFirstName(event.target.value)}}
              />

              <CostumTextField
                autoComplete="family-name"
                dir={lang.t('code.dir')}
                label={lang.t("general.lastName")}
                name="lastName"
                value={lastName}
                setValue={setLastName}
                onChange={(event) => {setLastName(event.target.value)}}
              />

              <CostumTextField
                // dir={lang.t('code.dir')}
                value={email}
                setValue={setEmail}
                id="email"
                label={lang.t("general.email")}
                name="email"
                autoComplete="email"
                onChange={(event) => {validateEmail(event.target.value)}}
              />

              <CostumPassTextField
                name="password"
                value={password}
                setValue={setPassword}
                label={lang.t("general.password")}
                id="password"
                autoComplete="new-password"
                onChange={(event) => {validatePassword(event.target.value)}}
              />

              <CustomDatePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate}/>

          </Box>
        </Box>

        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Box
            dir={lang.t('code.dir')}
          >
            <Checkbox 
            checked={licenceAgreed} 
            onChange={() => {setLicenceAgreed(!licenceAgreed)}}
            />
              {lang.t('signup.termsAndConditionsCheckBox')} 
            {/* <StyledTypography dir={lang.t('code.dir')} lng={lang.t('code.lng')}>
            </StyledTypography> */}
            <Button onClick={() => {setShowLicence(true)}}>
                {lang.t('signup.termsAndConditions')}
              {/* <StyledTypography variant='body1' dir={lang.t('code.dir')} lng={lang.t('code.lng')}>
              </StyledTypography> */}
            </Button>
          </Box>
          
          <Box sx={{ width: '70%', margin: '0 auto', textAlign: 'center'}}>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 4,
                  mb: 2,
                  border: '2px solid white', // Set the border to 2px solid white
                  borderRadius: theme.sizes.borderRadius, // Optional: Adjust border-radius for a rounded appearance
                  height: theme.sizes.height.medium,
                }}
                disabled={!licenceAgreed || !emailValid || !passwordValid}
              >
                <EastRoundedIcon style={{ fontSize: 50 }} />
                {/* {lang.t("signin.title")} */}
            </Button>
          </Box>

        </Box>
      </Box>
      <TermsAndConditionsContent 
      open={showLicence} 
      setOpen={setShowLicence} 
      title={lang.t("signup.termsAndConditions")}
      callback={() => {setLicenceAgreed(true); 
      setShowLicence(false);}
      }
      agreeButtonName={lang.t("signup.agree")}
      cancelButtonName={lang.t("signup.notAgree")}
      />

      {
        validationError &&
        <Alert dir="rtl" severity="warning">{validationError}</Alert>
      }
    </Container>
    
  );
}