import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  store: {
    base_url: 'https://www.loomecosmetics.com',
    cart_endpoint: '/cart-page?appSectionParams=%7B%22origin%22%3A%22cart-popup%22%7D'
  },
  images: {
    default_product_image: '/images/defaultProductLogo.png',
    app_logo_bg: '/images/logo 512x512 bg.jpg',
    app_logo_white_nobg: '/images/logoTransperent.png',
    app_logo_black_nobg: '/images/logoTransperent_black.png',
  },
  sizes: {
    logo: {
      xxSmall: 19,
      xSmall: '2rem',
      small: '2.4rem',
      medium: '5rem',
      large: '9rem',
    },
    borderRadius: '20px',
    height: {
      small: '2rem',
      medium: '3.4rem',
      large: '4rem',
    },
    gap: {
      small: '0.003rem',
      medium: '3.4rem',
      large: '4rem',
    }
  },
  palette: {
    primary: {
      main: "#8E8579", // Bitter Chocolate
    },
    secondary: {
      main: "#F1F2F2", // Azure
      dark: "#f5ece6"
    },
    tertiary: {
      main: "#BAA89E", // Crushed Stone
    },
    success: {
      main: "#96C291", // 
    },
    middle: {
      main: "#FFC436", // 
    },
    warning: {
      main: "#E25E3E", // 
    },
    black: {
      main: "#000000", // Natural Black
    },
    white: {
      main: "#F3F4F8", // 
    },
  }
});

export default theme;