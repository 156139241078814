import React from "react";
import ReactCountryFlag from "react-country-flag";
import { Button } from "@mui/material";
import { useLang } from "../lang";

function ChangeLangButton({ languageInfo , flagSize = 1.3}) {
  const lang = useLang();

  if (!languageInfo) {
    return null;
  }

  const isSelectedLanguage = lang.t('code.lng') === languageInfo.lang;

  return (
    <Button onClick={() => lang.changeLanguage(languageInfo.lang)} style={{marginTop: 7}}>
      <div
        style={{
          border: isSelectedLanguage ? "2px solid white" : "none",
          borderRadius: '10%',
          width: `${flagSize}em`,
          height: `${flagSize}em`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ReactCountryFlag
          countryCode={languageInfo.countryCode}
          svg
          style={{
            width: `${flagSize}em`,
            height: `${flagSize}em`,
          }}
          title={languageInfo.countryCode}
        />
      </div>
    </Button>
  );
}

export default ChangeLangButton;
