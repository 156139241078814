import { TextField , Button } from "@mui/material";

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import theme from "../theme";
import { useState } from "react";


export function CostumPassTextField({
  name,
  value,
  setValue,
  dir='ltr',
  label,
  id,
  onChange = () => {},
}){

  const [showPassword , setShowPassword] = useState(false);

  return(
    <TextField
      margin="normal"
      dir={dir}
      required
      fullWidth
      name={name}
      value={value}
      label={label}
      type={showPassword ? 'text' : 'password'}
      id={id}
      onChange={onChange}
      autoComplete="current-password"
      InputProps={{
        style: {
          borderRadius: theme.sizes.borderRadius, // Adjust the border-radius for rounded edges
          height: theme.sizes.height.medium,
        },
        startAdornment: (
          <>
            {
              value != ''
              ?
              <Button disableRipple sx={{ borderRadius: '100%', '&:hover': { backgroundColor: 'rgba(0,0,0,0)' } }} onClick={() => {setValue('')}}>
                <HighlightOffIcon 
                sx={{ width: 20, height: 20 }} 

                />
              </Button>
              : null
            }
          </>
        ),
        endAdornment: (
          <Button disableRipple onClick={() => {setShowPassword(!showPassword)}}>
            {
              showPassword
              ? <LockOpenIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
              : <LockIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
            }
            
          </Button>
        ),
      }}
/>
  );
}

export function CostumTextField({ 
    name,
    value,
    setValue,
    dir='ltr',
    autoFocus,
    type,
    id,
    label,
    onChange = () => {},
    autoComplete,
}) {
    return (

    <TextField
        margin="normal"
        dir={dir}
        required
        fullWidth
        name={name}
        value={value}
        autoFocus={autoFocus}
        label={label}
        type={type}
        id={id}
        onChange={onChange}
        autoComplete={autoComplete}
        InputProps={{
          style: {
            borderRadius: theme.sizes.borderRadius, // Adjust the border-radius for rounded edges
            height: theme.sizes.height.medium,
          },
          startAdornment: (
            <>
                    {
                      value != ''
                      ?
                      <Button disableRipple sx={{ borderRadius: '100%', '&:hover': { backgroundColor: 'transparent' } }} onClick={() => {setValue('')}}>
                        <HighlightOffIcon
                         sx={{ width: 20, height: 20 }} 

                         />
                      </Button>
                      : null
                    }
              </>
          ),
          // endAdornment: (
          //   endAdornment
          // ),
        }}
  /> );
}
