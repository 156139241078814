import { Typography , Container , Box , Grid , TextField  , CssBaseline , Button , Link , Alert} from "@mui/material";
import FullScreenDialog from "./FullScreenDialog";
import { useEffect } from "react";
import toast from "react-hot-toast";

// API
import pb from "../../lib/pocketbase";
import { useState } from "react";

// MUI Icons
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SendIcon from '@mui/icons-material/Send';



import LoomeLogoBackdrop from '../backdrop/LoomeLogoBackdrop';
import { useAuth } from "../auth";
import { useLang } from "../lang";
import StyledTypography from "../text/StyledTypography";
import { CostumTextField } from "../text_field/CostumTextField";
import {CostumSubmitButton} from '../buttons/CostumSubmitButton';



export default function ChangePasswordDialog ({
    open , 
    setOpen , 
    title = "",
    saveButtonLabel = "", 
}) {

    const auth = useAuth();
    const lang = useLang();
    const [changeReady , setChangeReady] = useState(false);
    const [loading , setLoading] = useState(false);
    const [msgSent , setMsgSent] = useState(false);

    const [email , setEmail] = useState(null);

  const [emailValid , setEmailValid] = useState(true);

  const [validationError , setValidationError] = useState(null);

  const validateEmail = (value) => {
    // Regular expression for a simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    // Check if the email matches the regex
    const isValidEmail = emailRegex.test(value);
  
    // Set the email valid state based on the result
    setEmailValid(isValidEmail);

    setEmail(value);

    if(!isValidEmail){
      setValidationError(lang.t('forgot.emailNotValidMsg'));
    }else{
        setValidationError(null);
    }
  }

    const handleRequestPasswordReset = async () => {
        
        setLoading(true);

        if (!emailValid){
            setLoading(false);
            
            toast.error(validationError , {
                duration: 5 * 1000,
            })

            return;
        }
        const response = await auth.requestResetPassword(email);
        
        if(response){
            setChangeReady(true);
            toast.success(lang.t('forgot.sendRestoreCodeMsg') , {
                duration: 5 * 1000,
                
            })
        }
        
        setLoading(false);
    }
    
    useEffect(() => {
        if (!auth.authUser){
            return;
        }
        setEmail(auth.authUser.record.email);
    } , [])

    return (
        <FullScreenDialog
            open={open}
            setOpen={setOpen}
            // title={title}
            saveButtonLabel={saveButtonLabel}
            handleCloseCallback={() => {setMsgSent(false)}}
            callback={() => {setMsgSent(false)}}
        >

            <Container component="main" maxWidth="xs">
                    <LoomeLogoBackdrop loading={loading}/>
                    <CssBaseline />
                    <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    >

                    <StyledTypography variant="h5">
                        {title}
                    </StyledTypography>
                    <Typography component="h1" variant="h5">
                    </Typography>
                    

                    <Box component="form" noValidate sx={{ mt: 1 , width: '70%' }}>
                        <CostumTextField
                            id="email"
                            label={lang.t('general.email')}
                            name="email"
                            autoComplete="email"
                            onChange={(event) => {
                                validateEmail(event.target.value);
                            }}
                            value={email}
                            setValue={setEmail}
                            autoFocus={true}
                        />
                    </Box>


                    {
                        !msgSent
                        ? 
                        <Box sx={{ width: '70%', margin: '0 auto', textAlign: 'center' }}>
                            <CostumSubmitButton onClick={handleRequestPasswordReset}>
                                <SendIcon/>
                            </CostumSubmitButton>
                            <Typography>{lang.t('forgot.spamMsg')}</Typography>
                        </Box>
                        :
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography dir="rtl">{lang.t('forgot.sendRestoreCodeMsg')}</Typography>
                            <Button variant="outlined" sx={{ mt: 15 }} onClick={handleRequestPasswordReset}>
                                {lang.t('forgot.sendRestoreCodeAgain')}
                            </Button>
                        </Box>
                    }
                    </Box>

                    {
                    validationError &&
                    <Alert dir={lang.t('code.dir')} severity="warning">{validationError}</Alert>
                    }


                </Container>

        </FullScreenDialog>
    );
}

