import * as React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Button, Container, Divider , Card , Box } from '@mui/material';
import { useEffect , useState , useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../auth';
import { useLang } from '../lang';

import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';
import AvatarList from '../avatar/AvatarList';

import pb from '../../lib/pocketbase';
import AccountAvatar from '../avatar/AccountAvatar';

import LoomeLogoBackdrop from '../backdrop/LoomeLogoBackdrop';
import StyledTypography from '../text/StyledTypography';
import CustomDatePicker from '../datepicker/CustomDatePicker';
import FullScreenDialog from '../dialog/FullScreenDialog';
import HalfScreenDialog from '../dialog/HalfScreenDialog';
import SmartCamera from '../camera/SmartCamera';
import MouseTrail from '../animations/mouse/MouseTrail';
import toast from 'react-hot-toast';
import ScanAnimation from '../animations/scanner/ScanAnimation';
import {CostumTextField} from '../text_field/CostumTextField';
import UploadProfileImage from '../upload/UploadProfileImage';
import {CostumSubmitButton} from '../buttons/CostumSubmitButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DeblurOutlinedIcon from '@mui/icons-material/DeblurOutlined';


import theme from '../theme';


const face_types_data = [
    {
      id: 1, 
      image_url : '/images/assets/face_type_1.jpg',
    },
    {
      id: 2, 
      image_url : '/images/assets/face_type_2.jpg',
    },
    {
      id: 3, 
      image_url : '/images/assets/face_type_3.jpg',
    },
    {
      id: 4, 
      image_url : '/images/assets/face_type_4.jpg',
    },
    {
      id: 5, 
      image_url : '/images/assets/face_type_5.jpg',
    },
    {
      id: 6, 
      image_url : '/images/assets/face_type_6.jpg',
    },
]
const brow_types_data = [
    {
      id: 1, 
      image_url : '/images/assets/brow_type_1.jpg',
    },
    {
      id: 2, 
      image_url : '/images/assets/brow_type_2.jpg',
    },
    {
      id: 3, 
      image_url : '/images/assets/brow_type_3.jpg',
    },
    {
      id: 4, 
      image_url : '/images/assets/brow_type_4.jpg',
    },
    {
      id: 5, 
      image_url : '/images/assets/brow_type_5.jpg',
    },
]
const eye_types_data = [
    {
      id: 1, 
      image_url : '/images/assets/eye_type_1.jpg',
    },
    {
      id: 2, 
      image_url : '/images/assets/eye_type_2.jpg',
    },
    {
      id: 3, 
      image_url : '/images/assets/eye_type_3.jpg',
    },
    {
      id: 4, 
      image_url : '/images/assets/eye_type_4.jpg',
    },
    {
      id: 5, 
      image_url : '/images/assets/eye_type_5.jpg',
    },
    {
      id: 6, 
      image_url : '/images/assets/eye_type_6.jpg',
    },
]


function FaceScanDialog({
  scan,
  setScan,
  onStartScan = () => {}
}) {
  const auth = useAuth();
  const lang = useLang();
  const [openInfoMsg, setOpenInfoMessage] = useState(true);

  return (
    <FullScreenDialog
      open={auth.initScan}
      setOpen={auth.setInitScan}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
        }}
      >
        <div>
          <div
            style={{
              position: 'absolute',
              width: '200%',
              height: '100%',
              zIndex: 2,
            }}
          >
            {scan && <ScanAnimation />}
          </div>
          <div>
            <SmartCamera onResults={(results) => {}} />
          </div>
        </div>
        
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            padding: '16px', // Adjust the padding as needed
            zIndex: 2,
          }}
        >
          {/* Information Dialog */}
          <Button onClick={() => setOpenInfoMessage(true)}>
            <InfoOutlinedIcon color='black'/>
          </Button>

          <HalfScreenDialog open={openInfoMsg} setOpen={setOpenInfoMessage}>
            <StyledTypography variant={'h6'} dir={lang.t('code.dir')} lng={lang.t('code.lng')}>
              {lang.t('toast.scanFaceMsg')}
            </StyledTypography>
          </HalfScreenDialog>

          {/* Custom Submit Button */}
          <CostumSubmitButton
            onClick={onStartScan}
            type={'button'}
            disabled={scan}
            loading={scan}
            sx={{
              width: '90%',
            }}
          >
            {lang.t('general.startScan')}
          </CostumSubmitButton>
        </Box>

      </Box>
    </FullScreenDialog>
  );
}


export default function ProfileInformationScreen() {

  const navigate = useNavigate();
  const auth = useAuth();
  const lang = useLang();
  const user = auth.authUser.record;
  const [curName , setCurName] = useState(user.name);
  const [curFaceType , setCurFaceType] = useState(user.face_type);
  const [curBrowType , setCurBrowType] = useState(user.brow_type);
  const [curEyeType , setCurEyeType] = useState(user.eye_type);
  const [imageSrc, setImageSrc] = useState(null);
  const [fileUpload , setFileUpload] = useState(null);
  const [loading , setLoading] = useState(false);
  const [disableUpdate , setDisableUpdate] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date(user.birth_date));

  const [faceDetectedMsgSent , setFaceDetectedMsgSent] = useState(false);
  const [scan , setScan] = useState(false);

  
  const [scanCameraOpen , setScanCameraOpen] = useState(true);
  // Create a reference for the file input
  const fileInputRef = useRef(null);
  const dateRef = useRef(null);

  const imageCacheKey = 'cacheProfileImage';

  const updateUserInformation = async () => {

    setLoading(true);

    user.face_type = curFaceType;
    user.brow_type = curBrowType;
    user.eye_type = curEyeType;
    user.birth_date = selectedDate.toISOString().replace("T", " ");
    user.avatar = imageSrc;
    user.name = curName == "" ? user.name : curName;

    const data = {
      "face_type": curFaceType,
      "brow_type": curBrowType,
      "eye_type": curEyeType, 
      "name" : curName == "" ? user.name : curName,
      "birth_date": selectedDate.toISOString().replace("T", " "),
    };

    if(fileUpload != null){
      data.avatar = fileUpload
    }
    
    const record = await pb.collection('users').update(auth.authUser.record.id, data);

    await auth.authRefresh();
    setLoading(false);
    // navigate("/home");

  }

  const getRandomInt = (min, max) => {
    // min (inclusive) and max (exclusive)
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }

  const scanDetermineFaceAttributes = () => {

    setCurBrowType(getRandomInt(1,5));
    setCurEyeType(getRandomInt(1,6));
    setCurFaceType(getRandomInt(1,6));
    
  }

  // Function to start the scan
  const startScan = () => {

    const timeScan = 10; // seconds
    setScan(true);
    // Set a timeout to stop the scan after 3 seconds
    setTimeout(() => {
      setScan(false);
      auth.setInitScan(false);
      setScanCameraOpen(false);
    }, timeScan * 1000);


    scanDetermineFaceAttributes();
  };

  useEffect(() => {
    // Enable the update button if any setting has changed
    setDisableUpdate(
      selectedDate.toISOString().replace("T", " ") === user.birth_date &&
      curName === user.name &&
      curFaceType === user.face_type &&
      curBrowType === user.brow_type &&
      curEyeType === user.eye_type &&
      imageSrc === null &&
      fileUpload === null
    );

  }, [curFaceType, curBrowType, curEyeType, selectedDate ,curName , imageSrc, user, fileUpload]);


  if (!auth.authUser) {
    return null;
  }

  return (
    <Paper elevation={0} style={{ overflow: 'auto', height: window.innerHeight }}>

        <LoomeLogoBackdrop loading={loading}/>
        
        <FaceScanDialog scan={scan} setScan={setScan} onStartScan={startScan}/>
        
        <Container dir={lang.t('code.dir')}>
      
          <Stack direction="row" spacing={2} sx={{display: 'flex'}}>
              {/* Account Image Avatar */}
              <UploadProfileImage 
                imageSrc={imageSrc} 
                setImageSrc={setImageSrc} 
                fileUpload={fileUpload}
                setFileUpload={setFileUpload}
              />
              {/* Account Image Avatar End*/}

              <div style={{display: 'flex' , alignContent: 'center' , textAlign: 'center' ,flexDirection: 'column'}}>
                <StyledTypography variant="h4" dir={lang.t('code.dir')} lng={lang.t('code.lng')}>
                    {curName}
                </StyledTypography>
                <StyledTypography variant="subtitle1" dir={lang.t('code.dir')} lng={lang.t('code.lng')}>
                  {user.email}
                </StyledTypography>
              </div>
            
          </Stack>
          
          <CostumTextField
            autoComplete="name"
            dir={lang.t('code.dir')}
            name="name"
            id="name"
            value={curName}
            setValue={setCurName}
            onChange={(event) => {setCurName(event.target.value)}}
            label={lang.t("general.name")}
          />

          
          {/* <StyledTypography variant="subtitle1" dir={lang.t('code.dir')} lng={lang.t('code.lng')}>
              {lang.t('account.persongInfoScreen.birthday')}
          </StyledTypography> */}
          {/* <CustomDatePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate}/> */}


          <Divider sx={{mt:5 , mb: 5}}/>


            <Button fullWidth onClick={() => {
              setScanCameraOpen(true);
              auth.setInitScan(true);
            }} variant='outlined'>
                <StyledTypography variant="h4" dir={lang.t('code.dir')} lng={lang.t('code.lng')}>
                    <DeblurOutlinedIcon style={{fontSize: '1.5rem'}}/> Face Scan
                </StyledTypography>
            </Button>
          
            
            <div style={{overflow: 'auto' , height: window.innerHeight * 0.45 , marginTop: 3}}>
              <StyledTypography variant="subtitle1" dir={lang.t('code.dir')} lng={lang.t('code.lng')}>
                  {lang.t('account.persongInfoScreen.faceType')}
              </StyledTypography>
              <AvatarList cur={curFaceType} data={face_types_data} onAvatarClick={setCurFaceType}/>
              <StyledTypography variant="subtitle1" dir={lang.t('code.dir')} lng={lang.t('code.lng')}>
                  {lang.t('account.persongInfoScreen.browType')}
              </StyledTypography>
              <AvatarList cur={curBrowType} data={brow_types_data} onAvatarClick={setCurBrowType}/>
              <StyledTypography variant="subtitle1" dir={lang.t('code.dir')} lng={lang.t('code.lng')}>
                  {lang.t('account.persongInfoScreen.eyeType')}
              </StyledTypography>
              <AvatarList cur={curEyeType} data={eye_types_data} onAvatarClick={setCurEyeType}/>
            </div>

            <div style={{height: '30%'}}></div>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                position: 'absolute',
                bottom: "10%",
                left: 0,
                right: 0,
                padding: '16px', // Adjust the padding as needed
                zIndex: 2,
              }}
            >
              <CostumSubmitButton
                dir={lang.t('code.dir')}
                disabled={disableUpdate}
                onClick={updateUserInformation}
                loading={loading}
                sx={{}}
              >
                <DataSaverOnIcon style={{paddingRight: 5 , paddingLeft: 5}}/>
                <StyledTypography dir={lang.t('code.dir')} lng={lang.t('code.lng')}>
                  {lang.t('account.persongInfoScreen.updateAccountButtonLabel')}
                </StyledTypography>

              </CostumSubmitButton>

            </Box>
            
        </Container>
    </Paper>
  );
}
