import React from "react";
import { useState } from "react";
import { Stack , Item , Box} from "@mui/material";

import FilterButton from "./FilterButton";
import RangeSlider from "./RangeSlider";
import FilterTags from "./FilterTags";

function FilterBar() {

    const [showFilters , setShowFilters] = useState(false);

    return (
        <Box sx={{mt: 5}}>
            <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
                <FilterButton onClickFilter={() => {setShowFilters(!showFilters)}}/>
                <RangeSlider/>
                {
                    showFilters &&
                    <FilterTags/>
                }
            </Stack>
        </Box>
    );
}

export default FilterBar;