import { Button } from "@mui/material";

import theme from "../theme";
import { LoadingButton } from "@mui/lab";

export function CostumSubmitButton({
    disabled,
    loading = false,
    type='submit',
    sx={},
    onClick = () => {},
    startIcon=null,
    endIcon=null,
    children
}) {
    return ( 
        <LoadingButton
            type={type}
            fullWidth
            variant="contained"
            onClick={onClick}
            sx={{
            mt: 4,
            mb: 2,
            border: '2px solid white', // Set the border to 2px solid white
            borderRadius: theme.sizes.borderRadius, // Optional: Adjust border-radius for a rounded appearance
            height: theme.sizes.height.medium,
            ...sx
            }}
            disabled={disabled}
            loading={loading}
            startIcon={startIcon}
            endIcon={endIcon}
        >
            {children}
        </LoadingButton>
    );
}
