import { Avatar, Card } from "@mui/material";
import { useEffect, useState } from "react";

import theme from '../theme';

export const LoomeLogoAvatar = ({ path = theme.images.app_logo_bg, logoSize = 70 , elevation = 5}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = path;

    img.onload = () => {
      setImageLoaded(true);
    };

    return () => {
      img.onload = null;
    };
  }, [path]);

  return (
    <Card
      elevation={elevation}
      style={{
        borderRadius: '100%'
      }}
    >

      <Avatar
        sx={{
          bgcolor: 'rgba(0,0,0,0)',
          width: logoSize,
          height: logoSize,
          display: imageLoaded ? 'block' : 'none', // Hide the Avatar until the image is loaded
        }}
      >
        <img
          src={path}
          alt="Logo"
          style={{ width: '100%', height: '100%' }}
          onLoad={() => setImageLoaded(true)} // In case the onLoad event doesn't fire for some reason
        />
      </Avatar>

    </Card>
  );
};

