import { Box , Typography , Card } from "@mui/material"
import AccountAvatar from "../avatar/AccountAvatar"
import StyledTypography from "../text/StyledTypography"
import { useLang } from "../lang"
import { useAuth } from "../auth"
import theme from "../theme"



function UserCard({imageSize , width = '100%' , height = '30%'}) {

    const auth = useAuth();
    const lang = useLang();

    return(
    <Card
      sx={{
        m: "auto",
        mb: 2,
        p: 0.4,
        display: "flex",
        justifyContent: "space-between", // Space items equally
        alignItems: "center", // Center vertically
        flexDirection: "column", // Align items in a column
        borderRadius: 0,
        backgroundColor: 'rgba(0,0,0,0)',
        width: width,
        // height: height,
        transition: "box-shadow 0.3s", // Add a smooth transition effect
      }}
      elevation={0}
    >
      {/* TODO: Add an option to change profile image from this point */}
      <AccountAvatar imageSize={'95px'} changeOption={false}/>
      <StyledTypography 
        sx={{
          mt: 5,
          mb: 10,
          fontWeight: 'bold'
          }} 
        variant="h6"
        lng={lang.t('code.lng')}
        color={theme.palette.secondary.main}
      >
        {auth.authUser.record.name}
      </StyledTypography>
      {/* <Typography variant="overline" display="block">{auth.authUser.record.email}</Typography> */}
    </Card>
    )

}


export default UserCard;