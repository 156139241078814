import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, Box, Chip, Avatar , Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth";
import CustomizedRating from "../rating/CustomizedRating";
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';


import theme from '../theme';

// MUI Icons
import LiquorIcon from '@mui/icons-material/Liquor';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import BusinessIcon from '@mui/icons-material/Business';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import LensBlurIcon from '@mui/icons-material/LensBlur';
import StarsIcon from '@mui/icons-material/Stars';
import DiamondIcon from '@mui/icons-material/Diamond';
import MovieCreationIcon from '@mui/icons-material/MovieCreation';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import StyledTypography from '../text/StyledTypography';
import { useLang } from "../lang";


const MatchIcon = ({iconName}) => {

  if(!iconName || iconName === ""){

    return(
      <>
        <OndemandVideoIcon/>
      </>
    )
  }


  return(
    <>
      {iconName === "LiquorIcon" && <LiquorIcon/>}
      {iconName === "AccessTimeFilledIcon" && <AccessTimeFilledIcon/>}
      {iconName === "BusinessIcon" &&<BusinessIcon/>}
      {iconName === "BeachAccessIcon" &&<BeachAccessIcon/>}
      {iconName === "LensBlurIcon" &&<LensBlurIcon/>}
      {iconName === "StarsIcon" &&<StarsIcon/>}
      {iconName === "DiamondIcon" &&<DiamondIcon/>}
      {iconName === "MovieCreationIcon" &&<MovieCreationIcon/>}
      {iconName === "FavoriteBorderIcon" &&<FavoriteBorderIcon/>}
    </>
  )


}


const TutorialTypeCard = ({ tutorialType }) => {

  const navigate = useNavigate();
  const auth = useAuth();
  const lang = useLang();
  
  const handleClickTutorialType = () => {

    navigate(`tutorials/${tutorialType.id}`);

  }

  if(!tutorialType){
    return (
      <Card 
      elevation={0}
      variant="outlined"
      sx={{
        borderRadius: 2,
        boxShadow: 4,
        mb: 2,
        mt: 2,
        mx: 'auto',
        width: '95%',
        cursor: 'pointer',
        "&:hover": {
          boxShadow: 8,
        },
      }}
      >
        <CardContent>
          <div style={{display: 'flex' , flexDirection: 'row' , marginBottom: 40 , marginTop: 2 , padding: 2 , justifyContent:"space-between"}}>
            <Skeleton variant="text" height={40} width="50%"/>
            <Skeleton variant="circular" height={50} width={50} sx={{mr: 2, ml: 2}}/>
          </div>

          <Skeleton variant="text" height={20} width="100%"/>
          <Skeleton variant="text" height={20} width="100%"/>

        </CardContent>
      </Card>
    );
  }

  if(tutorialType.tutorials.length === 0 && !auth.authUser.record.is_super){
    return null;
  }

  return (
    <div onClick={handleClickTutorialType}>
      <Card
        elevation={0}
        variant="outlined"
        sx={{
          borderRadius: 2,
          boxShadow: 4,
          mb: 4,
          mt: 2,
          p: 0.2,
          mx: 'auto',
          width: '95%',
          cursor: 'pointer',
          "&:hover": {
            boxShadow: 8,
          },
        }}
      >
        <CardContent dir="rtl">

            {/* Title Box */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between", // Adjust as needed
                    width: '100%',
                    p: 1,
                }}
            >   
                <Avatar sx={{bgcolor: theme.palette.primary.main}}>
                  <MatchIcon iconName={tutorialType.icon_name}/>
                </Avatar>
                <StyledTypography
                  variant="h6"
                  sx={{ 
                    fontWeight: 600 , 
                    textAlign: "center" , 
                    fontFamily: 'PT Sans'
                    }}
                >
                  {tutorialType.name}
                </StyledTypography>

          </Box>
          {/* End Title Box */}

          {/* Description */}
          <StyledTypography variant="body1" color="text.secondary" lng="he" dir="rtl">
            {tutorialType.description}
          </StyledTypography>
          {/* End Tutorial Descriptio */}

        </CardContent>
      </Card>
    </div>
  );
};

export default TutorialTypeCard;
