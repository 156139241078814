import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import LoomeLogoBackdrop from '../backdrop/LoomeLogoBackdrop';
import WebView from '../webview/WebView';
import { useLang } from '../lang';

import { pbGetAll } from '../../lib/pbGet';
import { ConstructionOutlined } from '@mui/icons-material';
import TopBar from '../topbar/TopBar';
import { Avatar, Box, Divider } from '@mui/material';
import AvatarList from '../avatar/AvatarList';
import StyledTypography from '../text/StyledTypography';
import { HorizontalScroller, ScrollItem , ProductScrollItem, TutorialScrollItem} from '../scrollers/HorizontalScroller';


export default function HomeScreen() {
  const lang = useLang();
  const [first, setFirst] = useState(true);
  const [products , setProducts] = useState([]);
  const [tutorials , setTutorials] = useState([]);

  const fetchProucts = async () => {
    const productRes = await pbGetAll('products');
    setProducts(productRes.items);
  }

  const fetchTutorials = async () => {
    const tutorialsRes = await pbGetAll('tutorials');
    setTutorials(tutorialsRes.items);
    
  }

  useEffect(() => {

    fetchProucts();
    fetchTutorials();

  } , [])


  return (
    <>
    
    <TopBar title={lang.t('home.title')}/>
    
    <div
      style={{
        height: window.innerHeight,
        overflow: 'auto'
      }}
    >

      {/* Products */}
      <Box dir={lang.t('code.dir')} style={{padding: 15}}>
        <StyledTypography variant={'h5'} sx={{width: '100%'}} dir={lang.t('code.dir')} lng={lang.t('code.lng')}>{lang.t('home.newProducts')}</StyledTypography>
        <Divider/>
        <StyledTypography variant={'subtitle'} sx={{width: '100%'}} dir={lang.t('code.dir')} lng={lang.t('code.lng')}>{lang.t('home.newProductSub')}</StyledTypography>
      </Box>
      <HorizontalScroller>
        {
          products.map((product) => ((
            <ProductScrollItem product={product}/>
          )))
        }
      </HorizontalScroller>


      {/* Tutorials */}
      <Box dir={lang.t('code.dir')} style={{padding: 15}}>
        <StyledTypography variant={'h5'} sx={{width: '100%'}} dir={lang.t('code.dir')} lng={lang.t('code.lng')}>{lang.t('home.matchVideos')}</StyledTypography>
        <Divider/>
        <StyledTypography variant={'subtitle'} sx={{width: '100%'}} dir={lang.t('code.dir')} lng={lang.t('code.lng')}>{lang.t('home.matchVideosSub')}</StyledTypography>
      </Box>

      <HorizontalScroller>
        {
          tutorials.map((tutorial) => ((
            <TutorialScrollItem tutorial={tutorial} spacing={20}/>
          )))
        }

      </HorizontalScroller>
      <div
        style={{
          height: '30%'
        }}
      >

      </div>

      {/* <WebView srcUrl={"https://www.loomecosmetics.com"}/> */}
    
    </div>
    </>
  );
}
