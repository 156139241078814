import React, { useRef, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import { Card } from '@mui/material';


const ImageCard = ({index=null , avatar=null , cur=null , onClick = () => {}}) => {

  if(!avatar){
    return(
      <div
        style={{
          width: 100,
          height: 100
        }}
      >
      </div>
    )
  }

  return(
    <div
          key={index}
          onClick={() => onClick(avatar.id)}
          style={{
            margin: 5,
          }}
        >
          <Card
            elevation={cur === avatar.id ? 5 : 0}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Avatar
              src={avatar.image_url}
              style={{
                margin: '0 10px',
                cursor: 'pointer',
              }}
              variant="rounded"
              sx={{ width: cur === avatar.id ? 120 : 100, height: cur === avatar.id ? 120 : 100 }}
            >
              {avatar.id}
            </Avatar>
          </Card>
        </div>
  )


}




const AvatarList = ({ cur = 0, data = [], onAvatarClick }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    // Scroll the selected avatar to the center when the component mounts or when cur changes
    if (containerRef.current) {
      const selectedAvatar = containerRef.current.children[cur];
      if (selectedAvatar) {
        const containerWidth = containerRef.current.offsetWidth;
        const selectedAvatarWidth = selectedAvatar.offsetWidth;
        const scrollLeft = selectedAvatar.offsetLeft - (containerWidth - selectedAvatarWidth) / 2;
        containerRef.current.scrollTo({ left: scrollLeft, behavior: 'smooth' });
      }
    }
  }, [cur]);

  return (
    <div
      ref={containerRef}
      style={{
        overflow: 'auto',
        overflowX: 'scroll',
        whiteSpace: 'nowrap',
        display: 'flex',
        flexDirection: 'row',
      }}
    >

      <ImageCard/>

      {data.map((avatar, index) => (
        <ImageCard index={index} avatar={avatar} cur={cur} onClick={onAvatarClick}/>
      ))}


      <ImageCard/>
    </div>
    
  );
};

export default AvatarList;
