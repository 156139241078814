import { useNavigate } from "react-router-dom";

import { Button } from "@mui/material";

import FullScreenDialog from "../../dialog/FullScreenDialog";
import ProductCard from "../../cards/ProductCard";
import { useLang } from "../../lang";
import StyledTypography from "../../text/StyledTypography";
import { useCart } from "../../cart";
import toast from "react-hot-toast";


function ProductSummary ({instructionRecordList = [] , setShowNavbar , open ,setOpen}){

    const navigate = useNavigate();
    const lang = useLang();
    const cart = useCart();

    const handleAddAllToCart = async () => {
        for(const instruction of instructionRecordList){
            cart.addProduct(instruction.product , false);
        }
        toast.success(lang.t('tutorial.allProductsInCartMsg'));
    }

    return(
        <FullScreenDialog 
                open={open} 
                setOpen={setOpen}
                handleCloseCallback={() => {
                    setShowNavbar(true);
                    navigate("/");
                    }}
                >
                    {instructionRecordList.map((instruction, index) => (
                        <ProductCard
                            productId={instruction.product}
                        >
                        </ProductCard>
                    ))}

                    <Button
                        sx={{
                            position: 'absolute',
                            bottom: "10%",
                            right: "10%",
                        }}
                        variant="contained"
                        color="primary"
                        onClick={handleAddAllToCart}
                    >
                        <StyledTypography variant='body1' lng={lang.t('code.lng')} dir={lang.t('code.dir')}>
                            {lang.t('tutorial.addAllToCart')}
                        </StyledTypography>
                    </Button>
                </FullScreenDialog>
    )

}

export default ProductSummary;