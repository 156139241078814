import { useEffect , useState } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

import { pbGetAll } from '../../../lib/pbGet';
import pb from '../../../lib/pocketbase';
import { useAuth } from '../../auth';
import { Box, Typography , Button } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';
import { LinePlot } from '@mui/x-charts/LineChart';
import { BarPlot } from '@mui/x-charts/BarChart';
import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis';
import { ChartsYAxis } from '@mui/x-charts/ChartsYAxis';
import { axisClasses } from '@mui/x-charts/ChartsAxis';


export default function CounterCard({ countTo, label }) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count < countTo) {
      const intervalId = setInterval(() => {
        setCount((prevCount) => {
          const nextCount = prevCount + 1;
          return nextCount <= countTo ? nextCount : prevCount;
        });
      }, 50); // You can adjust the interval duration here

      return () => clearInterval(intervalId);
    }
  }, [count, countTo]);

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {label}
        </Typography>
        <Typography variant="h4">{count}</Typography>
      </CardContent>
    </Card>
  );
}
