import { useState } from "react";
import { Typography , Button, Box } from "@mui/material";
import { useNotif } from "../notify";
import { useLang } from "../lang";
import toast from "react-hot-toast";

import ProductsStats from "../admin/carts/ProductsStats";
import TutorialStats from "../admin/carts/TutorialStats";

function AdminScreen() {

    const notif = useNotif();
    const lang = useLang();

    const [testError , setTestError] = useState(null);

    return ( 
            <Box sx={{padding: 2 , overflow: 'auto' , height: window.innerHeight}}>
                <Typography variant="h4">{lang.t('admin.title')}</Typography>

                <ProductsStats/>
                <TutorialStats/>
                <Typography variant="h5">{lang.t('admin.testing.title')}</Typography>

                <a id='openSafari' href='https://loomecosmetics.com' target="_blank" rel="noreferrer external">loome cosmetics</a>

                <div style={{height: '20%'}}></div>
            </Box>
    );
}

export default AdminScreen;