import { useTheme } from "@emotion/react";
import DynamicLogo from "../avatar/DynamicLogo";

function AppDownScreen() {
    const theme = useTheme();

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: theme.palette.primary.main // You can adjust background color based on your theme
        }}>
            <DynamicLogo size={theme.sizes.logo.large}/>
            <h1 style={{color: theme.palette.black.main }}>App is down...</h1>
        </div>
    );
}

export default AppDownScreen;
