import React, { useState } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import theme from '../theme';

const DynamicLogo = ({ backgroundColor, imageSrc = theme.images.app_logo_black_nobg , size = theme.sizes.logo.small, selected = false }) => {
  const [imageError, setImageError] = useState(false);

  // Style for the logo container
  const containerStyle = {
    backgroundColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
    width: size,
    height: size,
    maxWidth: 35,
    maxHeight: 35,
    border: selected ? `1px solid ${theme.palette.primary.main}` : 'none',
  };

  // Style for the logo image
  const logoStyle = {
    width: size,
    height: size,
    objectFit: 'contain',
  };

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <div style={containerStyle}>
      {!imageError ? (
        <img
          src={imageSrc}
          style={{ ...logoStyle }}
          onError={handleImageError}
        />
      ) : (
        <HomeIcon style={{ color: theme.palette.primary.main }} />
      )}
    </div>
  );
};

export default DynamicLogo;
