import React, { useEffect, useState } from "react";
import TutorialCard from "../cards/TutorialCard";
import pb from '../../lib/pocketbase';
import { pbGetAll } from "../../lib/pbGet";

import ShaderCanvas from '../canvas/ShaderCanvas';
import LoomeLogoBackdrop from '../backdrop/LoomeLogoBackdrop';
import FilterBar from "../filter/FilterBar";
import VideoPlayer from "../videoplayer/VideoPlayer";
import TutorialTypeCard from "../cards/TutorialTypeCard";
import { Typography  , Button} from "@mui/material";

import {useLang} from '../lang';
import StyledTypography from "../text/StyledTypography";
import DynamicLogo from "../avatar/DynamicLogo";
import { useParams } from "react-router-dom";
import { useNotif } from "../notify";
import TopBar from '../topbar/TopBar';



const TutorialTypeScreen = () => {

    const [tutorialData , setTutorialData] = useState([]);
    const [loading , setLoading] = useState(false);
    const lang = useLang();
    const notif = useNotif();

    
    const fetchTurorialTypes = async () => {
        setLoading(true);
        try{
            const data = await pbGetAll('tutorial_types');
            setTutorialData(data.items);
        }catch(error){
            console.log(error);
        }finally{
            setLoading(false);
        }
    }
    useEffect(() => {

        fetchTurorialTypes();

    }, [])

    return (
        <div>
            <TopBar title={lang.t('tutorialTypes.tutorialTypes')}/>
            <LoomeLogoBackdrop loading={loading}/>
            <div style={{overflow: 'auto' , height: window.innerHeight}}>
                <div style={{padding: 15}}>

                    <StyledTypography variant="h5" lng={lang.t('code.lng')} dir={lang.t('code.dir')}>
                        {lang.t('tutorialTypes.title')}
                    </StyledTypography>
                </div>
                <div style={{height: '5%'}}>

                </div>
                {tutorialData.map((tutorial_type) => (
                    <TutorialTypeCard tutorialType={tutorial_type}/>
                ))}
                <div style={{height: '20%'}}>

                </div>
            </div>
        </div>
      );
}

export default TutorialTypeScreen;