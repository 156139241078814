import * as React from 'react';
import { useEffect , useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import DialogContent from '@mui/material/DialogContent';

import CloseIcon from '@mui/icons-material/Close';

import AccountScreen from '../screens/AccountScreen';
import { useTheme } from 'styled-components';
import theme from '../theme';
import { useStepContext } from '@mui/material';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} timeout={{ enter: 700, exit: 400 }} />;
});

export default function AccountDialog({
  open,
  setOpen,
  title = '',
  saveButtonLabel = '',
  handleCloseCallback = () => {},
  callback = () => {},
  children,
}) {

  const [bgAlpha , setBgAlpha] = useState('D9');

  function convertToAlphaHex(alpha) {
    // Ensure the alpha value is within the valid range
    alpha = Math.max(0, Math.min(1, alpha));
  
    // Convert the alpha value to a hex value
    const hexValue = Math.round(alpha * 255).toString(16).toUpperCase();
  
    // Ensure the hex value is two digits (pad with zero if necessary)
    return hexValue.length === 1 ? '0' + hexValue : hexValue;
  }



  const handleClose = () => {
    handleCloseCallback();
    setOpen(false);
  };

  useEffect(() => {

    setBgAlpha(convertToAlphaHex(0.90));

  } , [])

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            width:'78%',
            height: '100%', // Set the desired height (in this case, half of the screen)
            position: 'absolute',
            borderRadius: '0 30px 30px 0',
            backgroundColor: 'rgba(0,0,0,0)',
            bottom: 0,
            left: 0,
            maxHeight: 'none', // Allow the content to take up the specified height
          },
        }}
      >
        
        <DialogContent
          dividers
          sx={{
            overflowY: 'auto',
            flexGrow: 1,
            zIndex: 2,
            backgroundColor: `${theme.palette.primary.main}${bgAlpha}`
          }}
        >
            <AccountScreen/>

        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
