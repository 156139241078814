import { createContext , useContext, useEffect, useState } from 'react';

import { useAuth } from './auth';
import { useLang } from './lang';


const NotificationContext = createContext(null);

export function useNotif(){
    return useContext(NotificationContext);
}


export function NotificationProvider(props){

    const icon = './images/loomeLogo.png';
    const lang = useLang();
    
    const checkPermission = async () => {

        if('Notification' in window){
            const perm = await Notification.requestPermission();
            return perm;
        }else{
            console.error('Notification api not supported');
            return 'noSupport';
        }
    }

    const send = async (title , body , onClose = () => {console.log('closed message')}) => {

        const res = await checkPermission();
        if(res === 'granted') {
            const notification = new Notification(
                title , 
                {
                    body: body,
                    data: {test: 'test notification'},
                    icon: icon,
                });
            

            notification.addEventListener('close' , e => {
                onClose();
            })

            notification.addEventListener('error' , e => {
                return 'error';
            })

            return notification.data.test;


        }else if(res === 'denied'){
            alert(lang.t('general.needNotificationPermission'));
        }else if(res === 'noSupport'){
            return 'No Support';
        }

    }


return <NotificationContext.Provider value={{send}}>
        {props.children}
    </NotificationContext.Provider>


}