
// MUI
import { Typography , Stack , Avatar , ButtonGroup , Button } from "@mui/material";

// MUI Icons
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { brown } from '@mui/material/colors';

// Costum Components
import CameraComponent from '../../camera/CameraComponent';
import SmartCamera from '../../camera/SmartCamera';
import CustomizedProgressBars from "../../progress/CustomizedProgressBars";
import ShareButton from "../../camera/controls/buttons/ShareButton";
import CheckAnimation from '../../animations/check/CheckAnimation';
import { useEffect, useState } from "react";

function TutorialCameraComponent({
    tutorial , 
    viewState , setViewState , viewStates ,
    showInstruction , setShowInstruction,
    curInstruction , setCurInstruction ,
    curInstructionIndex , setCurInstructionIndex,
    isLastInstruction , setIsLastInstruction , 
    isFirstInstruction , setIsFirstInstruction , 
    setFaceAreaNameList , 
    showCanvas , faceAreaNameList , setShowNavbar,

    instructionRecordList , 
    curIndexRef,
    onFinishTutorial = () => {}
}) {

    return ( 
        <div>
            <CameraComponent 
                showCanvas={true}
                instructionRecordList={instructionRecordList}
                curIndexRef={curIndexRef}
                showInstruction={showInstruction}
            />
        </div> 
    );
}

export default TutorialCameraComponent;