import { useEffect , useState } from 'react';

import { pbGetAll } from '../../../lib/pbGet';
import { useAuth } from '../../auth';
import { Box, Typography , Button } from '@mui/material';

import CounterCard from './CounterCard';

export default function TutorialStats() {

  const auth = useAuth();
  const [showSection , setShowSection] = useState(true);
  // const [data , setData] = useState([0]);
  // const [axisData , setAxisData] = useState([""]);
  const [tutorialTotalItems , setTutorialTotalItems] = useState(0);
  const [tutorialTypesTotalItems , setTutorialTypesTotalItems] = useState(0);

  const fetchTutorials = async () => {
    
    const users = await pbGetAll('users');
    console.log(users);

    const tutorials = await pbGetAll('tutorials');
    setTutorialTotalItems(tutorials.totalItems);

    const tutorial_types = await pbGetAll('tutorial_types');
    setTutorialTypesTotalItems(tutorial_types.totalItems);

  }

  useEffect(() => {

    fetchTutorials();

  } , [])

  return (
    <Box>
      <div>
        <Button onClick={() => {setShowSection(!showSection)}}>Tutorial Stats</Button>
      </div>
      {
        showSection &&
        <Box>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly'
            }}
          >
          <CounterCard countTo={tutorialTotalItems} label={"# Tutorials"}/>
          <CounterCard countTo={tutorialTypesTotalItems} label={"# Tutorial Types"}/>
          

          </div>
        </Box>
      }
    </Box>
  );
}





