import { Avatar } from "@mui/material";
import YouTube from 'react-youtube';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { useEffect } from "react";

function VideoAvatar({videoId , imageSrc , width= 50 , height= 50 , onClick = () => {}}) {

    const opts = {
        height: height,
        width: width,
        playerVars: {
            autoplay: 0,
        },
    };

    return ( 

        <div>
            <Avatar src={imageSrc} variant="rounded" style={{width: width , height: height , position: 'relative'}}>
                {
                    !imageSrc &&
                     <OndemandVideoIcon/>
                }
            </Avatar>
        </div>

    );
}

export default VideoAvatar;