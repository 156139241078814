import * as React from 'react';
import Badge from '@mui/material/Badge';

import { useAuth } from '../auth';

import pb from '../../lib/pocketbase';


export default function AccountBadge({children}) {
  
  const auth = useAuth();
  const [hasCoupons , setHasCoupons] = React.useState(0);

  React.useEffect(() => {
    setHasCoupons(auth.authUser.record.coupon.length);
  } , [auth.authUser.record]);
  
  return (
    <div>
      {
        hasCoupons == 0
        ? 
        <>
        {children}
        </>
        :
      <Badge color="primary" variant="dot">
          {children}
      </Badge>
      }
    </div>
  );
}