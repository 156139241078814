import { Typography } from "@mui/material";
import { useLang } from "../lang";
import { useEffect , useState } from "react";

function StyledTypography({variant=null , color = 'text.black' , dir = 'ltr' ,lng = 'en' , sx={} , style ,children }) {

    const lang = useLang();
    
    return (
        <Typography 
            dir={dir}
            variant={variant} 
            color={color}
            style={style}
            sx={{ 
                ...sx ,
                fontFamily: lng === 'en' ? 'PT Sans' : 'Miriam Libre' 
                }}>
            {children}
        </Typography>
    )

}

export default StyledTypography;