import React, { useState, useEffect } from 'react';
import Badge from '@mui/material/Badge';
import { useCart } from '../cart';
import { useCookies } from 'react-cookie';

export default function CartBadge({ children }) {

  const cart = useCart();

  useEffect(() => {
    
    cart.initCartCookie();

  } , [])

  return (
    <Badge badgeContent={Object.keys(cart.cartCookie).length} color="primary">
      {children}
    </Badge>
  );
}
