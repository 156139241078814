import React, { useEffect } from "react";
import { useState } from "react";
import CouponCard from '../cards/CouponCard';
import List from '@mui/material/List';
import Paper from "@mui/material/Paper";
import { pbGetById } from "../../lib/pbGet";
import { useAuth } from "../auth";
import { Typography } from "@mui/material";

import CouponAnimation from '../animations/coupon/CouponAnimation';
import LoomeLogoBackdrop from '../backdrop/LoomeLogoBackdrop';
import StyledTypography from '../text/StyledTypography';
import { useLang } from "../lang";


function CouponsScreen() {
    const auth = useAuth();
    const lang = useLang();
    const user = auth.authUser.record;

    const [loading , setLoading] = useState(false);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <LoomeLogoBackdrop loading={loading}/>
            
            <Paper style={{ width: '100%', maxWidth: 600, height: '100vh' ,overflow: 'auto' }}>
                {auth.authUser.record.coupon.length === 0 &&
                    (
                        <div style={{ textAlign: 'center', padding: '20px' }}>
                            <StyledTypography variant="h5" dir={lang.t('code.dir')} lng={lang.t('code.lng')}>
                                {lang.t('coupons.noCouponsMsg')}
                            </StyledTypography>
                            

                            <CouponAnimation />
                        </div>
                    )
                }
                <List>
                    {auth.authUser.record.coupon.map((couponId, index) => (
                        <CouponCard key={index} couponId={couponId}/>
                    ))}
                </List>
            </Paper>
        </div>
    );
}

export default CouponsScreen;
