import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import StyledTypography from '../text/StyledTypography';
import { useLang } from '../lang';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} timeout={{ enter: 400, exit: 400 }} />;
  // Adjust the timeout values (enter and exit) as needed
});

export default function AlertDialogSlide({
  title,
  subTitle,
  cancelButtonLabel,
  approveButtonLabel,
  open , 
  setOpen ,
  onCancel = () => {},
  onApprove = () => {},
  children
}) {

  const lang = useLang();
  const handleCancelButton = () => {

    onCancel();
    setOpen(false);

  }

  const handleApproveButton = () => {

    console.log('approve');
    onApprove();
    setOpen(false);

  }

  const handleClose = () => {
    setOpen(false);
    return;
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        dir={lang.t('code.dir')}
      >
        <DialogTitle>
          <StyledTypography lng={lang.t('code.lng')}>
            {title}
          </StyledTypography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <StyledTypography lng={lang.t('code.lng')}>
              {subTitle}
            </StyledTypography>
          </DialogContentText>
          {children}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelButton}><StyledTypography lng={lang.t('code.lng')}>{cancelButtonLabel}</StyledTypography></Button>
          <Button onClick={handleApproveButton}><StyledTypography lng={lang.t('code.lng')}>{approveButtonLabel}</StyledTypography></Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}