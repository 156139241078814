import * as React from 'react';

// MUI
import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import DialogContent from '@mui/material/DialogContent';

// MUI Icons
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} timeout={{ enter: 700, exit: 400 }} />;
  // Adjust the timeout values (enter and exit) as needed
});

export default function FullScreenDialog({
  open,
  setOpen,
  title = '',
  saveButtonLabel = '',
  handleCloseCallback = () => {},
  callback = () => {},
  children,
}) {
  const handleClose = () => {
    handleCloseCallback();
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar 
        elevation={0}
        sx={{ 
          position: 'relative',
          }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            {/* <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {title}
            </Typography> */}
            <Button autoFocus color="inherit" onClick={callback}>
              {saveButtonLabel}
            </Button>
          </Toolbar>
        </AppBar>
        
        <Box
        sx={{ 
          overflowY: 'auto', 
          flexGrow: 1,
          borderRadius: '10px 10px 0 0',
          zIndex: 2,
          }}
        >
          {children}

        </Box>
        {/* <DialogContent dividers sx={{ 
          overflowY: 'auto', 
          flexGrow: 1,
          borderRadius: '10px 10px 0 0',
          zIndex: 2,
          }}>
        </DialogContent> */}
      </Dialog>
    </React.Fragment>
  );
}
