import React, { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { Avatar, Box, Button, ButtonGroup, Grid } from "@mui/material";

// MUI Icons
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CameraIcon from '@mui/icons-material/Camera';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import FlipCameraIosIcon from '@mui/icons-material/FlipCameraIos';
import ShareIcon from '@mui/icons-material/Share';

import ShaderCanvas from '../../canvas/ShaderCanvas';
import ShareButton from "../../camera/controls/buttons/ShareButton";

function MakeupCamera({instructionRecordList}) {
    const webcamRef = useRef();
    const fileInputRef = useRef();
    const [imageSrc, setImageSrc] = useState(null);
    const [shareableImageSrc , setShareableImageSrc] = useState(null);
    const [rotateImage , setRotateImage] = useState(true);
    const [detect , setDetect] = useState(false);
    const [products , setProducts] = useState([]);
    
    const imageRef = useRef();

    const handleTakeImage = () => {
        const screenshot = webcamRef.current.getScreenshot();
        setImageSrc(screenshot);
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleUploadButtonClick = () => {
        fileInputRef.current.click();
    };

    useEffect(() => {
        const productRecords = [];

        for(const instruction of instructionRecordList){
            productRecords.push(instruction.productRecord);
        }
        setProducts(productRecords);

    } , [])

    return (
        <div>
            <Grid container spacing={1} justifyContent="center" alignItems="center">
                
                <Grid item xs={12}>
                    {!imageSrc ? (
                        <Webcam
                            ref={webcamRef}
                            style={{
                                transform: "rotateY(180deg)",
                                width: '100%',
                                height: '100%',
                                borderRadius: '8px',
                            }}
                        />
                    ) : (
                        <div>
                            <ShaderCanvas imageSrc={imageSrc} detect={detect} rotate={rotateImage} setShareableImageSrc={setShareableImageSrc} products={products}/>
                        </div>
                    )}

                </Grid>

            </Grid>
            
            <Box 
            style={{
                position:'absolute',
                bottom: '15%',
                width: '100%' , 
                zIndex: 4, // Ensure the button is above other elements
            }}
            sx={{
                ml:-3
            }}
            >
                {!imageSrc ? (
                            <ButtonGroup fullWidth width="100%">
                                <Button onClick={handleTakeImage} variant="contained" color="primary"><CameraIcon /></Button>
                                <Button onClick={handleUploadButtonClick}><FileUploadIcon /></Button>
                                <input
                                    type="file"
                                    accept="image/*"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleFileUpload}
                                />
                            </ButtonGroup>
                        ) : (
                            <Box>
                                <ButtonGroup fullWidth>
                                    <Button onClick={() => {setDetect(!detect)}} variant="contained" color={!detect ? "primary" : "secondary"}><FaceRetouchingNaturalIcon/></Button>
                                    <ShareButton imageSrc={shareableImageSrc}/>

                                </ButtonGroup>
                                <ButtonGroup fullWidth>
                                    <Button onClick={() => { setImageSrc(null) }}><DeleteOutlineIcon /></Button>
                                    <Button onClick={() => { setRotateImage(!rotateImage) }}><FlipCameraIosIcon /></Button>
                        
                                </ButtonGroup>
                            </Box>
                )}

            </Box>
            

        </div>
    );
}

export default MakeupCamera;
