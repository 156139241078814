// SupportScreen.js
import React from 'react';
import Button from '@mui/material/Button';
import StyledTypography from '../text/StyledTypography';
import ChangeLangButton from '../buttons/ChangeLangButton';
import { useLang } from '../lang';

const SupportScreen = () => {
  const lang = useLang();
  const supportEmail = 'loome.sup@gmail.com';

  const handleSendEmail = () => {
    window.location.href = `mailto:${supportEmail}`;
  };

  return (
    <>
    
    
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <StyledTypography dir={lang.t('code.dir')} lng={lang.t('code.lng')} variant="h4" gutterBottom>
        {lang.t('general.support.contactSupport')}
      </StyledTypography>
      <StyledTypography dir={lang.t('code.dir')} lng={lang.t('code.lng')} variant="h6" paragraph>
        {lang.t('general.support.contactMsg')}
      </StyledTypography>

      <Button
        variant="contained"
        color="primary"
        onClick={handleSendEmail}
        style={{ margin: '16px' }}
        >
        {supportEmail}
      </Button>

    </div>
    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '16px' , position: 'absolute' , bottom: 20 , right: '20%'}}>
        {lang.langs.map((l) => (
          <ChangeLangButton languageInfo={l} key={l.code} />
        ))}
      </div>
    </>
  );
};

export default SupportScreen;
