import React , {useEffect, useState} from 'react';
import { Route , Routes } from 'react-router-dom';
import { Box, Button, ThemeProvider } from '@mui/material';

import { Toaster } from "react-hot-toast";

// Auth
import { AuthProvider, useAuth } from './components/auth';

// Screens
import AccountScreen from './components/screens/AccountScreen';
import ProfileInformationScreen from './components/screens/PofileInformationScreen';
// import CartScreen from './components/screens/CartScreen';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import ChangePassordFrom from './components/forms/ChangePasswordForm';
import AdminScreen from './components/screens/AdminScreen';

// Bottom Navbar
import { MuiBottomNavigation } from './components/MuiBottomNavigation';
import TutorialScreen from './components/screens/TutorialScreen';
import CouponsScreen from './components/screens/CouponsScreen';
import NoMatch from './components/screens/NoMatch';
import CartSkelaton from './components/skelaton/CartSkelaton';
import InstructionScreen from './components/screens/InstructionScreen';
import CartScreen from './components/screens/CartScreen';
import HomeScreen from './components/screens/HomeScreen';
import TutorialTypeScreen from './components/screens/TutorialTypeScreen';
import SupportScreen from './components/screens/SupportScreen';

import pb from './lib/pocketbase';
import { endpoint } from './lib/backend_endpoint';
import DressingRoom from './components/dress_room/DressingRoom';
import theme from './components/theme';


import { useLang } from './components/lang';
import PrivacyPolicy from './components/screens/PrivacyPolicy';
import AppDownScreen from './components/screens/AppDownScreen';


const LazyCartScreen = React.lazy(() => import('./components/screens/CartScreen'));
const LazyHomeScreen = React.lazy(() => import('./components/screens/HomeScreen'));

function App() {

  const [screenVal , setScreenVal] = useState(2);
  const [showNavbar , setShowNavbar] = useState(true);
  const auth = useAuth();
  
  
  useEffect(() => {
    // Detect when the keyboard is shown
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () => {
    // Check if the screen height has changed (indicating keyboard visibility change)
    if (window.innerHeight < window.screen.height) {
      // The keyboard is now visible, scroll to the desired position
      const scrollPosition = 0; // Set your desired scroll position
      window.scrollTo(0, scrollPosition);
    }
  };
  
  return (
    <ThemeProvider theme={theme}>

    
    <Box sx={{
        width: window.innerWidth,
        height: window.innerHeight,
        background: `linear-gradient(180deg, #FFFFFF 15%, ${theme.palette.secondary.dark} 95%)`,
      }}>
      <Toaster/>
        {
        !auth.authUser
        
        ?
        <div>
        <Routes>
          {
            endpoint 
            ?
            <Route path='/' element={<SignIn/>}></Route>
            :
            <Route path='/' element={<AppDownScreen/>}></Route>
          }
          <Route path='confirm-password-reset/:token' element={<ChangePassordFrom/>}></Route>
          <Route path='privacy-policy' element={<PrivacyPolicy/>}></Route>
          <Route path="support" element={<SupportScreen/>} />
          <Route path='*' element={<NoMatch/>}></Route>

        </Routes>
        </div>
        :
        <div>

        <Routes>
            <Route path='/' element={<TutorialTypeScreen/>}></Route>
            <Route path='tutorials/:tutorialTypeId' element={<TutorialScreen/>}></Route>
            <Route index path='dress-room/:type/:recordId' element={<DressingRoom setShowNavbar={setShowNavbar}/>}></Route>
            <Route path="instruction/:tutorialId" element={<InstructionScreen/>}></Route>
            <Route path='account/personal-information' element={<ProfileInformationScreen/>}></Route>
      
            {/* <Route path='home' element={<React.Suspense fallback="Loading..."><LazyHomeScreen/></React.Suspense>}></Route>
            <Route path='cart' element={<React.Suspense fallback="Loading..."><LazyCartScreen/></React.Suspense>}></Route> */}
            <Route path='home' element={<HomeScreen/>}></Route>
            <Route path='cart' element={<CartScreen/>}></Route>
            <Route path="support" element={<SupportScreen/>} />
            
            {
              auth.authUser.record.is_super &&
              <Route path='_' element={<AdminScreen/>}></Route>
            }


            <Route path='*' element={<NoMatch/>}></Route>
        </Routes>

        {
          showNavbar &&
          <MuiBottomNavigation screenVal = {screenVal} setScreenVal = {setScreenVal}/>
        }
        </div>
      }
    </Box>

    
    </ThemeProvider>
  );
}

export default App;