import { createContext , useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';


const LangContext = createContext(null);

export function useLang(){
    return useContext(LangContext);
}

export function LangProvider(props){

    // const [lng , setLng] = useState(navigator.language);    
    const [lng , setLng] = useState('he');    
    const {t , i18n} = useTranslation();
    const langs = [
        {
            lang: 'he',
            countryCode: 'IL',
        },
        {
            lang: 'en',
            countryCode: 'US',
        }
    ];

    useEffect(() => {
        i18n.changeLanguage(lng);
    } , [])

    const changeLanguage = (nextLng = null) => {
                
        if(!nextLng){
            var curLng = lng === 'en' ? 'he' : 'en';
        }
        else{
            var curLng = nextLng;
        }

        setLng(curLng);
        i18n.changeLanguage(curLng);
    }


    return <LangContext.Provider value={{lng , changeLanguage , t , langs}}>
        {props.children}
    </LangContext.Provider>

}