import { useState , useRef, useEffect } from 'react';


// YOUTUBE
import YouTube from 'react-youtube';

// MUI
import { Button , Avatar, Typography, Grid  , Stack, ButtonGroup } from '@mui/material';
import { brown } from '@mui/material/colors';

// MUI Icons
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import ClearAllIcon from '@mui/icons-material/ClearAll';

import FullScreenDialog from '../../dialog/FullScreenDialog';
import CustomizedProgressBars from '../../progress/CustomizedProgressBars';
import ProductDialog from '../../dialog/ProductDialog';
import ScrollDialog from '../../dialog/ScrollDialog';
import {InstructionCard} from '../../cards/InstructionCard';
import StyledTypography from '../../text/StyledTypography';
import { useLang } from '../../lang';
import theme from '../../theme';

import { LargePlayButton } from '../../buttons/LargePlayButton';

const switchViewRatio = 3/4;
const switchVideoScale = 170;

const buttonSize = theme.sizes.logo.xxSmall;

const controlButtonStyle = {
    height: buttonSize , 
    width: buttonSize ,
    fontSize: 4,
    padding: 10
}


function ChangeFrameView({state , changeView = () => {} , addStyle = {} , children}){

    const smallStyle = {
        position: 'absolute',
        left: 25,
        bottom: '5%',
        zIndex: 30,
        width: switchVideoScale * switchViewRatio,
        height: switchVideoScale / switchViewRatio,
    }

    const largeStyle = {...addStyle , width: '100%' , height: '100%'}

    return(
        <>  
            <div 
            style={state ? largeStyle : smallStyle}
            onClick={!state ? changeView : () => {}}
            >
                {children}
            </div>
        </>
    
        );

}

function TutorialVideo({
    open , setOpen , title = "" , videoId ,
    tutorial , 
    viewState , setViewState , viewStates ,
    showInstruction , setShowInstruction,
    curInstruction , setCurInstruction,
    curInstructionIndex , setCurInstructionIndex,
    isLastInstruction , setIsLastInstruction , 
    isFirstInstruction , setIsFirstInstruction , 
    setFaceAreaNameList , instructionRecordList , 
    showCanvas , faceAreaNameList, setShowNavbar,
    curTimeRef  ,curTime , setCurTime ,curIndexRef,
    onFinishTutorial = () => {},
    children
}) {

    const lang = useLang();
    
    const [viewChange , setViewChange] = useState(false);
    
    const [videoState , setVideoState] = useState(true);
    const [disableMove , setDisableMove] = useState(false);
    const [openProductDialog , setOpenProductDialog] = useState(false);
    const [curProduct , setCurProduct] = useState(null);
    const [showAllInstructions , setShowAllInstructions] = useState(false);

    const videoPlayerRef = useRef();
    

    const opts = {
        height: !viewChange ? window.innerHeight : switchVideoScale / switchViewRatio,
        width: !viewChange ? window.innerWidth : switchVideoScale * switchViewRatio,
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
        },
    };

    // Instruction Functions
    const handleInstruction = async (index) => {

        setDisableMove(true);
        setCurInstructionIndex(index);
        if(!instructionRecordList[index]){
            return;
        }
        curIndexRef.current.innerText = index;

        try{
            await setFaceAreaNameList(instructionRecordList[index].faceAreasNameList);
        }catch(error){
            console.log(error);
        }

        try{
            goTo(tutorial.youtube_video_data.section_timing[index]);
        }catch(error){
            console.log(error);
        }

        setDisableMove(false);
    }

    const handleNextInstruction = () => {

        const nextIndex = curInstructionIndex + 1;
        if (nextIndex == instructionRecordList.length){
            setCurInstructionIndex(instructionRecordList.length - 1);
            setIsLastInstruction(true);
            onFinishTutorial();
            return;
        }

        setIsLastInstruction(false);
        setIsFirstInstruction(false);
        handleInstruction(nextIndex);
        
    }
    
    const handlePrevInstruction = () => {
        
        const nextIndex = curInstructionIndex - 1;
        
        // if(isFirstInstruction){
            //     setCurInstructionIndex(nextIndex);
            //     setViewState(viewStates.tutorialDescription);
            //     setShowNavbar(true);
            // }
        if (nextIndex < 0){
            setCurInstructionIndex(0);
            setIsFirstInstruction(true);
            handleInstruction(0);
            return;
        }
        
        setIsLastInstruction(false);
        setIsFirstInstruction(false);
        handleInstruction(nextIndex);
        
    }

    // Video Control Functions

    const goTo = (timeSec) => {

        if(!timeSec){
            return;
        }

        curTimeRef.current.innerText = timeSec;
        videoPlayerRef.current.getInternalPlayer().seekTo(timeSec);

    }

    const changeView = () => {
        setViewChange(!viewChange);
    }

    const changeVideoState = () => {
        
        // console.log(videoPlayerRef.current.internalPlayer);

        if(!videoState){
            videoPlayerRef.current.getInternalPlayer().pauseVideo()
        }else{
            // videoPlayerRef.current.internalPlayer.playVideo()   
            videoPlayerRef.current.getInternalPlayer().playVideo();   
        }

        setVideoState(!videoState);
    }

    const getCurInternalPlayerTime = async () => {

        try{
            const t = await videoPlayerRef.current.getInternalPlayer().getCurrentTime();
            setCurTime(t);
        }catch(error){
            return;
        }
    }

    const checkSyncInstructionChange = async () => {

        if(!curTimeRef || !curTimeRef.current || !tutorial || !tutorial.youtube_video_data || tutorial.youtube_video_data.section_timing.length === 0 ){
            return;
        }
        const timeFromRef = parseInt(curTimeRef.current.innerText);

        if(
            timeFromRef >= tutorial.youtube_video_data.section_timing[curInstructionIndex] &&
            timeFromRef <= tutorial.youtube_video_data.section_timing[curInstructionIndex + 1]
            ){
                // The time is correct for this check
            }else{
                for(let i=0; i < tutorial.youtube_video_data.section_timing.length ; i++){
                    if(
                        timeFromRef >= tutorial.youtube_video_data.section_timing[i] &&
                        timeFromRef < tutorial.youtube_video_data.section_timing[i + 1]    
                    ){
                        if(parseInt(curIndexRef.current.innerText) != i){
                            await handleInstruction(i);
                        }
                    }
            }
        }


        


    }

    const handleShowAllInstructions = () => {

        // Open a popup with all the intructions of the current tutorial

        if(!curInstruction || instructionRecordList.length === 0){
            return;
        }

        setShowAllInstructions(true);

    }
    
    useEffect(() => {

        try{
            if(curInstruction){
                setCurProduct(curInstruction.productRecord);
            }
    
            const intervalId = setInterval(async () => {
                getCurInternalPlayerTime();
                checkSyncInstructionChange();
                
            }, 500);
    
            // Cleanup function to clear the interval when the component unmounts
            return () => clearInterval(intervalId);

        }catch(error){
            console.log(error);
        }

    }, []);

    const cleanUp = () => {

        setCurInstructionIndex(0);
        handleInstruction(0);
        setCurTime(0);
        setViewChange(false);
        setVideoState(true);
        curIndexRef.current.innerText = 0;
        onFinishTutorial();

    }
    

    if(!videoId){
        return null;
    }
    
    return ( 

        <FullScreenDialog open={open} setOpen={setOpen} title={title} handleCloseCallback={cleanUp} >
            {/* Instruction Info */}
            {
                instructionRecordList[curInstructionIndex] &&
                <div 
                style={{ 
                    position: 'absolute', 
                    top: '10%',
                    width: '100%',
                    padding: 15,
                    // backgroundColor: 'rgba(0,0,0,0.5)',
                    zIndex: 20,
                    }}>
                    <StyledTypography variant="h5" dir={lang.t('code.dir')} lng={lang.t('code.lng')} color={theme.palette.black.main} style={{ fontWeight: 'bold', marginTop: '8px' }} sx={{mr: 4}}>
                        {instructionRecordList[curInstructionIndex].title}
                    </StyledTypography>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-evenly',
                            marginLeft: '5%',
                            marginRight: '5%'
                        }}
                    >
                        {/* {instructionRecordList[curInstructionIndex].productRecord && (
                            <Avatar
                                sx={{ bgcolor: brown[100] , width: 75 , height: 75}}
                                variant='rounded'
                                src={instructionRecordList[curInstructionIndex].productRecord.image_url}
                            >
                                Loome
                            </Avatar>
                        )} */}
                        <StyledTypography dir={lang.t('code.dir')} lng={lang.t('code.lng')} variant="body1" color={theme.palette.black.main}>
                            {instructionRecordList[curInstructionIndex].desc}
                        </StyledTypography>
                    </div>
                </div>
            }
            {/* End Instruction Info */}


            {/* Video Player */}

            <ChangeFrameView state={!viewChange} changeView={changeView} addStyle={{}}>
            <div>
            {/* Video Player */}

                {/* Debug Text */}
                <div style={{position: 'absolute' , top: 100 , left : 20 , zIndex: 10 , opacity: 0}}>
                    <p ref={curTimeRef} style={{color: 'white'}}>{Math.round(curTime)}</p>
                    <p ref={curIndexRef} style={{color: 'white'}}>{curInstructionIndex}</p>
                </div>
                {/* End Debug Text */}


                <div
                    style={{
                        position: 'absolute',
                        zIndex: 3,
                        opacity: 1,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <LargePlayButton onClick={changeVideoState} hidden={!videoState}/>
                </div>

                
                <YouTube 
                    ref={videoPlayerRef} 
                    videoId={videoId}
                    opts={opts}
                    onPause={() => {setVideoState(true)}}
                    onPlay={() => {setVideoState(false)}}
                    onEnd={() => {onFinishTutorial()}}
                />

                {/* End Video Player */}

            </div>
                
            </ChangeFrameView>

            {/* End Video Player */}


            {/* Camera View */}

            <ChangeFrameView state={viewChange} changeView={changeView}>
                <Avatar variant='square' style={{width: '100%' , height: '100%'}}>
                    {children}
                </Avatar>
            </ChangeFrameView>
            {/* End Camera View */}


            {/* Video Controls */}

            <div
            style={{
                width: '45%',
                height: '20%',
                position: 'absolute',
                right: '6%',
                bottom: '3%',
                zIndex: 10
            }}
            >

                <ButtonGroup 
                variant="text" 
                color="secondary" 
                aria-label=" button group"
                fullWidth
                style={{
                    marginTop: 50,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                }}
                >
                    <Button 
                    onClick={handlePrevInstruction} 
                    disableRipple 
                    // disabled={isFirstInstruction || !curInstruction}
                    >
                        <KeyboardArrowLeftIcon style={controlButtonStyle} color='secondary'/>
                    </Button>
                    <Button
                        disableRipple
                        onClick={changeVideoState}
                    >
                        {
                            videoState
                            ? <PlayArrowIcon color='secondary' style={controlButtonStyle}/>
                            : <PauseIcon color='secondary' style={controlButtonStyle}/>
                        }
                    </Button>   

                    <Button 
                    onClick={handleNextInstruction} 
                    disableRipple 
                    // disabled={isLastInstruction || ! curInstruction}
                    >
                        <KeyboardArrowRightIcon style={controlButtonStyle} color='secondary'/>
                    </Button>  
                </ButtonGroup>

                <ButtonGroup 
                variant="text" 
                color="secondary" 
                aria-label=" button group"
                fullWidth
                style={{
                    marginTop: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                }}
                >
                    <Button onClick={handleShowAllInstructions} disabled={instructionRecordList.length === 0 || !curInstruction}>
                        <ClearAllIcon style={controlButtonStyle} color='secondary'/>
                    </Button>
                    <Button
                        disableRipple
                    >
                    </Button>   
                    {
                        (curInstructionIndex + 1 < instructionRecordList.length && curInstruction) &&
                        <Button disableRipple disabled={isLastInstruction || ! curInstruction} onClick={() => {setOpenProductDialog(true)}}>
                            <Avatar 
                                variant='rounded' 
                                src={
                                    instructionRecordList[curInstructionIndex + 1].productRecord.image_url
                                    ?instructionRecordList[curInstructionIndex + 1].productRecord.image_url
                                    : theme.images.app_logo_bg
                                    }
                                >
                                    L
                                </Avatar> 
                        </Button>
                    }
                </ButtonGroup>

            </div>


            {/* End Video Controls */}

            {/* Progress Bar */}

            <CustomizedProgressBars value={(curInstructionIndex / instructionRecordList.length) * 100}/>
            
            {
                curProduct &&
                <ProductDialog open={openProductDialog} setOpen={setOpenProductDialog} product={instructionRecordList[curInstructionIndex + 1].productRecord}/>
            }

            <ScrollDialog 
                open={showAllInstructions} 
                setOpen={setShowAllInstructions} 
                title={tutorial.title}
                >
                    {
                        instructionRecordList.map((instruction) => (
                            <InstructionCard instruction={instruction}></InstructionCard>
                        ))
                    }

            </ScrollDialog>
            
        </FullScreenDialog>


    );
}

export default TutorialVideo;