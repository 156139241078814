import { useState, useRef } from "react";
import { Button } from "@mui/material";
import AccountAvatar from "../avatar/AccountAvatar";
import imageCompression from "browser-image-compression";

function UploadProfileImage({ imageSrc, setImageSrc, fileUpload, setFileUpload, imageSize = 50 }) {
  
  const fileInputRef = useRef(null);

  const handleAccountImageChange = async (e) => {
    const selectedFile = e.target.files[0];

    try {
      const compressedFile = await compressImage(selectedFile);
      setImageSrc(URL.createObjectURL(compressedFile));
      setFileUpload(compressedFile);
    } catch (error) {
      console.error("Error compressing image:", error);
    }
  };

  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 1, // Adjust the max size as needed
      maxWidthOrHeight: 800, // Adjust the max width or height as needed
      useWebWorker: true,
    };

    try {
      return await imageCompression(file, options);
    } catch (error) {
      console.error("Error compressing image:", error);
      throw error;
    }
  };

  const handleButtonClick = () => {
    // Trigger the click event of the file input when the button is clicked
    fileInputRef.current.click();
  };

  return (
    <>
      <input
        type="file"
        id="fileInput"
        onChange={handleAccountImageChange}
        style={{ display: "none" }} // Hide the file input visually
        ref={fileInputRef} // Connect the ref to the file input
      />

      <Button onClick={handleButtonClick}>
        <AccountAvatar imageSrc={imageSrc} imageSize={imageSize} />
      </Button>
    </>
  );
}

export default UploadProfileImage;
