import { useState , useRef } from 'react';

import AccountAvatar from '../avatar/AccountAvatar';
import AlertDialogSlide from './AlertDialogSlide';

import { useAuth } from '../auth';

function UpdateProfileImageDialog({open , setOpen}) {


  const auth = useAuth();
  const user = auth.authUser.record;
  const [imageSrc, setImageSrc] = useState(null);
  const [fileUpload , setFileUpload] = useState(null);

  // Create a reference for the file input
  const fileInputRef = useRef(null);

    const handleAccountImageChange = (e) => {
        setImageSrc(URL.createObjectURL(e.target.files[0]));
        setFileUpload(e.target.files[0]);
    }

    const handleButtonClick = () => {
        // Trigger the click event of the file input when the button is clicked
        fileInputRef.current.click();
    };

    const saveNewImage = () => {

        console.log("save image");
        setOpen(false);

    }


    return ( 
        <AlertDialogSlide 
        open={open} 
        setOpen={setOpen} 
        title={"החלף תמונת פרופיל"}
        approveButtonLabel={"אישור"}
        cancelButtonLabel={'ביטול'}
        onApprove={saveNewImage}
        >
            <div onClick={handleButtonClick}>
                <AccountAvatar imageSize={250} imageSrc={imageSrc}/>
            </div>

            <input
              type="file"
              id="fileInput"
              onChange={handleAccountImageChange}
              style={{ display: 'none' }} // Hide the file input visually
              ref={fileInputRef} // Connect the ref to the file input
            />

        </AlertDialogSlide>
    );
}

export default UpdateProfileImageDialog;