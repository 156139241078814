import { createContext , useContext, useEffect, useState } from 'react';

import { useAuth } from './auth';
import { useLang } from './lang';
import { useCookies } from 'react-cookie';
import toast from 'react-hot-toast';


const CartContext = createContext(null);

export function useCart(){
    return useContext(CartContext);
}

export function CartProvider(props){
    
    const lang = useLang();
    const auth = useAuth();
    const initCartCookieName = `loomecart`
    const [cartCookieName , setCartCookieName] = useState(initCartCookieName);
    const [cookies, setCookie, removeCookie] = useCookies([cartCookieName]);
    const [products , setProducts] = useState([]);
    const [cartCookie , setCartCookie] = useState({});

    // STORE SESSION CONTROL
    const [sessionUrl , setSessionUrl] = useState("https://www.loomecosmetics.com");
    const [openSessionDialog , setOpenSessionDialog] = useState(true);

    const showProductInStore = (product_store_url) => {
        if(!product_store_url){
            return;
        }
        setSessionUrl(product_store_url);
        setOpenSessionDialog(true);
    }

    const initCartCookie = () => {
        
        const userRelatedName = initCartCookieName + auth.authUser.record.id;
        setCartCookieName(userRelatedName);

        if(!cookies[userRelatedName]){
            setCookie(userRelatedName , {});
            setCartCookie({});
        }else{
            setCartCookie(cookies[userRelatedName]);
        }

    }
    
    const getCartObject = () => {

        try{
            return cartCookie;
        }catch(error){
            return error
        }

    }

    const addProduct = (productId , showToast = true) => {
        
        if(!cartCookie || !cartCookie[productId]){
            const newObj = cartCookie;
            newObj[productId] = 1;
            setCookie(cartCookieName , newObj);
            setCartCookie(newObj);
        }else{
            const newObj = cartCookie;
            newObj[productId] = cartCookie[productId] + 1;
            setCookie(cartCookieName , newObj);
            setCartCookie(newObj)
        }

        if(showToast){
            toast.success(lang.t('toast.addedToCart'))

        }
        // console.log(cartCookie);

    }

    const emptyCart = () => {
        
        const newObj = {};
        setCookie(cartCookieName, newObj);
        setCartCookie(newObj);

        toast.success(lang.t('toast.emptyCart'));
        
    }

    const removeProduct = (productId , showToast = true) => {
        try {
            if (cartCookie && cartCookie[productId]) {
                const newObj = { ...cartCookie };
                delete newObj[productId];
                console.log(newObj)
                setCookie(cartCookieName, newObj);
                setCartCookie(newObj);
                if(showToast){
                    toast.success(lang.t('toast.removedFromCart'));
                }
            }
        } catch (error) {
            console.error('Error removing product from cart:', error);
            toast.error(lang.t('toast.errorRemovingFromCart'));
        }
    }

    return <CartContext.Provider value={{
        addProduct , 
        removeProduct , 
        getCartObject , 
        emptyCart , 
        products , 
        initCartCookie , 
        cartCookie,
        sessionUrl,
        setSessionUrl,
        openSessionDialog,
        setOpenSessionDialog,
        showProductInStore
        }}>
        {props.children}
    </CartContext.Provider>


}