import { Box, Typography } from "@mui/material";

import ScrollDialog from "./ScrollDialog";

function Section({title , content}){

    return(
        <Box>
            <Typography variant="h6">
                {title}
            </Typography>
            <Typography>
                {content}
            </Typography>
        </Box>
    );
}


const app_general_info = {
    app_name: "Loome",
    developer_name: "עפרי מקדסי",
    owner_name : 'טל בלום'
}

const termsAndConditionsContent = [
    {
        title : `תנאי מדיניות ושירות של אפליקציית "${app_general_info.app_name}"`,
        content: `אפליקציית "${app_general_info.app_name}" (להלן: "האפליקציה") היא אפליקציה המאפשרת למשתמשים לצפות בתצוגה מקדימה של מוצרי איפור שונים באמצעות מצלמת הפלאפון. האפליקציה פותחה על ידי ${app_general_info.developer_name}.`
    },
    {
        title : `תנאים כלליים`,
        content: `בעצם השימוש באפליקציה, המשתמש מסכים לתנאי מדיניות ושירות אלה. אם המשתמש אינו מסכים לתנאים אלה, הוא אינו רשאי להשתמש באפליקציה`
    },
    {
        title : `שימוש באפליקציה`,
        content: `   
האפליקציה מיועדת לשימוש אישי בלבד. השימוש באפליקציה למטרות מסחריות אסור בהחלט.
המשתמש מתחייב להשתמש באפליקציה באופן חוקי ובתום לב. המשתמש מתחייב שלא להשתמש באפליקציה כדי להפיץ תוכן פוגעני או לא חוקי.
האפליקציה עשויה להכיל קישורים לאתרים או אפליקציות של צד שלישי. השימוש בקישוריים אלה כפוף לתנאי השימוש של אתרים או אפליקציות אלה.
האפליקציה עשויה להכיל פרסומות. המשתמש מסכים לקבל פרסומות באפליקציה.
האפליקציה עשויה להכיל רכיבים של צד שלישי, כגון רכיבים של שירותי Google. השימוש ברכיבים אלה כפוף לתנאי השימוש של שירותי Google.
האפליקציה עשויה להכיל שגיאות או תקלות. המפתח אינו אחראי לשגיאות או תקלות באפליקציה.
האפליקציה עשויה להשתנות או להתעדכן מעת לעת. המפתח אינו אחראי לשינויים או עדכונים באפליקציה.     
        `
    },
    {
        title: `
זכויות יוצרים
        `,
        content:
        `        
כל הזכויות לאפליקציה, לרבות הזכויות לתוכן, למידע ולתכונות, שמורות ל${app_general_info.owner_name} ומפתחי אפליקציית.
        `
    },
    {
        title: 
        `
        הצהרה לגבי פרטיות
        `,
        content:
        `
        האפליקציה עשויה לאסוף מידע מסוים מהמשתמש, כגון כתובת IP, מיקום גיאוגרפי ונתונים על השימוש באפליקציה. המידע הזה ישמש את המפתח כדי לשפר את האפליקציה ולספק למשתמשים שירות טוב יותר.
        המפתח לא יעביר את המידע הזה לצד שלישי אלא אם כן יהיה צורך בכך על פי דין או על פי בקשת המשתמש.
        `
    },
    {
        title: 
        `
שינויים בתנאי מדיניות ושירות
        `,
        content:
        `

המפתח רשאי לשנות את תנאי מדיניות ושירות אלה מעת לעת. השינויים ייכנסו לתוקף מרגע פרסומם באפליקציה.

        `

    },
    {
        title: 
        `
החוק החל
        `,
        content:
        `
תנאי מדיניות ושירות אלה יוסדרו ופירושם יהיו על פי חוקי מדינת ישראל.
        `

    },
    {
        title: 
        `
סמכות שיפוט
        `,
        content:
        `
כל מחלוקת הנובעת מתנאי מדיניות ושירות אלה תובא בפני בית משפט מוסמך במדינת ישראל.
        `

    },
    {
        title: 
        `
שונות
        `,
        content:
        `
אם הוראה כלשהי מתנאי מדיניות ושירות אלה תימצא בלתי חוקית, בטלה או בלתי אכיפה, אזי אותה הוראה תיחשב כמנותקת מתנאי מדיניות ושירות אלה והיתר תנאי מדיניות ושירות אלה ימשיכו לחול במלואם.
        `
    },
    
]


function TermsAndConditionsContent({
    open, 
    setOpen, 
    title, 
    agreeButtonName, 
    cancelButtonName, 
    callback = () => {}
}) {
    return (
        <ScrollDialog 
            open={open}
            setOpen={setOpen}
            title={title}
            agreeButtonName={agreeButtonName}
            cancelButtonName={cancelButtonName}
            callback={callback}
        >
            <Box>
                {termsAndConditionsContent.map((section) => (
                <Section title={section.title} content={section.content} />
                ))}
            </Box>
        </ScrollDialog>
    );
  }
  
  export default TermsAndConditionsContent;



const termsAndConditionsContent1 = `


`