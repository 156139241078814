import { Typography , Box } from "@mui/material";
import Markdown from 'react-markdown'



const ppen = `
**Privacy Policy for the Loome App**

**introduction**

Loome is an app that allows users to create and share creative video content. The application collects certain information about its users, including personal information, to provide its services and improve its user experience.

This privacy policy explains what information Loome collects, how it uses that information, to whom it transfers the information, and how your personal information can be controlled.

**What information does Loome collect?**

Loome collects certain personal information about its users, including:

* Name, e-mail address, phone number, and other personal information that the user provides when registering for the application.
* Information about your use of the application, including the type of content you create, the time you spend in the application, and how you navigate it.
* Technical information about your device, including the operating system, browser type, and device ID.

Loome may also collect additional information, such as information about your location, if you allow the app to access your location.

**How does Loome use information?**

Loome uses the information it collects to provide its services, including:

* To allow you to register for the App and manage your account.
* To provide you with personalized content and services.
* To improve your experience using the application.
* To protect the app and its users.

Loome may also use the information it collects to create statistical data about the use of the application. This data is used to improve the application and develop new products and services.

**To whom does Loome transfer the information?**

Loome does not transfer your personal information to third parties without your consent. However, Loome may transfer your personal information to third parties in the following cases:

* If you order services or products from a third party through the App, Loome may transfer your personal information to that third party so that the third party can provide you with the services or products.
* If Loome merges with or is sold to another company, your personal information may be transferred to the other company.
* If Loome is required to do so by law or court order.

**How can you control your personal information?**

The personal information you provide to Loome can be controlled in the following ways:

* When registering for the application, you can choose to provide only the personal information required to provide you with the services.
* Your personal information can be changed or deleted at any time in your account.
* You can withdraw your consent to the use of your personal information for the purpose of receiving direct mail.

**information security**

Loome implements security measures to protect your personal information. These security measures include:

* Encryption of your personal information.
* Protection of your personal information from unauthorized access.
* Protection of your personal information from misuse.

Loome makes every effort to protect your personal information, but it is important to note that no security measures are completely foolproof.

**User Rights**

Users have the following rights regarding their personal information:

* The right to access their personal information.
* The right to correct or delete their personal information.
* The right to object to the use of their personal information.
* The right to limit the use of their personal information.
* The right to transfer their personal information to a third party.

Users can exercise their rights by contacting Loome using the contact form on the company's website.

**Privacy Policy Change**

Loome may change this privacy policy from time to time. If Loome makes material changes to the Privacy Policy, it will notify users by email or by posting a notice on its website.

**contact**

If you have any questions or concerns about Loome's privacy policy, please contact Loome using the contact form on the company's website.

**Additional additions that can be considered for inclusion in the privacy policy**

* Information regarding specific uses of personal information, such as targeted marketing.
* Information about how Loome uses statistical data about the use of the application.
* Information regarding the security measures that Loome implements to protect users' personal information.
* Information regarding user rights in certain countries, such as the European Union.

The specific wording of these additions depends on the app's target country and local privacy laws.
`

const pphe = `
**מדיניות פרטיות לאפליקציית Loome**

**הקדמה**

Loome היא אפליקציה המאפשרת למשתמשים ליצור ולשתף תוכן וידאו יצירתי. האפליקציה אוספת מידע מסוים על המשתמשים שלה, לרבות מידע אישי, כדי לספק את השירותים שלה ולשפר את חווית השימוש בה.

מדיניות פרטיות זו מסבירה מהו המידע ש-Loome אוספת, כיצד היא משתמשת במידע זה, למי היא מעבירה את המידע, וכיצד ניתן לשלוט במידע האישי שלך.

**איזה מידע אוספת Loome?**

Loome אוספת מידע אישי מסוים על המשתמשים שלה, לרבות:

* שם, כתובת דואר אלקטרוני, מספר טלפון, ומידע אישי אחר שהמשתמש מספק בעת ההרשמה לאפליקציה.
* מידע על השימוש שלך באפליקציה, לרבות סוג התוכן שאתה יוצר, הזמן שאתה מבלה באפליקציה, וכיצד אתה מנווט בה.
* מידע טכני על המכשיר שלך, לרבות מערכת ההפעלה, סוג הדפדפן, ומזהה המכשיר.

Loome עשויה גם לאסוף מידע נוסף, כגון מידע על מיקומך, אם תאפשר לאפליקציה לגשת למיקום שלך.

**כיצד Loome משתמשת במידע?**

Loome משתמשת במידע שהיא אוספת כדי לספק את השירותים שלה, לרבות:

* כדי לאפשר לך להירשם לאפליקציה ולנהל את החשבון שלך.
* כדי לספק לך תוכן ושירותים מותאמים אישית.
* כדי לשפר את חווית השימוש שלך באפליקציה.
* כדי להגן על האפליקציה ומשתמשיה.

Loome עשויה גם להשתמש במידע שהיא אוספת כדי ליצור נתוני סטטיסטיקה על השימוש באפליקציה. נתונים אלה משמשים לשיפור האפליקציה ולפיתוח מוצרים ושירותים חדשים.

**למי Loome מעבירה את המידע?**

Loome אינה מעבירה את המידע האישי שלך לצדדים שלישיים ללא הסכמתך. עם זאת, Loome עשויה להעביר את המידע האישי שלך לצדדים שלישיים במקרים הבאים:

* אם תזמין שירותים או מוצרים מצד שלישי באמצעות האפליקציה, Loome עשויה להעביר את המידע האישי שלך לאותו צד שלישי כדי שהצד השלישי יוכל לספק לך את השירותים או המוצרים.
* אם Loome תתמזג עם חברה אחרת או תימכר לחברה אחרת, המידע האישי שלך עשוי להיות מועבר לחברה האחרת.
* אם Loome מחויבת לעשות זאת על פי חוק או על פי צו שיפוטי.

**כיצד ניתן לשלוט במידע האישי שלך?**

ניתן לשלוט במידע האישי שאתה מספק ל-Loome בדרכים הבאות:

* בעת ההרשמה לאפליקציה, ניתן לבחור למסור רק את המידע האישי הנדרש כדי לספק לך את השירותים.
* ניתן לשנות או למחוק את המידע האישי שלך בכל עת בחשבון שלך.
* ניתן לבטל את ההסכמה לשימוש במידע האישי שלך לצורך קבלת דיוור ישיר.

**בטיחות המידע**

Loome מיישמת אמצעי אבטחה כדי להגן על המידע האישי שלך. אמצעי אבטחה אלה כוללים:

* הצפנה של המידע האישי שלך.
* הגנה על המידע האישי שלך מפני גישה לא מורשית.
* הגנה על המידע האישי שלך מפני שימוש לרעה.

Loome עושה את כל המאמצים להגן על המידע האישי שלך, אך חשוב לציין כי אין אמצעי אבטחה חסינים לחלוטין.

**זכויות המשתמשים**

למשתמשים יש את הזכויות הבאות לגבי המידע האישי שלהם:

* הזכות לקבל גישה למידע האישי שלהם.
* הזכות לתקן או למחוק את המידע האישי שלהם.
* הזכות להתנגד לשימוש במידע האישי שלהם.
* הזכות להגביל את השימוש במידע האישי שלהם.
* הזכות להעביר את המידע האישי שלהם לצד שלישי.

משתמשים יכולים לממש את זכויותיהם על ידי פנייה ל-Loome באמצעות טופס יצירת קשר באתר האינטרנט של החברה.

**שינוי מדיניות הפרטיות**

Loome רשאית לשנות את מדיניות הפרטיות הזו מעת לעת. אם Loome תבצע שינויים מהותיים במדיניות הפרטיות, היא תודיע על כך למשתמשים באמצעות דואר אלקטרוני או על ידי פרסום הודעה באתר האינטרנט שלה.

**יצירת קשר**

אם יש לך שאלות או חששות לגבי מדיניות הפרטיות של Loome, אנא צור קשר עם Loome באמצעות טופס יצירת קשר באתר האינטרנט של החברה.

**הוספות נוספות שניתן לשקול לכלול במדיניות הפרטיות**

* מידע לגבי שימושים ספציפיים במידע האישי, כגון שיווק ממוקד.
* מידע לגבי האופן שבו Loome משתמשת בנתוני סטטיסטיקה על השימוש באפליקציה.
* מידע לגבי אמצעי האבטחה ש-Loome מיישמת כדי להגן על המידע האישי של המשתמשים.
* מידע לגבי זכויות המשתמשים במדינות מסוימות, כגון האיחוד האירופי.

הנוסח הספציפי של ההוספות הללו תלוי במדינת היעד של האפליקציה ובחוקי הפרטיות המקומיים.
`


function PrivacyPolicy({}){

    return(
        <>
        <div style={{overflow: 'auto' , height: window.innerHeight , margin: 10}}>
            <Box dir='rtl'>
                <Markdown>{pphe}</Markdown>
            </Box>
            <Box dir='ltr'>
                <Markdown>{ppen}</Markdown>
            </Box>
        </div>
        </>
    );
}

export default PrivacyPolicy;


