import { useState , useEffect } from "react";
import { Link } from "react-router-dom";
import theme from './theme';

// MUI
import { Avatar, BottomNavigation , BottomNavigationAction, Button, Card } from "@mui/material";

// MUI Icons
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

import CameraIcon from '@mui/icons-material/Camera';
import CameraOutlinedIcon from '@mui/icons-material/CameraOutlined';

import AccountBadge from "./costum_badge/AccountBadge";
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';


import ChecklistIcon from '@mui/icons-material/Checklist';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';


import CartBadge from "./costum_badge/CartBadge";
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';

import { useLang } from "./lang";
import { useAuth } from "./auth";

import AccountDialog from './dialog/AccountDialog';
import DynamicLogo from './avatar/DynamicLogo';
import AccountAvatar from "./avatar/AccountAvatar";
import StoreSession from "./dialog/StoreSession";
import PortalComponent from './dialog/PortalComponent';
import FullScreenDialog from "./dialog/FullScreenDialog";
import YouTube from "react-youtube";


export const MuiBottomNavigation = ({screenVal , setScreenVal}) => {

    const lang = useLang();
    const auth = useAuth();
    const [openAccount, setOpenAccount] = useState(false);
    const [componentWidth, setComponentWidth] = useState(window.innerWidth * 0.9);

    useEffect(() => {
        const handleResize = () => {
            setComponentWidth(window.innerWidth * 0.9);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    const buttonWidthPercentage = 100 / 5; // Assuming there are 5 buttons
    const smallButtonSx = {width: 0.09 * componentWidth , maxWidth: 35}


    const opts = {
        height: window.innerHeight,
        width: window.innerWidth,
        playerVars: {
            autoplay: 1,
        },
    };

    return(
        <BottomNavigation
            id="main-navbar"
            sx={{
                width: `${componentWidth}px`, // Use componentWidth to set the width dynamically
                height: '8%',
                position: 'absolute',
                bottom: 0,
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: 200,
                borderTop: `1px solid ${theme.palette.secondary.main}`,
                borderRadius: 20,
                backgroundColor: 'rgba(255,255,255,0.95)',
                mb: '30px',
                display: 'flex',
                // justifyContent: 'space-evenly', // Adjust spacing between icons
                alignItems: 'center',
            }}
            value={screenVal}
            onChange={(event, newValue) => {
                setScreenVal(newValue);
            }}
        >

            

            {/* This is causing the value of the next one to be the second */}
            <AccountDialog open={openAccount} setOpen={setOpenAccount}/>


            {/* The value of this became 1 */}
            <BottomNavigationAction 
            disableRipple
            sx={{
                minWidth: '15px'
            }}
            // label={lang.t('navbar.account')}
            icon={
                <Button
                        disableRipple
                        onClick={() => {
                            setOpenAccount(!openAccount);
                        }}
                        sx={{
                            width: `${buttonWidthPercentage}%`, // Set button width dynamically
                            flex: 1, // Allow the button to grow and shrink
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                    <AccountBadge>
                        <AccountAvatar imageSize={0.098 * componentWidth}/>
                    </AccountBadge>
                </Button>
            }
            />


            <BottomNavigationAction 
            disableRipple
            sx={{
                minWidth: '15px'
            }}
            // label={lang.t('navbar.tutorials')} 
            icon={
            <Link to={"tutorials/favourites"}>
                {
                    screenVal === 2
                    ? <ChecklistIcon color='black' sx={smallButtonSx}/>
                    : <ChecklistRtlIcon color="action" sx={smallButtonSx}/>
                }
                
                
                </Link>}
            />
            <BottomNavigationAction 
            sx={{
                minWidth: '15px'
            }}
            disableRipple label="" icon={<Link to={""}>
                <Card
                    elevation={10}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '100%',
                        width: 0.2 * componentWidth , maxWidth: 35, minWidth: 40,
                        height: 0.2 * componentWidth , maxHeight: 35, minHeight: 40,
                        backgroundColor: theme.palette.primary.main,
                    }}
                >
                    {
                        screenVal === 3
                        ? <CameraIcon style={{width: '100%' ,color:theme.palette.secondary.main}}/>
                        : <CameraOutlinedIcon style={{width: '100%' , color: theme.palette.secondary.main}}/>
                    }
                </Card>
            
            </Link>}/>

            <BottomNavigationAction 
            sx={{
                minWidth: '15px'
            }}
            disableRipple 
            // label={lang.t('navbar.cart')} 
            icon={
            <Link to={"cart"}>
                <CartBadge>
                    
                    {
                        screenVal === 4
                        ? <ShoppingBasketIcon  color="black" sx={smallButtonSx}/>
                        : <ShoppingBasketOutlinedIcon color="action" sx={smallButtonSx}/>
                    }
                   
                </CartBadge></Link>}/>


            <BottomNavigationAction 
            sx={{
                minWidth: '15px'
            }}
            disableRipple 
            // label={lang.t('navbar.home')} 
            icon={
            <Link to={"home"}>
                {
                    screenVal === 5
                    ? <DynamicLogo selected={true} size={0.09 * componentWidth}/>
                    : <DynamicLogo size={0.09 * componentWidth}/>
                }
                </Link>}/>
            

            <FullScreenDialog open={auth.signupVideoUp} setOpen={auth.setSignupVideoUp}>
                
                <YouTube  
                    videoId={'ycqWNGbSM3M'}
                    opts={opts}
                    // onPause={() => {setVideoState(true)}}
                    // onPlay={() => {setVideoState(false)}}
                    onEnd={() => {
                        auth.setSignupVideoUp(false);
                        auth.setInitScan(true);
                        }}
                />
            </FullScreenDialog>

        </BottomNavigation>
    )
}