import * as React from 'react';
import { useState } from 'react';
import Badge from '@mui/material/Badge';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import { useAuth } from '../auth';



export default function CouponBadge({disableBadge = false}) {

  const auth = useAuth();
  const [value , setValue] = useState(0);
  

  React.useEffect(() => {


    try{
      setValue(auth.authUser.record.coupon.length);
    }catch(error){
      console.log(error);
    }

  }, [])



  return (
    
    <>
      {
        (value === 0 || disableBadge)
        ?
        <CardGiftcardIcon />
        :
        <Badge color="primary" variant="dot">
          <CardGiftcardIcon />
        </Badge>
      }
    </>

  );
}