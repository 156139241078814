import { useEffect , useState } from "react";
import { useParams } from "react-router-dom";

// API
import { pbGetById } from "../../../lib/pbGet";

// MUI
import { Typography , Paper , List , ButtonGroup , Button, Avatar, ListItem, Divider} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';

// MUI Icons
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ChecklistIcon from '@mui/icons-material/Checklist';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

// Providers
import { useLang } from "../../lang";

import {InstructionCard, InstructionCardFromId} from "../../cards/InstructionCard";
import VideoAvatar from '../../avatar/VideoAvatar';
import StyledTypography from '../../text/StyledTypography';
import {CostumSubmitButton} from '../../buttons/CostumSubmitButton';


function Controls({ready , handleStartTutorial , handleMakeupPreview}){

    const lang = useLang();

    return(
        <div
        style={{
            position: 'absolute',
            height: 30,
            width: '100%',
            bottom: '20%',
            display: 'flex',
            justifyContent:'center',

        }}
    >
        <ButtonGroup variant="text" aria-label="text button group">
            <CostumSubmitButton
                loading={!ready}
                onClick={handleStartTutorial}
                startIcon={<ChecklistIcon/>}
            >
                <StyledTypography dir={lang.t('code.dir')} lng={lang.t('code.lng')}>
                    {lang.t('tutorial.startTutorial')}
                </StyledTypography>
            </CostumSubmitButton>
            <CostumSubmitButton
                loading={!ready}
                onClick={handleMakeupPreview}
                endIcon={<AutoAwesomeIcon/>}
            >
                <StyledTypography dir={lang.t('code.dir')} lng={lang.t('code.lng')}>
                    {lang.t('tutorial.preview')}
                </StyledTypography>
            </CostumSubmitButton>
        </ButtonGroup>
    </div>
    );
}


function ListItemSpacer(){
    return(
        <>
        <ListItem sx={{height: '20%'}}>
            
        </ListItem>
        <ListItem sx={{height: '20%'}}>
    
        </ListItem>
        <ListItem sx={{height: '20%'}}>
    
        </ListItem>
        <ListItem sx={{height: '20%'}}>
        </ListItem>
        </>
    )
}

function TutorialDescription({
    tutorialId , 
    // instructionRecordList , 
    setViewState ,
    ready,
    handleStartTutorial,
    handleMakeupPreview}) {

    const [tutorial , setTutorial] = useState(null);
    const lang = useLang();
    const params = useParams();

    const fetchTutorial = async () => {
        const record = await pbGetById('tutorials' , params.recordId);
        setTutorial(record);
    }

    useEffect(() => {

        fetchTutorial();


    } , [])

    return (
        <div style={{padding: 7}}>

            {
                tutorial &&
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            margin: 15
                        }}
                    >

                        <VideoAvatar imageSrc={tutorial.image_url}/>

                        <StyledTypography variant="body" dir='rtl' lng="he">
                            {lang.t('tutorial.headerInstruction')}
                        </StyledTypography>
                        


                    </div>
                </>
                
            }
            {
                tutorial &&
                <>
                <div
                    style={{
                        width: '100%',
                        height: window.innerHeight * 0.7,
                        overflow: 'auto',
                    }}
                >
                    
                    <List>
                        {tutorial.instruction.map((instructionId) => (
                            <InstructionCardFromId instructionId={instructionId}/>
                        ))}
                        <ListItemSpacer/>
                    </List>  
                </div>
                </>
                
            }

            <Controls
                ready={ready}
                handleMakeupPreview={handleMakeupPreview}
                handleStartTutorial={handleStartTutorial}
            />

        </div>
    );
}

export default TutorialDescription;


{/* <Paper style={{
                    maxHeight: window.innerHeight, 
                    overflow: 'auto' , 
                    marginBottom: '10%',
                    backgroundColor: 'rgba(0,0,0,0)',
                    }} elevation={0}>
                    
</Paper> */}