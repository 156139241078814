import React, { useEffect, useState } from "react";

// MUI
import { Card, CardContent, Typography, Box, Chip, Alert, Button, Skeleton, Divider } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ListItem from '@mui/material/ListItem';

import { useNavigate } from "react-router-dom";

import CouponBadge from "../costum_badge/CouponBadge";
import {CopyToClipboard} from "react-copy-to-clipboard";
import { pbGetById } from "../../lib/pbGet";
import { useLang } from "../lang";
import toast from "react-hot-toast";


function CouponCard({couponId}) {

    const [showAlert , setShowAlert] = useState(false);
    const [coupon , setCoupon] = useState(null);
    const lang = useLang();

    const handleClickCoupon = async () => {
        toast.success(lang.t('coupons.copiedToBoard') , {
            duration: 3000,
        });
    }

    const fetchCoupon = async () => {

        try{
            const couponRecord = await pbGetById('coupons' , couponId);
            setCoupon(couponRecord);

        }catch(error){
            console.log(error);
        }


    }

    useEffect(() => {
        fetchCoupon();
    } , [] )

    if (!coupon) {
        
        return (
          <ListItem alignItems="flex-start" dir="rtl">
            <Card variant="outlined" sx={{ borderRadius: 2, boxShadow: 4, mb: 2, mt: 2, ml: 1, mr: 1, width: "100%"}}>
              <CardContent>
                <div style={{display: 'flex' , flexDirection: 'row' , marginBottom: 4 , padding: 2}}>
                    <Skeleton variant="rounded" height={40} width="10%" sx={{mr: 2, ml: 2}}/>
                    <Skeleton variant="rounded" height={40} width="20%" />
                </div>
                <Divider></Divider>
                <Skeleton variant="rectangular" height={40} width="100%" />
                <Skeleton variant="text" height={20} width="80%"/>
                <Skeleton variant="text" height={20} width="60%" />
              </CardContent>
            </Card>
          </ListItem>
        );
    }


    return (
        <ListItem alignItems="flex-start" dir='rtl'>
            <Card variant="outlined" sx={{ borderRadius: 2, boxShadow: 4, mb: 2 , mt:2 , ml: 1 , mr: 1 , width: '100%'}}>
                <CardContent>
                    <Box sx={{display: 'flex' , flexDirection: 'row'}}>
                    <Box sx={{opacity: 0.4}}>
                        <CouponBadge disableBadge={true}/>
                    </Box>
                    <Chip label={coupon.plain_coupon} sx={{borderRadius: 0.7 , opacity: 0.8}}/>
                    
                    <Divider></Divider>
                    
                    </Box>
                    <Box sx={{display: "flex" , flexDirection: 'row' ,alignItems: 'center', pl: 1, pb: 1 , justifyContent: 'space-evenly'}}>
                    <Typography variant="h6" component="div" gutterBottom>
                    {coupon.title}
                    </Typography>
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                    {coupon.description}
                    </Typography>
                </CardContent>
            <CopyToClipboard text={coupon.plain_coupon} onCopy={handleClickCoupon}>
                <Button>
                    <ContentCopyIcon/>
                </Button>
            </CopyToClipboard>
            {
                showAlert &&
                <Alert severity="success" dir={lang.t('code.dir')}>
                    {lang.t('coupons.copiedToBoard')}
                </Alert>
            }
            </Card>
        </ListItem>
      );
}

export default CouponCard;