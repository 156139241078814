import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect, useState , useRef } from "react";

import { useAuth } from "../auth";

// API
import { pbGetAll , pbGetById } from "../../lib/pbGet";

// MUI
import { Button } from "@mui/material";

// Costum Components
import TutorialDescription from "./components/TutorialDescription";
import TutorialCameraComponent from "./components/TutorialCameraComponent";
import MakeupCamera from './components/MakeupCamera';
import FullScreenDialog from "../dialog/FullScreenDialog";
import ProductCard from "../cards/ProductCard";
import TutorialVideo from "./components/TutorialVideo";
import { LangProvider } from "../lang";
import ProductSummary from "./components/ProductSummary";
import CameraComponent from "../camera/CameraComponent";
import SmartCamera from "../camera/SmartCamera";


function DressingRoom({setShowNavbar}) {
    
    const viewStates = {
        preview: "preview",
        finishedTutorial: "finishedTutorial",
        tutorialCamera: "tutorialCamera",
        tutorialDescription: "tutorialDescription"
    }

    const navigate = useNavigate();
    const params = useParams();
    const auth = useAuth();
    const [tutorial , setTutorial] = useState(null);
    const [instructionRecordList , setInstructionRecordList] = useState([]);
    const [viewState , setViewState] = useState(viewStates.tutorialDescription);
    const [showCanvas , setShowCanvas] = useState("none");

    const [curInstructionIndex , setCurInstructionIndex ] = useState(0);
    const [curInstruction , setCurInstruction] = useState(null);
    const [faceAreaNameList , setFaceAreaNameList] = useState([]);
    const [showInstruction , setShowInstruction] = useState(true);
    const [isLastInstruction , setIsLastInstruction] = useState(false);
    const [isFirstInstruction , setIsFirstInstruction] = useState(true);
    const [finishedTutorial , setFinishedTutorial] = useState(false);

    const [openMakeupCamera , setOpenMakeupCamera] = useState(false);
    const [openTutorialVideo , setOpenTutorialVideo] = useState(false);

    const curTimeRef = useRef();
    const curIndexRef = useRef();
    const [curTime , setCurTime] = useState(null);


    const fetchInstructions = async () => {

        const instructionList = []

        // const tutorialData = await pb.collection('tutorials').getOne(params.recordId);
        const tutorialObj = await pbGetById('tutorials' , params.recordId);
        setTutorial(tutorialObj);


        for( let i = 0 ; i < tutorialObj.instruction.length ; i++){

            const curFaceAreasNameList = [];
            const productFaceAreasNameList = [];
            const instructionRecord = await pbGetById('instructions' , tutorialObj.instruction[i]);
            

            for(let j=0 ; j < instructionRecord.face_areas.length ; j++){
                const faceAreaRecord = await pbGetById('face_areas' , instructionRecord.face_areas[j]);
                curFaceAreasNameList.push(faceAreaRecord.name);
            }

            const curProductRecord = await pbGetById('products', instructionRecord.product);
            

            for(let j=0 ; j < curProductRecord.face_areas.length ; j++){
                const faceAreaRecord = await pbGetById('face_areas' , curProductRecord.face_areas[j]);
                productFaceAreasNameList.push(faceAreaRecord.name);
            }

            curProductRecord.faceAreasNameList = productFaceAreasNameList;

            instructionList.push({...instructionRecord , productRecord : curProductRecord , faceAreasNameList: curFaceAreasNameList});
        }

        setInstructionRecordList(instructionList);
        setFaceAreaNameList(instructionList[0].faceAreasNameList);
        setCurInstruction(instructionList[0]);


    }
    useEffect(() => {
    
        fetchInstructions();

    }, [])

    return (
        <div>


            {/* Debug Text */}
            <div style={{position: 'absolute' , top: 100 , left : 20 , zIndex: 10 , opacity: 0}}>
                <p ref={curTimeRef} style={{color: 'white'}}>{Math.round(curTime)}</p>
                <p ref={curIndexRef} style={{color: 'white'}}>{curInstructionIndex}</p>
            </div>
            {/* End Debug Text */}

            {/* Tutorial Video */}
            {   
                (tutorial && !finishedTutorial) &&
                <TutorialVideo 
                open={openTutorialVideo} 
                setOpen={setOpenTutorialVideo} 
                videoId={tutorial.youtube_video_id}
                title={tutorial.title}
                tutorial={tutorial}
                viewState={viewState}
                setViewState={setViewState}
                viewStates={viewStates}
                showInstruction={showInstruction}
                setShowInstruction={setShowInstruction}
                curInstruction={curInstruction}
                setCurInstruction={setCurInstruction}
                curInstructionIndex={curInstructionIndex}
                setCurInstructionIndex={setCurInstructionIndex}
                isLastInstruction={isLastInstruction}
                setIsLastInstruction={setIsLastInstruction}
                isFirstInstruction={isFirstInstruction}
                setIsFirstInstruction={setIsFirstInstruction}
                setFaceAreaNameList={setFaceAreaNameList}
                instructionRecordList={instructionRecordList}
                showCanvas={showCanvas}
                faceAreaNameList={faceAreaNameList}
                setShowNavbar={setShowNavbar}
                curTime={curTime}
                setCurTime={setCurTime}
                curIndexRef={curIndexRef}
                curTimeRef={curTimeRef}
                onFinishTutorial={() => {
                    setViewState(viewStates.finishedTutorial);
                    setFinishedTutorial(true);
                    }}
                >

                <TutorialCameraComponent
                    tutorial={tutorial}
                    viewState={viewState}
                    setViewState={setViewState}
                    viewStates={viewStates}
                    showInstruction={showInstruction}
                    setShowInstruction={setShowInstruction}
                    curInstruction={curInstruction}
                    setCurInstruction={setCurInstruction}
                    curInstructionIndex={curInstructionIndex}
                    setCurInstructionIndex={setCurInstructionIndex}
                    isLastInstruction={isLastInstruction}
                    setIsLastInstruction={setIsLastInstruction}
                    isFirstInstruction={isFirstInstruction}
                    setIsFirstInstruction={setIsFirstInstruction}
                    showCanvas={showCanvas}
                    setFaceAreaNameList={setFaceAreaNameList}
                    faceAreaNameList={faceAreaNameList}

                    instructionRecordList={instructionRecordList}
                    curIndexRef={curIndexRef}

                    setShowNavbar={setShowNavbar}
                    onFinishTutorial={() => {
                        setViewState(viewStates.finishedTutorial);
                        setFinishedTutorial(true);
                        }}
                />

                </TutorialVideo>
            }
            {/* End Tutorial Video */}


            {/* Finish Tutorial Product Summary */}
            <ProductSummary
                instructionRecordList={instructionRecordList}
                setShowNavbar={setShowNavbar}
                open={finishedTutorial} 
                setOpen={setFinishedTutorial}
            />
            {/* End Finish Tutorial Product Summary */}

            {/* Makeup Camera */}
            <FullScreenDialog
                open={openMakeupCamera}
                setOpen={setOpenMakeupCamera}
            >
                {
                    openMakeupCamera &&
                    <MakeupCamera 
                        instructionRecordList={instructionRecordList}
                    />
                }
            </FullScreenDialog>
            {/* End Makeup Camera */}
            


            {/* Tutorial Description */}
            {
                viewState === viewStates.tutorialDescription &&

                <TutorialDescription
                    tutorial={tutorial}
                    ready={faceAreaNameList.length}
                    // instructionRecordList={instructionRecordList} 
                    handleStartTutorial={() => {setOpenTutorialVideo(true)}}
                    handleMakeupPreview={() => {setOpenMakeupCamera(true)}}
                />
            }
            {/* End Tutorial Description */}

        </div>

    );
}

export default DressingRoom;