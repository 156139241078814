

const InstructionScreen = () => {

    return(
        <div>
            <h1>Instruction</h1>
        </div>
    )


}

export default InstructionScreen;