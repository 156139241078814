import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IconButton, MenuItem, Select } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CalendarIcon from "@mui/icons-material/Today"; // Replace with the actual Material-UI calendar icon
import StyledTypography from "../text/StyledTypography";

function CustomDatePicker({ selectedDate, setSelectedDate }) {
  
  const handleChangeMonth = (date, event) => {
    const newDate = new Date(date);
    newDate.setMonth(parseInt(event.target.value, 10));
    setSelectedDate(newDate);
  };

  const handleChangeYear = (date, event) => {
    const newDate = new Date(date);
    newDate.setFullYear(parseInt(event.target.value, 10));
    setSelectedDate(newDate);
  };

  const CustomInput = ({ value, onClick }) => (
    <div className="custom-input-container">
      <IconButton className="custom-input-icon" onClick={onClick}>
        <CalendarIcon />
      </IconButton>
      <StyledTypography>
        {value}
      </StyledTypography>
    </div>
  );

  return (
    <DatePicker
      selected={selectedDate}
      onChange={(date) => setSelectedDate(date)}
      customInput={<CustomInput />}
      dateFormat="dd/MM/yyyy"
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      // renderCustomHeader={({ date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
      //   <div>
      //     <IconButton onClick={() => decreaseMonth()} disabled={prevMonthButtonDisabled}>
      //       <RemoveIcon />
      //     </IconButton>
      //     <Select value={date.getMonth()} onChange={(e) => handleChangeMonth(date, e)}>
      //       {Array.from({ length: 12 }, (_, i) => (
      //         <MenuItem key={i} value={i}>
      //           {new Date(0, i).toLocaleDateString(undefined, { month: "long" })}
      //         </MenuItem>
      //       ))}
      //     </Select>
      //     <Select value={date.getFullYear()} onChange={(e) => handleChangeYear(date, e)}>
      //       {Array.from({ length: 50 }, (_, i) => (
      //         <MenuItem key={i} value={date.getFullYear() - 25 + i}>
      //           {date.getFullYear() - 25 + i}
      //         </MenuItem>
      //       ))}
      //     </Select>
      //     <IconButton onClick={() => increaseMonth()} disabled={nextMonthButtonDisabled}>
      //       <AddIcon />
      //     </IconButton>
      //   </div>
      // )}
    />
  );
}

export default CustomDatePicker;
