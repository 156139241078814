import { createContext , useContext, useState } from 'react';
import pb from '../lib/pocketbase';

import { useNavigate } from 'react-router-dom';

const AuthContext = createContext(null);

export function decodeJWT(jwt) {
    // Split the JWT into header, payload, and signature
    const parts = jwt.split('.');
    const encodedPayload = parts[1];
  
    // Decode the base64-encoded payload
    const decodedPayload = atob(encodedPayload);
  
    // Parse the JSON payload
    const jsonPayload = JSON.parse(decodedPayload);
  
    return jsonPayload;
}


export function useAuth(){
    return useContext(AuthContext);
}

export function AuthProvider(props){

    const [authUser , setAuthUser] = useState(null);
    const navigate = useNavigate();
    const [signupVideoUp , setSignupVideoUp] = useState(false);
    const [initScan , setInitScan] = useState(false);
    
    const login = async (user) => {
        try{
            
            console.log("Sending auth request");
            const authData = await pb.collection('users').authWithPassword(
                user.email,
                user.password,
            );

            
            setAuthUser(authData);
            return true;
        }catch(err){
            console.log("Login Error: " , err);
            return false;
        }
    }

    const loginWithFacebook = async (facebookUser) => {


        try{
            const isLogedIn = await login({email: facebookUser.email , password: facebookUser.id})

            if(!isLogedIn){
                // Try signup
                const userData = {
                    "username": '',
                    "email": facebookUser.email,
                    "emailVisibility": true,
                    "password": facebookUser.id,
                    "passwordConfirm": facebookUser.id,
                    "name": '',
                    "coupon": [],
                    "birth_date": "2022-01-01 10:00:00.123Z",
                    "face_type": 1,
                    "brow_type": 1,
                    "eye_type": 1,
                    "locale": !facebookUser.locale ? "he" : facebookUser.locale,
                    "facebook_sub": facebookUser.id,
                    "facebook_picture": facebookUser.picture,
                };
        
                const response = await signup(userData);
                return response;
            }
        }catch(error){
            console.log(error);
            return error;
        }

    }

    const loginWithGoogle = async (googleUser) => {

        try{
            if(!googleUser.email){
                throw "Login failed. Could not find email information"
            }

            const isLogedIn = await login({email: googleUser.email , password: googleUser.sub})


            if(!isLogedIn){
                // Try signup
                const userData = {
                    "username": googleUser.given_name,
                    "email": googleUser.email,
                    "emailVisibility": true,
                    "password": googleUser.sub,
                    "passwordConfirm": googleUser.sub,
                    "name": googleUser.name,
                    "coupon": [],
                    "birth_date": "2022-01-01 10:00:00.123Z",
                    "face_type": 1,
                    "brow_type": 1,
                    "eye_type": 1,
                    "locale": googleUser.locale,
                    "google_sub": googleUser.sub,
                    "google_picture": googleUser.picture,
                };
        
                const response = await signup(userData);
                return response;
            }
        }catch(error){
            console.error(error);
            return error;
        }

    }
    
    const authRefresh = async () => {
        
        try{
            const authData = await pb.collection('users').authRefresh();
            if (pb.authStore.isValid){
                setAuthUser(authData);
            }
        }catch(error){
            console.log(error);
        }

    }

    const logout = () => {
        pb.authStore.clear()
        setAuthUser(null)
    }

    const signup = async (user) => {
        
        const record = await pb.collection('users').create(user);

        try{
            const loginResponse = await login({email: record.email , password: user.password});
            if(loginResponse){
                navigate(`/account/personal-information`);
                setSignupVideoUp(true);
            }            
            return true
        }catch(error){
            console.log(error);
            return false
        }
    }

    const requestResetPassword = async (email) => {
        
        if(!email){
            try{
                if(!authUser){
                    console.warn("No connected user");
                    return;
                }
                email = authUser.record.email;
            }catch(error){
                console.log(error);
            }
        }


        try{
            const response = await pb.collection('users').requestPasswordReset(email);
            return response
        }catch(error){
            console.log(error);
            return false;
        }

    }

    const resetPassword = async (token , newPassword , newPasswordConfirm) => {

        try{
            const response = await pb.collection('users').confirmPasswordReset(
                token,
                newPassword,
                newPasswordConfirm,
            );
            return response;
        }catch(error){
            console.log(error);
            return false;
        }

    }

    return <AuthContext.Provider value={{
        authUser , 
        login , 
        loginWithGoogle , 
        loginWithFacebook , 
        authRefresh , 
        logout , 
        signup ,
        requestResetPassword , 
        resetPassword ,
        signupVideoUp,
        setSignupVideoUp,
        initScan , 
        setInitScan,
        }}>
        {props.children}
    </AuthContext.Provider>
}

