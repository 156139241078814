import { Button } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';

import theme from "../theme";

function SignoutButton({iconSize = "9%" , callback = () => {}}) {

    return ( 
        <Button variant='contained' style={{
            backgroundColor: theme.palette.secondary.main + 'D9',
            marginTop: '20%',
            marginBottom: '10%',
            }} 
            fullWidth 
            onClick={callback}>
            <LogoutIcon style={{
                width: iconSize , 
                height: iconSize , 
                maxWidth: '30px',
                color: theme.palette.black.main
                }}/>
        </Button>
    );
}

export default SignoutButton;{}