import { Button } from "@mui/material";
import LanguageIcon from '@mui/icons-material/Language';

import { useLang } from "../lang";

function ChangeLangGlobeButton() {

    const lang = useLang();


    return ( 
        <div style={{width: '100%' , display: 'flex' , justifyContent: 'center'}}>
          <Button disableRipple onClick={() => {lang.changeLanguage(lang.lng === 'he' ? 'en' : 'he')}}><LanguageIcon/></Button>
        </div>

    );
}

export default ChangeLangGlobeButton;