import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

// Auth Providers


// API
import pb from '../lib/pocketbase';

// MUI
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// MUI Icons
import LanguageIcon from '@mui/icons-material/Language';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { useAuth } from './auth';
import { useNavigate } from 'react-router-dom';
import { LoomeLogoAvatar } from './avatar/LoomeLogoAvatar';
import LoomeLogoBackdrop from './backdrop/LoomeLogoBackdrop';
import FullScreenDialog from './dialog/FullScreenDialog';
import ChangePasswordDialog from './dialog/ChangePasswordDialog';
import Signup from './SignUp';
import { Alert, Card } from '@mui/material';
import { useLang } from './lang';
import SignWithAutoProviders from './auth_providers/SignWithAutoProviders';

// MUI ICONS
import EastRoundedIcon from '@mui/icons-material/EastRounded';

import StyledTypography from './text/StyledTypography';
import WavesAnimation from './animations/waves/WavesAnimation';
import theme from './theme';
import ChangeLangGlobeButton from './buttons/ChangeLangGlobeButton';
import { CostumPassTextField, CostumTextField } from './text_field/CostumTextField';
import { CostumSubmitButton } from './buttons/CostumSubmitButton';


export default function SignIn() {

  const auth = useAuth();
  const lang = useLang();
  const navigate = useNavigate();
  const [loading , setLoading] = useState(true);
  const [openForgot , setOpenForgot] = useState(false);
  const [openSignup , setOpenSignup] = useState(false);
  const [showPassword , setShowPassword] = useState(false);

  const [email , setEmail] = useState('');
  const [emailValid , setEmailValid] = useState(false);
  const [password , setPassword] = useState('');
  const [passwordValid , setPasswordValid] = useState(false);

  const [validationError , setValidationError] = useState(null);

  const validateEmail = (value) => {
    // Regular expression for a simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    // Check if the email matches the regex
    const isValidEmail = emailRegex.test(value);
  
    // Set the email valid state based on the result
    setEmailValid(isValidEmail);
    setEmail(value);

    if(!isValidEmail){
      setValidationError(lang.t('signin.emailNotValidMsg'));
    }else{
      setValidationError(null);
    }
  }
  
  const validatePassword = (value) => {
    // Minimum password length requirement, you can customize this as needed
    const minLength = 8;
  
    // Check if the password meets the minimum length requirement
    const isValidPassword = value.length >= minLength;
  
    // Set the password valid state based on the result
    setPasswordValid(isValidPassword);
    setPassword(value);

    if(!isValidPassword){
      setValidationError(lang.t('signin.passwordNotValidMsg'));
    }else{
      setValidationError(null);
    }
  
  }
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (emailValid && passwordValid){
      const data = new FormData(event.currentTarget);
      const userCreds = {
        email: data.get('email').toLocaleLowerCase(),
        password: data.get('password'),
      }
      const response = await auth.login(userCreds);
      if (!response){
        setValidationError(lang.t("signin.signinError"))
      }
    }

    setLoading(false);
  };

  useEffect(() => { 
    const authRefresh = async () => {
      // check if the user is still valid after going out
      setLoading(true)
      await auth.authRefresh();
      setLoading(false);
    }

    authRefresh();

  } , [])

  return (
      
      <>
        
      {/* Top bar with red background */}
      <div style={{ width: '100%', height: '7%', background: theme.palette.primary.main, position: 'fixed', top: 0, zIndex: 100 }}>
        {/* Add any content you want in the top bar here */}
      </div>

      <Container component="main" maxWidth="xs" style={{ marginTop: '50px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

        {/* <WavesAnimation/> */}

        <ChangePasswordDialog 
          open={openForgot} 
          setOpen={setOpenForgot} 
          title={lang.t('signin.forgotPasswordMsg')}
        >
        </ChangePasswordDialog>
        <FullScreenDialog open={openSignup} setOpen={setOpenSignup}>
          <Signup/>
        </FullScreenDialog>
        <CssBaseline />
        <LoomeLogoBackdrop loading={loading}/>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

          {/* <LoomeLogoAvatar logoSize={80} path='./images/logoTransperent.png' elevation={0}/> */}
          


          <div style={{zIndex: 2}}>
            <StyledTypography variant="h5" dir={lang.t('code.dir')} lng={lang.t('code.lng')}>
              {lang.t("signin.title")}
            </StyledTypography>
          </div>

          
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 , width: "100%" }}>
            <Box sx={{ width: '70%', margin: '0 auto', textAlign: 'center' }}>

            <CostumTextField
              // dir={lang.t('code.dir')}
              value={email}
              setValue={setEmail}
              id="email"
              // label={lang.t('general.email')}
              name="email"
              autoComplete="email"
              onChange={(event)=> {validateEmail(event.target.value)}}
              autoFocus
            />

            <CostumPassTextField
              dir={'ltr'}
              name="password"
              value={password}
              setValue={setPassword}
              // label={lang.t('general.password')}
              id="password"
              onChange={(event) => validatePassword(event.target.value)}

            />
  
            <CostumSubmitButton
              loading={loading}
              disabled={!emailValid || !passwordValid}
            >
              <EastRoundedIcon style={{ fontSize: 50 }} />
            </CostumSubmitButton>


            
            </Box>
            {/* Signup and Forgot buttons */}
            <Box sx={{ display: 'flex', width: '90%', justifyContent: 'space-between' , margin: '0 auto', textAlign: 'center'}}>
              <Button onClick={() => {setOpenForgot(true); setValidationError(null);}}>
                {lang.t("signin.forgotPasswordMsg")}
              </Button>
              <Button onClick={() => {setOpenSignup(true); setValidationError(null);}}>
                {lang.t("signin.noAccountMsg")}
              </Button>
            </Box>
            
            <Box sx={{ width: '70%', margin: '0 auto', textAlign: 'center' }}>
              <Divider sx={{mt: 5 , mb: 2,  borderBottomWidth: '3px' , borderBottomColor: theme.palette.primary.main , width: '100%'}} />
            </Box>
          
          
          </Box>
        </Box>


        {
          validationError &&
          <Alert dir={lang.t('code.dir')} severity="warning">{validationError}</Alert>
        }

        
        {/* External Auth Providers */}
        <SignWithAutoProviders loading={loading} setLoading={setLoading}/>
        {/* End External Auth Providers */}


      </Container>

      </>
  );
}