import React, { useEffect, useState } from "react";
import TutorialCard from "../cards/TutorialCard";
import pb from '../../lib/pocketbase';
import { pbGetAll, pbGetById } from "../../lib/pbGet";

import ShaderCanvas from '../canvas/ShaderCanvas';
import LoomeLogoBackdrop from '../backdrop/LoomeLogoBackdrop';
import FilterBar from "../filter/FilterBar";
import VideoPlayer from "../videoplayer/VideoPlayer";
import { useParams } from "react-router-dom";
import { Button, Typography } from "@mui/material";

import HalfScreenDialog from '../dialog/HalfScreenDialog';
import AccountDialog from '../dialog/AccountDialog';


const TutorialScreen = () => {

    const [tutorialTypeList , setTutorialTypeList] = useState([]);
    const [loading , setLoading] = useState(false);
    const params = useParams();
    
    const fetchTurorialType = async () => {
        setLoading(true);
        const qid = params.tutorialTypeId === "favourites" ? '7nzujcb46euyga2' : params.tutorialTypeId
        try{
            const tutorialTypeRecord = await pbGetById('tutorial_types' , qid)
            setTutorialTypeList([tutorialTypeRecord])
        }catch(error){
            console.log(error);
        }finally{
            setLoading(false);
        }
    }

    useEffect(() => {
        if (params.tutorialTypeId) {
            fetchTurorialType();
        }
    }, [params.tutorialTypeId]);
    

    if(tutorialTypeList.length === 0 ){
        return null;
    }

    return (
        <div
            style={{
                height: window.innerHeight * 0.9,
                overflow: 'auto'
            }}
        >
            {/* <FilterBar/> */}
            <LoomeLogoBackdrop loading={loading}/>
            
           {
            tutorialTypeList.map((tutorialType) => (

                tutorialType.tutorials.map((tutorialId) => ((
                    <TutorialCard key={tutorialId} tutorialId={tutorialId} />
                )
                ))  
            ))
           }

        </div>
      );
}

export default TutorialScreen;