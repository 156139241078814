import * as React from 'react';
import ReactCountryFlag from "react-country-flag"
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

// API
import { endpoint, pbGetImage } from '../../lib/pbGet';
import pb from '../../lib/pocketbase';

// MUI
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Avatar, Divider , Box, Grid, Stack, Typography, Paper , Card, Button, ButtonGroup } from '@mui/material';

// MUI Icons
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import KeyIcon from '@mui/icons-material/Key';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import CouponBadge from '../costum_badge/CouponBadge';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import SendIcon from '@mui/icons-material/Send';
import PermDeviceInformationIcon from '@mui/icons-material/PermDeviceInformation';
import LanguageIcon from '@mui/icons-material/Language';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import CameraEnhanceIcon from '@mui/icons-material/CameraEnhance';


import CouponsScreen from './CouponsScreen';


import version from '../../version';

// Custom Providers
import { useAuth } from '../auth';
import { useLang } from '../lang';

import AccountAvatar from '../avatar/AccountAvatar';
import AlertDialogSlide from '../dialog/AlertDialogSlide';
import ChangePasswordDialog from '../dialog/ChangePasswordDialog';
import SuggestionsDialog from '../dialog/SuggestionsDialog';
import StyledTypography from '../text/StyledTypography';
import toast from 'react-hot-toast';
import FullScreenDialog from '../dialog/FullScreenDialog';
import UserCard from '../cards/UserCard';
import SignoutButton from '../buttons/SignoutButton';
import theme from '../theme';
import ChangeLangButton from '../buttons/ChangeLangButton';
import CameraComponent from '../camera/CameraComponent';



const imageSize = 150;

export default function AccountScreen({}) {

  const auth = useAuth();
  const lang = useLang();
  const [openInbox , setOpenInbox] = React.useState(false);
  const navigate = useNavigate();
  const [openEditAccount , setOpenEditAccount] = React.useState(false);
  const [direction , SetDirection] = useState('rtl');
  
  
  const [openSignoutAlertDialog , setOpenSignoutAlertDialog] = useState(false);
  const [openDeleteAccountDialog , setOpenDeleteAccountDialog] = useState(false);
  const [openChangePasswordDialog , setOpenChangePasswordDialog] = useState(false);
  const [openChangeSuggestDialog , setOpenChangeSuggestDialog] = useState(false);
  const [openAppVersionDialog , setOpenAppVersionDialog] = useState(false);
  const [openCouponsDialog , setOpenCouponsDialog] = useState(false);
  const [openDebugCamera , setOpenDebugCamera] = useState(false);
  const indexRef = React.useRef();

  // const handleClickInbox = () => {
  //   setOpenInbox(!openInbox);
  // };

  const handleClickEditAccount = () => {
    setOpenEditAccount(!openEditAccount);
  };

  const handleDeleteAccount = async () => {

    const response = await pb.collection('users').delete(auth.authUser.record.id);
    if(response){
      handleLogout();
    }
  
  }


  const handleLogout = () => {
    auth.logout();
    navigate('/');
  }

  return (

    <div style={{height: window.innerHeight , width: '100%'}}>

      <UserCard imageSize={imageSize}/>

    <div
      style={{
        height: '70%'
      }}
    >

    <Card
      elevation={5}
      style={{
        borderRadius: 20,
        backgroundColor: theme.palette.secondary.main + 'AA'
      }}
    >

    
    <List
      dir={lang.t('code.dir')}
      sx={{ width: '100%' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
        </ListSubheader>
      }
    >

      {/* Coupons */}
      <ListItemButton onClick={() => {setOpenCouponsDialog(true)}} dir={lang.t('code.dir')}>
        <ListItemIcon>
          <CouponBadge/>
        </ListItemIcon>
        <StyledTypography lng={lang.t('code.lng')} dir={lang.t('code.dir')}>{lang.t('account.myCoupons')}</StyledTypography>  
      </ListItemButton>

      {/* Send Support Mail */}
      <a href={`mailto:loome.sup@gmail.com?subject=${lang.t('account.needHelpSubject')}`} style={{color: 'black' , textDecoration: "none"}}>
      <ListItemButton>
        <ListItemIcon>
          <SendIcon />
        </ListItemIcon>
        <StyledTypography lng={lang.t('code.lng')} dir={lang.t('code.dir')}>{lang.t('account.sendEmail')}</StyledTypography>
        {/* <ListItemText primary="Send mail" /> */}
      </ListItemButton>
      </a>


      <ListItemButton onClick={handleClickEditAccount}>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <StyledTypography lng={lang.t('code.lng')} dir={lang.t('code.dir')}>{lang.t('account.editAccount')}</StyledTypography>
        <ListItemText primary=""/>
        {openEditAccount ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openEditAccount} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 , pr: 4 }} onClick={() => {navigate('/account/personal-information')}}>
            <ListItemIcon>
              <PermContactCalendarIcon />
            </ListItemIcon>
            <StyledTypography lng={lang.t('code.lng')} dir={lang.t('code.dir')}>{lang.t('account.personalInfo')}</StyledTypography>
            <ListItemText primary=""/>
          </ListItemButton>
        </List>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 , pr: 4 }} onClick={() => {setOpenChangePasswordDialog(true)}}>
            <ListItemIcon>
              <KeyIcon />
            </ListItemIcon>
            <StyledTypography lng={lang.t('code.lng')} dir={lang.t('code.dir')}>{lang.t('account.changePassword')}</StyledTypography>
            <ListItemText primary=""/>
          </ListItemButton>
        </List>

        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 , pr: 4 , color: 'red'}} onClick={() => {setOpenDeleteAccountDialog(true)}}>
            <ListItemIcon>
              <PersonRemoveIcon sx={{color: 'red'}} />
            </ListItemIcon>
            <StyledTypography lng={lang.t('code.lng')} dir={lang.t('code.dir')}>{lang.t('account.deleteAccount')}</StyledTypography>
            <ListItemText primary=""/>
          </ListItemButton>
        </List>
      </Collapse>

      {/* <ListItemButton onClick={() => {setOpenChangeSuggestDialog(true)}}>
        <ListItemIcon>
          <MoveToInboxIcon />
        </ListItemIcon>
        <StyledTypography lng={lang.t('code.lng')} dir={lang.t('code.dir')}>{lang.t('account.suggestionBox')}</StyledTypography>
        <ListItemText primary="" />
      </ListItemButton> */}

      {
        (auth.authUser.record.is_super) &&
        <>

          {/* App Version */}
          <ListItemButton onClick={() => {
            // setOpenAppVersionDialog(true);
            toast(version , {duration: 5000})
          }}>
            <ListItemIcon>
              <PermDeviceInformationIcon/>
            </ListItemIcon>
            <StyledTypography lng={lang.t('code.lng')} dir={lang.t('code.dir')}>{lang.t('account.version')}</StyledTypography>
            <ListItemText primary=""/>
          </ListItemButton>
          
          {/* Admin Panel */}
          <ListItemButton onClick={() => {navigate('/_')}}>
            <ListItemIcon>
              <AdminPanelSettingsIcon/>
            </ListItemIcon>
            <StyledTypography lng={lang.t('code.lng')} dir={lang.t('code.dir')}>{lang.t('general.admin')}</StyledTypography>
            <ListItemText primary=""/>
          </ListItemButton>

          {/* Debug Camera */}
          <ListItemButton onClick={() => {setOpenDebugCamera(true)}}>
            <ListItemIcon>
              <CameraEnhanceIcon/>
            </ListItemIcon>
            <StyledTypography lng={lang.t('code.lng')} dir={lang.t('code.dir')}>{lang.t('general.debugCam')}</StyledTypography>
            <ListItemText primary=""/>
          </ListItemButton>

        </>

      }

    </List>
    </Card>
    

    {
      lang.langs.map((l) => ((
        <ChangeLangButton languageInfo={l}/>
      )))
    }
    
    <SignoutButton callback={() => {setOpenSignoutAlertDialog(true)}}/>
    
    </div>

    {/* Coupon Dialog */}
    <FullScreenDialog
      open={openCouponsDialog}
      setOpen={setOpenCouponsDialog}
    >
      <CouponsScreen/>
    </FullScreenDialog>
    
    {/* Debug Camera Dialog */}
    <FullScreenDialog
      open={openDebugCamera}
      setOpen={setOpenDebugCamera}
    > 
      
      <CameraComponent showCanvas={true} faceAreaNameList={['rightBrowSection']} showInstruction={true} debug={true} curIndexRef={indexRef}/>
    </FullScreenDialog>

    <SuggestionsDialog
      open={openChangeSuggestDialog}
      setOpen={setOpenChangeSuggestDialog}
    />

    <AlertDialogSlide 
    title={lang.t('account.messages.signoutMsg')}
    subTitle={""}
    cancelButtonLabel={lang.t('account.messages.singoutCancel')}
    approveButtonLabel={lang.t('account.messages.signoutApprove')}
    open={openSignoutAlertDialog} 
    setOpen={(setOpenSignoutAlertDialog)}
    onApprove={handleLogout}
    />

    <AlertDialogSlide 
      title={lang.t('account.version')}
      subTitle={version}
      open={openAppVersionDialog} 
      setOpen={(setOpenAppVersionDialog)} 
    />
    
    <AlertDialogSlide 
    title={lang.t('account.messages.deleteAccountTitle')}
    subTitle={lang.t('account.messages.deleteAccountMsg')}
    cancelButtonLabel={lang.t('account.messages.deleteCancel')}
    approveButtonLabel={lang.t('account.messages.deleteApprove')}
    open={openDeleteAccountDialog}
    setOpen={(setOpenDeleteAccountDialog)} 
    onApprove={handleDeleteAccount}
    />

    <ChangePasswordDialog
      open={openChangePasswordDialog}
      setOpen={setOpenChangePasswordDialog}
      title={lang.t('signin.forgotPasswordMsg')}
      saveButtonLabel=''
    />

    
    </div>
  );
}