import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: {
          code: {
            dir: 'ltr',
            fontFamily: 'PT Sans',
            lng: 'en',
            countryCode: 'US',
          },
          toast: {
            addedToCart: "Added to cart",
            listEmpty: 'Cart is empty',
            emptyCart: 'All products removed from the cart',
            removedFromCart: 'Product removed from the cart',
            suggestionSendFailed: 'Could not send suggestion, try again later',
            thanksForSuggestion: 'Thanks for the suggestion',
            scanFaceMsg: `In order to tailor the best experience for you,
            We have developed a facial scanning model that knows how to determine your facial features!
            
            We are interested in performing a quick facial scan
            that will help us improve the experience of using the application

            You can return to this scan at any stage and even choose manually
            If we didn't exactly succeed...
           `
          },
          admin: {
            title: 'Admin Screen',
            management: {
              title: 'Management',

            },
            testing: {
              title: 'Testing'
            }
          },
          navbar: {
            account: 'account',
            tutorials: 'tutorials',
            cart: 'cart',
            home: 'home',
          },
          general: {
            password: "password",
            email: "email",
            firstName: "first name",
            lastName: "last name",
            name: 'name',
            addToCart: 'Add to Cart',
            viewInStore: 'View in Store',
            needNotificationPermission: 'Please allow permission for sending notification for better user experience',
            admin: 'admin',
            debugCam: 'Debug Cam',
            screenUnderConstructionMsg: "This page is still under construction and will be accessible later",
            startScan: 'Start Scan',
            stopScan: 'Stop Scan',
            left: 'left',
            support: {
              contactSupport: 'Contact Support',
              contactMsg: 'If you need assistance, please feel free to contact us via email:'
            }
          },
          signin: {
            title: 'Happy to see you again!',
            restorePassword: 'Restore password',
            forgotPasswordMsg: 'Forgot password',
            noAccountMsg: "Still don't have account? Press here",
            emailNotValidMsg: 'Email is not valid',
            passwordNotValidMsg: "Password is not valid. Password must include english letters and be of length of 8 characters",
            signinError: "Username or password are incorrect",
            loginWithGoogle: "Singin using Google",
          },
          signup: {
            title: 'Join Loome Family',
            termsAndConditionsCheckBox: "I agree to the terms of the policy and the use of the Loome application",
            termsAndConditions: 'Terms and Conditions',
            agree: 'Agree',
            notAgree: "Don't agree",
            emailNotValidMsg: 'Email is not valid',
            passwordNotValidMsg: "Password is not valid. Password must include english letters and be of length of 8 characters",
            createAccountError: "Can't create account with this information",
          },
          forgot: {
            emailNotValidMsg: 'Email is not valid',
            sendRestoreCodeAgain: "Didn't get the email, send me again",
            sendRestoreCode: "Send me an email",
            sendRestoreCodeMsg: "Verification email has been sent to this email address",
            spamMsg: 'The mail can get to the spam folder, check there also'
          },
          tutorialTypes: {
            tutorialTypes: 'Tutorial Types',
            title: 'Choose from the different looks that are just right for you',
            
          },
          tutorial: {
            startTutorial: 'Start Tutorial',
            preview: 'Preview',
            addAllToCart: 'Add all to my cart',
            allProductsInCartMsg: 'All the products have been added to the cart',
            headerInstruction: 'Make sure you have the following products for maximum results'
          },
          coupons: {
            noCouponsMsg: "Looks like you don't have any coupons listed...",
            copiedToBoard: "Copied to board",
          },
          account: {
            version: 'Version',
            changeLang: 'Change Language',
            signout: 'Sign Out',
            needHelpSubject: 'I need help with something',
            suggestionBox: '(beta) Suggestion Box',
            suggestionBoxDialog: {
              chooseOption: 'Choose Option',
              suggestion: 'Suggestion',
              send: 'SEND',
              options: {
                improve: 'Improvement',
                appBug: 'Bug'
              },
              title: 'We would be happy to hear suggestions for improving the application',
            },
            deleteAccount: 'Delete Account',
            changePassword: 'Change Password',
            personalInfo: 'Change Personal Information',
            editAccount: 'Edit Account',
            sendEmail: 'Contact Us',
            myCoupons: 'My Coupons',
            persongInfoScreen: {
              birthday: 'Birthday',
              faceType: "Face Type",
              browType: "Brow Type",
              eyeType: "Eye Type",
              updateAccountButtonLabel: 'Updte Account'
            },
            messages: {
              signoutMsg: "Do you want to sign out?",
              singoutCancel: "Cancel",
              signoutApprove: "Sign out",
              deleteAccountTitle: "Delete Account",
              deleteAccountMsg: "Deleting the account is a final action. All information linked to this account will be deleted from the system. Are you sure you want to delete the account?",
              deleteCancel: "Cancle",
              deleteApprove: "Delete"
            },
          },
          wishlist: {
            title: 'Cart',
            removeProduct: 'Remove from the cart',
            emptyWishlist: 'Empty cart'
          },
          home: {
            title: 'Home',
            newProducts: 'New Arrivals',
            newProductSub: 'Discover the new products for your makeup bag',
            matchVideos: 'Perfect Match',
            matchVideosSub: 'We think the following looks will suit your face shape.',
          }
        }
      },
      he: {
        translation: {
          code: {
            dir: 'rtl',
            fontFamily: 'Miriam Libre',
            lng: 'he',
            countryCode: 'IL',
          },
          toast: {
            addedToCart: "נוסף לסל",
            listEmpty: 'הסל ריק',
            emptyCart: 'כל המוצרים הוסרו מהסל',
            removedFromCart: 'המוצר הוסר מהסל',
            suggestionSendFailed: 'לא הצלחנו לשלוח את ההצעה, נסו שוב מאוחר יותר',
            thanksForSuggestion: 'תודה על ההצעה! אנחנו נבדוק את זה',
            scanFaceMsg: `במטרה להתאים עבורך את החוויה הטובה ביותר,
            פיתחנו מודל סריקת פנים שיודע לקבוע את תווי הפנים שלך!
            
            אנו מעוניינים לבצע סריקת פנים זריזה
            שתעזור לנו לשפר את חווית השימוש באפליקציה

            ניתן לחזור לסריקה זו בכל שלב ואפילו לבחור בצורה ידנית
            במידה ולא הצלחנו בדיוק...
           `
          },
          navbar: {
            account: 'פרופיל',
            tutorials: 'הדרכות',
            cart: 'סל הקניות',
            home: 'דף הבית',
          },
          general: {
            password: "סיסמה",
            email: "אימייל",
            firstName: "שם פרטי",
            lastName: "שם משפחה",
            name: 'שם',
            addToCart: 'הוסיפי לסל',
            viewInStore: 'הצג בחנות',
            needNotificationPermission: 'אנא אפשר הרשאה לשליחת הודעה לחוויית משתמש טובה יותר',
            admin: 'מנהל',
            debugCam: 'מצלמת פיתוח',
            screenUnderConstructionMsg: "עמוד זה עדיין בבנייה ויהיה ניתן לגשת אליו בהמשך",
            startScan: 'התחלת סריקה',
            stopScan: 'הפסק סריקה',
            left: 'נשארו',
            support: {
              contactSupport: 'צור קשר',
              contactMsg: 'אם אתם צריכים עזרה, בבקשה צרו איתנו קשר באמצעות המייל:'
            }
          },
          signin: {
            title: 'שמחים לראות אותך שוב!',
            restorePassword: 'שחזור סיסמה',
            forgotPasswordMsg: 'שכחתי את הסיסמה',
            noAccountMsg: 'עדיין לא נרשמת? לחצי כאן',
            emailNotValidMsg: 'אימייל לא תקין',
            passwordNotValidMsg: "סיסמה לא תקינה, הסיסמה חייבת להיות באנגלית ולכלול לפחות 8 תווים",
            signinError: "שם משתמש או סיסמה לא נכונים",
            loginWithGoogle: "התחבר עם גוגל",
          },
          signup: {
            title: 'הצטרפי למשפחת Loome',
            termsAndConditionsCheckBox: 'אני מסכים לתנאי המדיניות והשימוש של אפליקציה זו',
            termsAndConditions: 'תנאי מדיניות ושימוש',
            agree: 'מסכים',
            notAgree: 'לא מסכים',
            emailNotValidMsg: 'אימייל לא תקין',
            passwordNotValidMsg: "סיסמה לא תקינה, הסיסמה חייבת להיות באנגלית ולכלול לפחות 8 תווים",
            createAccountError: "לא ניתן ליצור משתמש באמצעות פרטים אלו",
            
          },
          forgot: {
            emailNotValidMsg: 'אימייל לא תקין',
            sendRestoreCodeAgain: "לא קיבלתי, שלחו לי שוב",
            sendRestoreCode: "שלחו לי מייל לשחזור סיסמה",
            sendRestoreCodeMsg: "מייל אימות נשלח לכתובת המייל איתה נרשמת לאפליקציה",
            spamMsg: "שימו לב, המייל עשוי להגיע גם לתיקיית הספאם, בדקו גם שם",
          },
          tutorialTypes: {
            tutorialTypes: 'סוגי הדרכות',
            title: 'בחרי מתוך הלוקים השונים המתאימים בדיוק בשבילך',
          },
          tutorial: {
            startTutorial: 'התחלת הדרכה',
            preview: 'תצוגה מקדימה',
            addAllToCart: 'הוסף הכל לסל שלי',
            allProductsInCartMsg: 'כל המוצרים נוספו לסל',
            headerInstruction: 'ודאי שברשותך המוצרים הבאים לקבלת תוצאה מקסימלית',
          },
          coupons: {
            noCouponsMsg: 'נראה שאין לך קופונים ברשימה...',
            copiedToBoard: "הקופון הועתק ללוח",
          },
          account: {
            version: 'גרסת אפליקציה',
            changeLang: 'החלף שפה',
            signout: 'התנתק',
            needHelpSubject: 'אני צריך עזרה במשהו',
            suggestionBox: '(בטא) תיבת הצעות',
            suggestionBoxDialog: {
              chooseOption: 'בחר אפשרות',
              suggestion: 'הצעה',
              send: 'שלח',
              options: {
                improve: 'הצעה לשיפור',
                appBug: 'בעיה באפליקציה'
              },
              title: 'נשמח לשמוע הצעות לשיפור על האפליקציה',
            },
            deleteAccount: 'מחיקת חשבון',
            changePassword: 'החלף סיסמה',
            personalInfo: 'עריכת פרטים אישיים',
            editAccount: 'עדכון חשבון',
            sendEmail: 'צרו קשר',
            myCoupons: 'הקופונים שלי',
            persongInfoScreen: {
              birthday: 'תאריך לידה',
              faceType: "סוג פנים",
              browType: "סוג גבות",
              eyeType: "סוג עיניים",
              updateAccountButtonLabel: 'עדכון חשבון'
            },
            messages: {
              signoutMsg: "האם את.ה רוצה להתנתק?",
              singoutCancel: "ביטול",
              signoutApprove: "התנתק",
              deleteAccountTitle: "מחיקת החשבון",
              deleteAccountMsg: "מחיקת החשבון היא פעולה אחרונה. כל המידע המקושר לחשבון זה יימחק מהמערכת. האם אתה בטוח שברצונך למחוק את החשבון?",
              deleteCancel: "ביטול",
              deleteApprove: "מחק"
            },
          },
          wishlist: {
            title: 'סל הקניות',
            removeProduct: 'הסר מהסל',
            emptyWishlist: 'לרוקן את הסל',
            wishlishtIsEmpty: 'הסל ריק'
          },
          home: {
            title: 'עמוד הבית',
            newProducts: 'מוצרים חדשים',
            newProductSub: 'גלי את המוצרים החדשים לתיק האיפור שלך',
            matchVideos: 'התאמה מושלמת',
            matchVideosSub: 'אנחנו חושבים שהלוקים הבאים יתאימו למבנה הפנים שלך',
          }
        }
      }
    },
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

export default i18n;